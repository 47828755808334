import AccessTimeIcon from "@mui/icons-material/AccessTime";
import BarChartIcon from "@mui/icons-material/BarChart";
import PersonIcon from "@mui/icons-material/Person";
import QuizIcon from "@mui/icons-material/Quiz";
import SchoolIcon from "@mui/icons-material/School";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import WindowIcon from "@mui/icons-material/Window";
import { Box, Container, Tab, Tabs } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import images from "../../../assets/images";
import AccordionCard from "../../components/AccordianCard";
import CurriculumHeader from "../../components/CurriculumHeader";
import NavigatorAccordion from "../../components/NavigatorAccordion";
import PageHeader from "../../components/PageHeader ";
import ReviewCard from "../../components/ReviewCard";
import StartingComponent from "../../components/StartingComponent";

const courseDetails = [
  { icon: <AccessTimeIcon />, label: "12 Weeks" },
  { icon: <BarChartIcon />, label: "Beginner" },
  { icon: <ViewModuleIcon />, label: "4 Lesson" },
  { icon: <QuizIcon />, label: "1 Quizzes" },
  { icon: <SchoolIcon />, label: "20 Students" },
  { icon: <PersonIcon />, label: "Dr. Lisa Monroe " },
];

export default function TwelveWeek({
  accordionData,
  reviewsData,
  weekAccordianData,
  weekModule,
}) {
  const [value, setValue] = useState(0);
  const [visibleCount, setVisibleCount] = useState(3);

  const handleShowMore = () => {
    setVisibleCount((prevCount) => prevCount + 3);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const data = {
    title: "Your Compass to Clarity – FAQs for a Smooth Voyage",
    icon: <WindowIcon fontSize="20px" className="window_icon" />,
    buttonText: "Frequently Asked Questiuons",
  };

  return (
    <Box mb={2}>
      <CurriculumHeader
        title="The Captain’s Voyage: A 12-Week Expedition"
        subHeading={'May your journey be filled with discovery, growth, and endless horizons.'}
        backgroundImage={images?.cdBanner}
        details={courseDetails}
      />
      <Container maxWidth="lg" sx={{ py: 5 }} className="main_container">
        <PageHeader {...weekModule} />
        <NavigatorAccordion data={weekAccordianData} checked="Week Details" />
      </Container>
      <Container maxWidth="lg" sx={{ mb: 5 }} className="main_container">
        <StartingComponent
          backgroundImage={images.discoveryWeek}
          headerText="May your journey be filled with discovery, growth, and endless horizons."
          subHeader="This program invites you to set sail on an adventure to uncover your strengths, navigate your challenges, and chart a course for personal empowerment. With these weekly themes as your compass, you’re ready to begin your leadership journey. Fair winds and smooth seas!"
          buttonText="Enroll Now"
          onButtonClick={() => console.log("Button clicked!")}
        />
      </Container>
      {/*Compass   */}
      <Container maxWidth="lg" sx={{ py: 5 }} className="main_container">
        <PageHeader {...data} />
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              TabIndicatorProps={{ style: { display: "none" } }}
            >
              <Tab
                sx={{
                  color: "var(--color-para)",
                  "&.Mui-selected": {
                    color: "var(--color-darkSky)",
                    borderBottom: "2px solid var(--color-darkSky)",
                  },
                }}
                label="Reviews"
                className="fw-600"
                {...a11yProps(0)}
              />
              <Tab
                className="fw-600"
                sx={{
                  color: "var(--color-para)",
                  "&.Mui-selected": {
                    color: "var(--color-darkSky)",
                    borderBottom: "2px solid var(--color-darkSky)",
                  },
                }}
                label="Faqs"
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            {reviewsData?.map((review, index) => (
              <ReviewCard
                key={index}
                name={review.name}
                avatar={review.avatar}
                rating={review.rating}
                review={review.review}
                date={review.date}
              />
            ))}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Box className="text-center">
              {accordionData.slice(0, visibleCount).map((item, index) => (
                <AccordionCard
                  key={index}
                  title={item.title}
                  content={item.content}
                />
              ))}
              {visibleCount < accordionData.length && (
                <Box
                  onClick={handleShowMore}
                  sx={{ textDecoration: "underline", pt: 1 }}
                  className="text-darksky"
                >
                  Load More
                </Box>
              )}
            </Box>
          </CustomTabPanel>
        </Box>
      </Container>
    </Box>
  );
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

import WindowIcon from "@mui/icons-material/Window";
import { Box, Container, Typography } from "@mui/material";
import images from "../../assets/images";
import WellnessFeatures from "../AboutUs/components/WellnessFeatures";
import AnchorCustomSection from "../components/AnchorCustomSection";
import ConfidenceContainer from "../components/ConfidenceContainer";
import InfoCardGrid from "../components/InfoCard";
import StartingComponent from "../components/StartingComponent";
import ChartPath from "../Home/components/ChartPath";

export default function Captains({
  worksDetailData,
  guiderData,
  logBookData,
  compassData,
}) {
  return (
    <Box mb={2}>
      <AnchorCustomSection
        title="Your Personal Logbook: Manage Your Journey"
        subTitle="Welcome to Your Personal Logbook, your dedicated space to stay organized and on course throughout your journey."
        backgroundImage={images?.captionBanner}
      />
      <Box mt={2}>
        <ChartPath
          aboutText="Feature and Tools"
          header="Your Compass to Growth & Success"
          subHeader="Navigate your journey with ease—track progress, manage assessments, and reflect on milestones, all in one secure and intuitive space. Let this be your guiding star as you chart your course toward personal growth and success."
          programs={compassData}
          imageUrl={images.compas}
        />
      </Box>
      <Container maxWidth="lg" sx={{ py: 5 }} className="main_container">
        <Box className="all_center gap-1" mb={3}>
          <Box className="rounded-btns all_center cursor-pointer" p={2}>
            <WindowIcon fontSize="20px" className="window_icon" />{" "}
          </Box>
          <Typography
            variant="button"
            color="primary"
            className="rounded-btns cursor-pointer text-darksky capitalize_text"
            py={1}
            px={2}
          >
            How It Works{" "}
          </Typography>
        </Box>{" "}
        <InfoCardGrid data={worksDetailData} />{" "}
      </Container>

      <Container maxWidth="lg" sx={{ py: 5 }} className="main_container">
        <Box mt={4} mb={3}>
          <ConfidenceContainer
            header="Guiding Your Financial Management"
            imageUrl={images.guider}
            images={images}
            contentData={guiderData}
          />
        </Box>
      </Container>
      <Container maxWidth="lg" sx={{ py: 0 }} className="main_container">
        <WellnessFeatures
          header="Why Use the Logbook?"
          features={logBookData}
        />
      </Container>
      <Container maxWidth="lg" sx={{ py: 5, mt: 3 }} className="main_container">
        <StartingComponent
          backgroundImage={images.logBook}
          headerText="Your Personal Logbook is more than just a tool—it’s your co-captain on this voyage of self-discovery and achievement. Stay on course, celebrate milestones, and take charge of your future. Let your logbook lead you to success and fulfillment."
        />
      </Container>
    </Box>
  );
}

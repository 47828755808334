import { Button, Container, Grid, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useCreateUserMutation } from "../../redux/api/apiSlice";

export default function Signup() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [createUser, { isLoading, isError, error }] = useCreateUserMutation();

  const onSubmit = async (data) => {
    console.log("Submitting data:", data);
    try {
      const result = await createUser(data).unwrap();
      console.log("User created:", result);
      navigate("/login");
    } catch (err) {
      console.error("Error creating user:", err);
    }
  };

  return (
    <Container maxWidth="xs" className="all_center flex-column">
      <Typography
        variant="h4"
        className="text-center"
        gutterBottom
        sx={{
          mt: {
            xs: "20px",
            sm: "30px",
            md: "40px",
            lg: "70px",
          },
          mb: {
            lg: "20px",
          },
        }}
      >
        {" "}
        Sign Up
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              type="email"
              {...register("email", { required: "Email is required" })}
              error={!!errors.email}
              helperText={errors.email?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Username"
              variant="outlined"
              {...register("username", { required: "Username is required" })}
              error={!!errors.username}
              helperText={errors.username?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Password"
              variant="outlined"
              type="password"
              {...register("password", { required: "Password is required" })}
              error={!!errors.password}
              helperText={errors.password?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              className="btn-hover color-1 fw-600 text-darksky bg-lightsky w-100 mb-1"
              disabled={isLoading}
            >
              {isLoading ? "Signing Up..." : "Sign Up"}
            </Button>
          </Grid>
        </Grid>
        {isError && (
          <Typography color="error">Error: {error?.message}</Typography>
        )}
      </form>
    </Container>
  );
}

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Avatar,
  Box,
  Button,
  Card,
  Grid,
  LinearProgress,
  Pagination,
  Typography,
} from "@mui/material";
import { useState } from "react";
import images from "../../../assets/images";
import CustomAppBar from "../../components/CustomAppBar";
import WeekPlanCard from "./components/WeekPlanCard";
import WeeksPlanTable from "./components/WeeksPlanTable";

const dummyData = [
  {
    title: "Week 1: Bravery and Courage",
    progress: 8,
    journalEntries: 18,
    pointsEarned: 300,
    upcomingAppointments: 3,
    weekNumber: 1,
  },
  {
    title: "Week 2: Resilience",
    progress: 20,
    journalEntries: 15,
    pointsEarned: 250,
    upcomingAppointments: 1,
    weekNumber: 2,
  },
  {
    title: "Week 3: Confidence",
    progress: 35,
    journalEntries: 22,
    pointsEarned: 400,
    upcomingAppointments: 4,
    weekNumber: 3,
  },
  {
    title: "Week 4: Creativity",
    progress: 50,
    journalEntries: 30,
    pointsEarned: 450,
    upcomingAppointments: 2,
    weekNumber: 4,
  },
  {
    title: "Week 5: Patience",
    progress: 60,
    journalEntries: 27,
    pointsEarned: 500,
    upcomingAppointments: 0,
    weekNumber: 5,
  },
  {
    title: "Week 6: Focus",
    progress: 75,
    journalEntries: 21,
    pointsEarned: 550,
    upcomingAppointments: 3,
    weekNumber: 6,
  },
  {
    title: "Week 7: Discipline",
    progress: 80,
    journalEntries: 17,
    pointsEarned: 600,
    upcomingAppointments: 5,
    weekNumber: 7,
  },
  {
    title: "Week 8: Gratitude",
    progress: 90,
    journalEntries: 19,
    pointsEarned: 650,
    upcomingAppointments: 2,
    weekNumber: 8,
  },
  {
    title: "Week 9: Ambition",
    progress: 65,
    journalEntries: 25,
    pointsEarned: 700,
    upcomingAppointments: 1,
    weekNumber: 9,
  },
  {
    title: "Week 10: Leadership",
    progress: 55,
    journalEntries: 28,
    pointsEarned: 750,
    upcomingAppointments: 4,
    weekNumber: 10,
  },
  {
    title: "Week 11: Self-Awareness",
    progress: 40,
    journalEntries: 16,
    pointsEarned: 800,
    upcomingAppointments: 0,
    weekNumber: 11,
  },
  {
    title: "Week 12: Reflection",
    progress: 95,
    journalEntries: 29,
    pointsEarned: 850,
    upcomingAppointments: 2,
    weekNumber: 12,
  },
  {
    title: "Week 13: Inspiration",
    progress: 25,
    journalEntries: 20,
    pointsEarned: 200,
    upcomingAppointments: 3,
    weekNumber: 13,
  },
  {
    title: "Week 14: Optimism",
    progress: 30,
    journalEntries: 18,
    pointsEarned: 220,
    upcomingAppointments: 0,
    weekNumber: 14,
  },
  {
    title: "Week 15: Connection",
    progress: 10,
    journalEntries: 14,
    pointsEarned: 180,
    upcomingAppointments: 2,
    weekNumber: 15,
  },
  {
    title: "Week 16: Compassion",
    progress: 45,
    journalEntries: 21,
    pointsEarned: 270,
    upcomingAppointments: 1,
    weekNumber: 16,
  },
  {
    title: "Week 17: Accountability",
    progress: 60,
    journalEntries: 16,
    pointsEarned: 300,
    upcomingAppointments: 4,
    weekNumber: 17,
  },
  {
    title: "Week 18: Communication",
    progress: 50,
    journalEntries: 28,
    pointsEarned: 320,
    upcomingAppointments: 3,
    weekNumber: 18,
  },
  {
    title: "Week 19: Open-Mindedness",
    progress: 35,
    journalEntries: 19,
    pointsEarned: 350,
    upcomingAppointments: 0,
    weekNumber: 19,
  },
  {
    title: "Week 20: Self-Discipline",
    progress: 85,
    journalEntries: 30,
    pointsEarned: 380,
    upcomingAppointments: 2,
    weekNumber: 20,
  },
  {
    title: "Week 21: Motivation",
    progress: 70,
    journalEntries: 22,
    pointsEarned: 400,
    upcomingAppointments: 1,
    weekNumber: 21,
  },
  {
    title: "Week 22: Clarity",
    progress: 95,
    journalEntries: 25,
    pointsEarned: 420,
    upcomingAppointments: 3,
    weekNumber: 22,
  },
  {
    title: "Week 23: Introspection",
    progress: 65,
    journalEntries: 24,
    pointsEarned: 450,
    upcomingAppointments: 4,
    weekNumber: 23,
  },
  {
    title: "Week 24: Vision",
    progress: 80,
    journalEntries: 26,
    pointsEarned: 480,
    upcomingAppointments: 2,
    weekNumber: 24,
  },
  {
    title: "Week 25: Determination",
    progress: 90,
    journalEntries: 27,
    pointsEarned: 510,
    upcomingAppointments: 0,
    weekNumber: 25,
  },
  {
    title: "Week 26: Perspective",
    progress: 45,
    journalEntries: 23,
    pointsEarned: 540,
    upcomingAppointments: 1,
    weekNumber: 26,
  },
  {
    title: "Week 27: Positivity",
    progress: 55,
    journalEntries: 30,
    pointsEarned: 570,
    upcomingAppointments: 5,
    weekNumber: 27,
  },
  {
    title: "Week 28: Balance",
    progress: 50,
    journalEntries: 22,
    pointsEarned: 600,
    upcomingAppointments: 2,
    weekNumber: 28,
  },
  {
    title: "Week 29: Mindfulness",
    progress: 40,
    journalEntries: 21,
    pointsEarned: 630,
    upcomingAppointments: 0,
    weekNumber: 29,
  },
  {
    title: "Week 30: Self-Improvement",
    progress: 30,
    journalEntries: 19,
    pointsEarned: 660,
    upcomingAppointments: 3,
    weekNumber: 30,
  },
];
const appBarData = [
  {
    title: "12-Week Daily Journal",
    icon: <MenuBookIcon />,
    xp: "400 XP",
    notifications: 7,
    profileImage: images.kimAvatar,
  },
];

const tableHeaders = [
  "Day",
  "Self-reflection",
  "Goal Setting",
  "Progress Tracking",
];
const tableData = [
  {
    Day: "Monday",
    "Self-reflection":
      "What is one fear that has held you back in the past? How has it shaped your decisions?",
    "Goal Setting":
      "What is one small step you can take today to face this fear?",
    "Progress Tracking":
      "Did you take the step? How did it feel? If not, what held you back?",
  },
  {
    Day: "Tuesday",
    "Self-reflection":
      "Recall a time when you stayed silent but wished you had spoken up. How did it make you feel?",
    "Goal Setting":
      "Set an intention to speak up in a situation today—big or small. What will you say?",
    "Progress Tracking":
      "Did you do it? What was the response, and how did it feel?",
  },
  {
    Day: "Wednesday",
    "Self-reflection":
      "What is one risk you regret not taking? Why did you hesitate?",
    "Goal Setting":
      "Identify a low-stakes risk you can take today to build your courage muscle.",
    "Progress Tracking":
      "How did it turn out? What did you learn from the experience?",
  },
  {
    Day: "Thursday",
    "Self-reflection":
      "When was the last time you faced a difficult situation and pushed through? What strengths did you discover?",
    "Goal Setting":
      "How can you remind yourself of this strength the next time fear arises?",
    "Progress Tracking":
      "Did you apply this mindset today? How did it change your response to a challenge?",
  },
  {
    Day: "Friday",
    "Self-reflection":
      "What is one fear that has held you back in the past? How has it shaped your decisions?",
    "Goal Setting":
      "Choose one area where you want to be bolder—what's the first step?",
    "Progress Tracking":
      "What action did you take today that reflects bravery? How did it shape your mindset?",
  },
];

export default () => {
  const [page, setPage] = useState(1);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const itemsPerPage = 4;

  // Get the data for the current page
  const currentItems = dummyData.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const appointments = [
    {
      title: "Individual Therapy",
      therapist: "Michael Jenkins",
      time: "Today, 2:00 PM - 3:00 PM",
      avatar: "https://via.placeholder.com/40", // Replace with actual image
      isToday: true,
    },
    {
      title: "Therapy Session II",
      therapist: "Michael Jenkins",
      time: "Mar 6, 2:00 PM - 3:00 PM",
      avatar: "https://via.placeholder.com/40", // Replace with actual image
      isToday: false,
    },
    {
      title: "Therapy Session III",
      therapist: "Kim Sutherland",
      time: "Mar 10, 2:00 PM - 3:00 PM",
      avatar: "https://via.placeholder.com/40", // Replace with actual image
      isToday: false,
    },
  ];

  const goals = [
    { title: "Journal Entry", progress: 10, xp: 100 },
    { title: "DUI Lesson", progress: 70, xp: 100 },
    { title: "Podcast Learning", progress: 0, xp: 100 },
    { title: "Self Reflection", progress: 0, xp: 0 },
    { title: "Blue Shield - Guardian of Wellness", progress: 40, xp: 0 },
  ];

  return (
    <Box className="d-flex justify-content-between align-items-center flex-wrap">
      <CustomAppBar {...appBarData[0]} />

      <Box>
        <Grid container spacing={2}>
          {currentItems.map((data, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <WeekPlanCard {...data} />
            </Grid>
          ))}
        </Grid>

        {/* Pagination */}
        <Box className="d-flex justify-content-end" mt={3}>
          <Pagination
            count={Math.ceil(dummyData.length / itemsPerPage)}
            page={page}
            onChange={handleChangePage}
            color="primary"
            className="border-r-8 f-20 font-poppins fw-500"
            sx={{
              background: "var(--color-light-d)",
              p: "5px",
              "& .MuiPaginationItem-root.Mui-selected": {
                backgroundColor: "var(--color-darkSky)",
                color: "white",
                borderRadius: "5px",
              },
            }}
          />
        </Box>

        <Box mt={3}>
          <Typography className="font-18 text-black font-poppins fw-500" mb={2}>
            Pause and put your thoughts into words—start your journal today
          </Typography>
        </Box>
        <WeeksPlanTable headers={tableHeaders} data={tableData} />

        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          gap={3}
          mt={2}
        >
          {/* Upcoming Appointments */}
          <Card sx={{ flex: 1, p: 3, boxShadow: 3, borderRadius: 2 }}>
            <Typography variant="h6" fontWeight="bold">
              Upcoming
            </Typography>
            <Typography variant="body2" color="gray" mb={2}>
              Appointments & Reminders
            </Typography>
            {appointments.map((appointment, index) => (
              <Box
                key={index}
                display="flex"
                alignItems="center"
                gap={2}
                py={2}
                borderBottom={
                  index !== appointments.length - 1 ? "1px solid #eee" : "none"
                }
              >
                <Avatar src={appointment.avatar} />
                <Box flex={1}>
                  <Typography fontWeight="bold">{appointment.title}</Typography>
                  <Typography variant="body2" color="gray">
                    {appointment.therapist}
                  </Typography>
                  <Typography variant="body2" color="gray">
                    {appointment.time}
                  </Typography>
                </Box>
                {appointment.isToday ? (
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<OpenInNewIcon />}
                  >
                    Join with Zoom
                  </Button>
                ) : (
                  <Button variant="outlined" startIcon={<CalendarTodayIcon />}>
                    Add to Calendar
                  </Button>
                )}
              </Box>
            ))}
          </Card>

          {/* Goal Tracking */}
          <Card sx={{ flex: 1, p: 3, boxShadow: 3, borderRadius: 2 }}>
            <Typography variant="h6" fontWeight="bold">
              Goal Tracking
            </Typography>
            <Typography variant="body2" color="gray" mb={2}>
              Track your progress like a seasoned captain
            </Typography>
            {goals.map((goal, index) => (
              <Box key={index} mb={2}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography>{goal.title}</Typography>
                  {goal.xp > 0 && (
                    <Typography color="gold">+ {goal.xp} XP</Typography>
                  )}
                </Box>
                <LinearProgress
                  variant="determinate"
                  value={goal.progress}
                  sx={{ height: 8, borderRadius: 1 }}
                />
              </Box>
            ))}
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

import WindowIcon from "@mui/icons-material/Window";
import { Box, Container, Typography } from "@mui/material";
import images from "../../assets/images";
import AnchorCustomSection from "../components/AnchorCustomSection";
import InfoCardGrid from "../components/InfoCard";
import PageHeader from "../components/PageHeader ";
import StartingComponent from "../components/StartingComponent";
import Categories from "./components/categories";
import Community from "./components/Community";

export default function TreasureChest({
  streetDetailData,
  streetData,
  categoriesData,
  categoriesDetailData,
  engagementData,
}) {
  console.log(categoriesData, "categoriesData");

  return (
    <Box mb={2}>
      <AnchorCustomSection
        title="Cognitive Compass: Earn, Redeem, Thrive"
        subTitle="Welcome to the Cognitive Compass: Navigating Mental Wellness with Nature's Elements, where every milestone on your journey transforms into meaningful rewards."
        backgroundImage={images?.treasureChestBanner}
      />

      <Container
        maxWidth="lg"
        sx={{ py: 5, mt: 3 }}
        className="main_container px-0"
      >
        <PageHeader {...streetData} />
        <InfoCardGrid data={streetDetailData} />{" "}
      </Container>

      <Container
        maxWidth="lg"
        sx={{ py: 6, mb: 3 }}
        className="main_container bg-lightsky radius-16 card_border"
      >
        <Box className="all_center">
          <Box className="text-center w-75  flex-column">
            <Typography
              variant="h4"
              align="center"
              gutterBottom
              className="styled_header fw-600 font-poppins"
            >
              {categoriesData?.title}
            </Typography>
            <Typography
              variant="body1"
              align="center"
              color="text.secondary"
              className="f-18"
            >
              {categoriesData?.description}
            </Typography>
          </Box>
        </Box>

        <Categories categories={categoriesDetailData} />
      </Container>

      <Container
        maxWidth="lg"
        sx={{ py: 6, mb: 3 }}
        className="main_container px-0"
      >
        <Box className="all_center gap-1" mb={3}>
          <Box className="rounded-btns all_center cursor-pointer" p={2}>
            <WindowIcon fontSize="20px" className="window_icon" />{" "}
          </Box>
          <Typography
            variant="button"
            color="primary"
            className="rounded-btns cursor-pointer text-darksky capitalize_text"
            py={1}
            px={2}
          >
            Community Mangement
          </Typography>
        </Box>
        <Community engagementData={engagementData} />
      </Container>

      <Container maxWidth="lg" sx={{ py: 5 }} className="main_container px-0">
        <StartingComponent
          backgroundImage={images.steps}
          headerText="Step into a sanctuary where milestones shape your journey. Earn rewards, find inspiration, and navigate growth with Cognitive Compass. Set your course, track progress, and turn achievements into treasures!"
        />
      </Container>
    </Box>
  );
}

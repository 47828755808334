import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import {
  Box,
  Button,
  Container,
  Grid,
  Rating,
  styled,
  Typography,
} from "@mui/material";
import React from "react";
import images from "../../../assets/images";
import SliderComponent from "../../components/SliderComponent";

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#FFC107",
  },
  "& .MuiRating-iconHover": {
    color: "#FFEB3B",
  },
});

const Journey = () => {
  // const imagesData = [images.oneIcon, images.twoIcon, images.threeIcon];

  return (
    <Box
      className="flex-column all_center text-center main_slider"
      sx={{
        backgroundImage: `url(${images?.bannerImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "auto",
        // height: {
        //   xs: "60vh",
        //   sm: "75vh",
        //   md: "80vh",
        //   lg: "85vh",
        // },
        color: "var(--color-white)",
      }}
    >
      <Container maxWidth="lg">
        {" "}
        <Grid
          container
          spacing={4}
          alignItems="center"
          justifyContent="center"
          sx={{
            padding: { xs: 4, md: 8, sm: 2 },
          }}
        >
          <Grid item xs={12} md={6}>
            {/* <Box className="all_center" gap={4} mb={1}>
              <Box>
                <ImagesDetail userImages={imagesData} />
              </Box>
              <Box>
                <StyledRating
                  value={5}
                  readOnly
                  precision={0.5}
                  emptyIcon={
                    <span className="material-symbols-outlined">
                      star_outline
                    </span>
                  }
                />
                <Typography variant="body2" gutterBottom>
                  18,921 (reviews)
                </Typography>
              </Box>
            </Box> */}

            <Box>
              <Typography
                variant="h5"
                component="h2"
                gutterBottom
                sx={{ fontWeight: "bold" }}
                mb={1}
              >
                Set sail on a transformative journey toward a healthier, more
                fulfilling life.
              </Typography>
              <Typography variant="body1" paragraph>
                At Centerboard Wellness Center, we provide the compass, tools,
                and support you need to navigate your unique path. Let’s chart
                your course together.
              </Typography>

              <Button
                variant="contained"
                className="contact_btn"
                endIcon={<ArrowOutwardIcon />}
              >
                Start Your Journey
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Box sx={{ py: 4, width: "100%" }}>
        <SliderComponent />
      </Box>
    </Box>
  );
};

export default Journey;

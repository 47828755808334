import { Box } from "@mui/material";
import React from "react";
import DetailCurriculum from "./components/DetailCurriculum";
import GrowthCurriculum from "./components/GrowthCurriculum";
import JourneyCurriculum from "./components/JourneyCurriculum";
import Review from "./components/Review";
import SelfDiscovery from "./components/SelfDiscovery";

export default function SeaDiscovery({
  growthData,
  pageData,
  moduleData,
  accordionData,
}) {
  return (
    <Box>
      <DetailCurriculum />
      <GrowthCurriculum growthData={growthData} />
      <SelfDiscovery
        growthData={growthData}
        pageData={pageData}
        moduleData={moduleData}
        accordionData={accordionData}
      />
      <Box className="all_center navigation_card">
        <JourneyCurriculum />
      </Box>
      <Review accordionData={accordionData} />
    </Box>
  );
}

import {
  AccessTime,
  Layers,
  People,
  SignalCellularAlt,
  Star,
} from "@mui/icons-material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";

const CourseCard = ({ course }) => {
  const {
    title,
    description,
    instructor,
    duration,
    level,
    modules,
    students,
    rating,
    xp,
    icon,
    avatar,
    btn,
  } = course;

  // Course Details Data (For Mapping)
  const details = [
    { icon: <AccessTime fontSize="small" />, label: duration },
    { icon: <SignalCellularAlt fontSize="small" />, label: level },
    { icon: <Layers fontSize="small" />, label: `${modules} modules` },
    { icon: <People fontSize="small" />, label: `${students} students` },
    { icon: <Star fontSize="small" sx={{ color: "gold" }} />, label: rating },
  ];

  return (
    <Card sx={{ width: "100%", borderRadius: 3, boxShadow: 3, mt: 2 }}>
      <CardContent>
        {/* Title & Icon */}
        <Stack
          direction="row"
          className="d-flex justify-content-between align-items-center"
        >
          <Typography
            variant="h6"
            className="fw-500 font-poppins align-items-center"
            width="80%"
          >
            {title}
          </Typography>
          {icon}
        </Stack>

        {/* Description */}
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ mt: 1, mb: 2 }}
        >
          {description}
        </Typography>

        {/* Instructor */}
        <Stack direction="row" alignItems="center" spacing={1}>
          <Avatar
            src={avatar}
            alt={instructor}
            sx={{ width: 24, height: 24 }}
          />
          <Typography variant="body2" fontWeight="bold">
            {instructor}
          </Typography>
        </Stack>

        {/* Course Details (Mapped) */}
        <Stack spacing={1} sx={{ mt: 3 }}>
          {details.map((item, index) => (
            <Stack
              key={index}
              direction="row"
              className="d-flex align-items-center"
              spacing={1}
            >
              <Box sx={{ color: "var(--color-darkSky)" }}>{item.icon}</Box>
              <Typography variant="body2" fontWeight={500}>
                {item.label}
              </Typography>
            </Stack>
          ))}
        </Stack>

        {/* Action Button & XP */}
        <Box
          mt={3}
          className="d-flex justify-content-between align-items-center"
        >
          <Button
            variant="contained"
            className="contact_btn f-16"
            endIcon={<ArrowOutwardIcon />}
            sx={{ mt: 2 }}
          >
            {btn}{" "}
          </Button>

          <Typography className="text-darkblue font-lora f-14 xp_hero cursor-pointer">
            🏆 {xp} XP
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CourseCard;

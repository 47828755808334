import { School } from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";

const OverviewTab = ({ courseDescription, learningPoints }) => {
  return (
    <Box maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      {/* Course Description */}
      <Box mb={4}>
        <Typography
          className="text-darkblue font-poppins sub_header fw-500 gap-1 d-flex align-items-center mb-1"
          gutterBottom
        >
          {courseDescription.icon} Course Description
        </Typography>
        <Typography className="color-para font-lora f-18 fw-500 mb-1">
          {courseDescription.intro}
        </Typography>

        <Typography className="color-para font-lora f-20 fw-500 mb-1" mt={2}>
          {courseDescription.subtitle}
        </Typography>
        <Typography className="color-para font-lora f-18 fw-500 mb-1">
          {courseDescription.details}
        </Typography>
      </Box>

      {/* Overall Learning */}
      <Box>
        <Typography
          className="text-darkblue font-poppins sub_header fw-500 gap-1 d-flex align-items-center mb-1"
          gutterBottom
        >
          <Box sx={{ color: "var(--color-darkSky)" }}>
            <School fontSize="small" />{" "}
          </Box>
          Overall Learning
        </Typography>
        <List>
          {learningPoints.map((point, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <DoneIcon sx={{ color: "var(--color-darkBlue)" }} />{" "}
              </ListItemIcon>
              <ListItemText
                sx={{
                  fontFamily: "Lora, serif",
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "var(--color-para)",
                }}
                primary={point}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default OverviewTab;

import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import images from "../../assets/images";

const ContactUs = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <Box py={5}>
      <Container
        className="card_border radius-16"
        sx={{ backgroundColor: "var(--color-lightSky)", py: 5, mt: 3 }}
      >
        <Box className="all_center">
          <Grid
            container
            spacing={2}
            sx={{ maxWidth: "1200px", width: "100%" }}
          >
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  borderRadius: "10px",
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={images.contact}
                  alt="welcome"
                  className="object-fit-cover"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    display: "block",
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box p={3}>
                <Box
                  className="d-flex align-items-center justify-content-between"
                  mb={1}
                >
                  <Typography variant="h5" className="fw-600 text-black">
                    Reach Out: Let’s Navigate Together
                  </Typography>
                </Box>
                <Typography
                  className="font-lora f-18 fw-500 color-para"
                  sx={{ mb: 4 }}
                >
                  Have questions or ready to embark on your journey? We’d love
                  to hear from you. Whether you need guidance, support, or just
                  want to say hello, our team is here to help. Drop us a line
                  and let’s set sail toward a brighter horizon together.
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container spacing={2}>
                    {/* First Name */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="First Name"
                        variant="outlined"
                        {...register("firstName", {
                          required: "First Name is required",
                        })}
                        error={!!errors.firstName}
                        helperText={errors.firstName?.message}
                      />
                    </Grid>

                    {/* Last Name */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Last Name"
                        variant="outlined"
                        {...register("lastName", {
                          required: "Last Name is required",
                        })}
                        error={!!errors.lastName}
                        helperText={errors.lastName?.message}
                      />
                    </Grid>

                    {/* Email */}
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Email"
                        variant="outlined"
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value:
                              /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                            message: "Invalid email address",
                          },
                        })}
                        error={!!errors.email}
                        helperText={errors.email?.message}
                      />
                    </Grid>

                    {/* Phone Number */}
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Phone Number"
                        variant="outlined"
                        {...register("phoneNumber", {
                          required: "Phone Number is required",
                        })}
                        error={!!errors.phoneNumber}
                        helperText={errors.phoneNumber?.message}
                      />
                    </Grid>

                    {/* Message */}
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Message"
                        placeholder="Type your message here…"
                        variant="outlined"
                        multiline
                        rows={4}
                        {...register("message", {
                          required: "Message is required",
                        })}
                        error={!!errors.message}
                        helperText={errors.message?.message}
                      />
                    </Grid>

                    {/* Submit Button */}
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        className="contact_btn f-16 w-100 capitalize_text"
                        type="submit"
                      >
                        Sail Your Message Away
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default ContactUs;

import WindowIcon from "@mui/icons-material/Window";
import { Box, Container, Typography } from "@mui/material";
import { discussionTopics } from "../../../../assets/data/anchorPodcast";
import images from "../../../../assets/images";
import "../../../../assets/style.css";
import AnchorCustomSection from "../../../components/AnchorCustomSection";
import InfoCardGridDetail from "../../../components/InfoCardDetail";
import LegalHandbook from "../../../components/LegalHandbook";
import NavigatorAccordion from "../../../components/NavigatorAccordion";
import PageHeader from "../../../components/PageHeader ";
import PodcastDiscussion from "../../../components/PodcastDiscussion";
import SurveyCard from "../../../components/SurveyCard";
import JoinConversation from "./components/JoinConversation";
import PodcastReviews from "./components/PodcastReviews";
import Programs from "./components/Programs";
import ResearchBased from "./components/ResearchBased";

export default function EducationalPodcast({
  podcastData,
  educationPodcast,
  faceFearDetail,
  faceFearData,
  sampleQuestions,
  reviewhandlebookDAta,
  accordionData,
  surveyData,
  podcastStructure,
  podcastStructureData,
}) {
  return (
    <Box mb={2}>
      <AnchorCustomSection
        title="Navigating Choices: The DUI Prevention Podcast"
        backgroundImage={images?.anchorBanner}
        details={podcastData}
        btnText="Start Your Journey and Enroll Today"
      />
      <LegalHandbook data={educationPodcast} />
      <Container maxWidth="lg" sx={{ py: 5 }} className="main_container">
        <PageHeader {...faceFearData} />
        <InfoCardGridDetail data={faceFearDetail} />{" "}
      </Container>
      <Container maxWidth="lg" sx={{ py: 5 }} className="main_container">
        <PageHeader {...sampleQuestions} />
        {/* <br /> */}
        <PodcastDiscussion
          discussionTopics={discussionTopics}
          imageUrl={images.speaker}
        />
      </Container>

      {/* PODCAST STRUCTURE */}
      <Container maxWidth="lg" sx={{ py: 5 }} className="main_container">
        <PageHeader {...podcastStructure} />
        <NavigatorAccordion data={podcastStructureData} checked="Details" />
      </Container>

      <Container maxWidth="lg" sx={{ py: 5 }} className="main_container">
        <Box className="all_center gap-1" mb={2}>
          <Box className="rounded-btns all_center cursor-pointer" p={2}>
            <WindowIcon fontSize="20px" className="window_icon" />
          </Box>
          <Typography
            variant="button"
            color="primary"
            className="rounded-btns cursor-pointer text-darksky capitalize_text"
            py={1}
            px={2}
          >
            Podcast Impact & Listener Engagement{" "}
          </Typography>
        </Box>{" "}
        <Box>
          <Typography
            className="styled_header fw-600 font-poppins text-center"
            gutterBottom
          >
            How Do We Know Our Podcast Makes a Difference?{" "}
          </Typography>
          <Typography
            className="f-18 color-para fw-500 font-lora text-center"
            gutterBottoxm
          >
            We go beyond just talking about DUI awareness—we measure real
            impact. Our research-based approach tracks how listener attitudes
            and behaviors change over time, helping us improve future episodes
            and drive meaningful conversations.{" "}
          </Typography>
        </Box>
        <br />
        <SurveyCard data={surveyData} />
      </Container>
      <Container maxWidth="lg" sx={{ pb: 3 }} className="main_container">
        <Programs />
      </Container>
      <JoinConversation />

      <Container maxWidth="lg" sx={{ py: 3 }} className="main_container">
        <ResearchBased />
      </Container>

      <PodcastReviews
        accordionData={accordionData}
        reviewhandlebookDAta={reviewhandlebookDAta}
      />
    </Box>
  );
}

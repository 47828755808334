import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";

const SurveyCard = ({ data }) => {
  return (
    <Box sx={{ pb: 2, pt: 2, px: { xs: 2, md: 5 } }}>
      <Grid container spacing={3} justifyContent="center">
        {data.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              className="radius-12"
              sx={{
                overflow: "hidden",
                transition: "transform 0.3s ease-in-out",
                "&:hover": { transform: "scale(1.03)" },
                p: 1.5,
                border: "1px solid var(--color-midgray)",
              }}
            >
              <CardMedia
                component="img"
                height="200"
                image={item.imageUrl}
                alt={item.title}
                className="radius-12"
              />
              <CardContent className="px-0">
                <Typography
                  className="f-20 font-poppins fw-600"
                  fontFamily="bold"
                >
                  {item.title}
                </Typography>
                <Typography
                  className="font-lora f-18 color-para"
                  sx={{ mt: 1 }}
                >
                  {item.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default SurveyCard;

import {
  Box,
  Button,
  Container,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const OTPVerification = () => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);

  const handleChange = (index, value) => {
    if (value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
    }
  };

  const isOtpComplete = otp.every((digit) => digit !== "");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Entered OTP:", otp.join(""));
  };

  return (
    <Container
      component="main"
      maxWidth="lg"
      className="all_center"
      sx={{ minHeight: "100vh" }}
    >
      <Paper
        elevation={3}
        className="border-card-1 radius-12 bg-lightsky auth_card text-center"
        sx={{ p: 4, textAlign: "center" }}
      >
        <Typography className="styled_subheder_header fw-600 text-black">
          OPT Verification
        </Typography>
        <Typography
          className="fw-500 font-lora f-16 color-para"
          gutterBottom
          mb={4}
        >
          Enter the 6 digit code sent to Johndoe@example.com
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ mt: 2, display: "flex", justifyContent: "center", gap: 1 }}
        >
          {otp.map((digit, index) => (
            <TextField
              key={index}
              type="text"
              variant="outlined"
              inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
              value={digit}
              onChange={(e) => handleChange(index, e.target.value)}
              required
              className="otp_input"
              sx={{
                "& .MuiOutlinedInput-root fieldset": {
                  borderColor: isOtpComplete
                    ? "var(--color-lightGreen)"
                    : "inherit",
                },
                "& .MuiOutlinedInput-root:hover fieldset": {
                  borderColor: isOtpComplete
                    ? "var(--color-lightGreen)"
                    : "inherit",
                },
                "& .MuiOutlinedInput-root.Mui-focused fieldset": {
                  borderColor: isOtpComplete
                    ? "var(--color-lightGreen)"
                    : "inherit",
                },
              }}
            />
          ))}
        </Box>
        <Button
          to="/reset-password"
          component={Link}
          type="submit"
          fullWidth
          variant="contained"
          className={
            otp.some((digit) => digit === "")
              ? "bg_gray_btn text-white"
              : "contact_btn"
          }
          sx={{ mt: 3 }}
        >
          Verify
        </Button>
        <Typography className="color-para font-lora fw-500">
          Resend Code &ensp;
          <a href="/login-account" className="text-darksky font-poppins">
            03:00
          </a>
        </Typography>
      </Paper>
    </Container>
  );
};

export default OTPVerification;

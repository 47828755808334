import { Box, Container, Typography } from "@mui/material";
import React from "react";
import images from "../../../../../assets/images";

const WellnessBoard = () => {
  return (
    <Box className="all_center flex-column text-center" my={3}>
      <Box pb={2}>
        <img src={images.wellness} className="info_icon" alt="Icon" />
      </Box>
      {/* Heading */}
      <Typography
        variant="h5"
        mb={1}
        className="styled_subheder_header text-light font-poppins fw-600"
      >
        Why Choose Centerboard Wellness Center?
      </Typography>

      {/* Description */}
      <Container maxWidth="md">
        <Typography
          variant="body1"
          className="sub_header font-lora align-items-center text-light"
        >
          At Centerboard Wellness Center, we help you navigate life’s challenges
          with education, support, and direction. Our program not only fulfills
          legal requirements — it guides you toward better choices, personal
          growth, and a deeper connection to your community.
        </Typography>
      </Container>
    </Box>
  );
};

export default WellnessBoard;

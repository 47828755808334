import SettingsIcon from "@mui/icons-material/Settings";
import { Box } from "@mui/material";
import images from "../../../assets/images";
import CustomAppBar from "../../components/CustomAppBar";
import HIPAAIntakeForm from "./components/HIPAAIntakeForm";
import "./hippaform.css";

export default () => {
  const appBarData = [
    {
      title: "Client Management",
      icon: <SettingsIcon />,
      xp: "6000 XP",
      notifications: 90,
      profileImage: images.kimAvatar,
    },
  ];
  return (
    <Box className="d-flex justify-content-between align-items-center flex-wrap">
      <CustomAppBar {...appBarData[0]} />
      <HIPAAIntakeForm />
    </Box>
  );
};

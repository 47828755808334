import WindowIcon from "@mui/icons-material/Window";
import images from "../images";

export const poweredData = {
  title: "Navigating Wellness, Powered by You.",
  description:
    " Partner with Centerboard Wellness and align your brand with a mission that transforms lives. By sponsoring us, you’ll help individuals navigate their wellness journey while gaining visibility among a purpose-driven community. Join us in charting a course toward lasting impact—let’s make waves together!",
  icon: <WindowIcon fontSize="20px" className="window_icon" />,
  buttonText: "WHY PARTNER WITH US",
};

export const poweredDetailData = [
  {
    title: "Empowerment",
    description:
      "Supporting individuals on a path to independence and well-being.",
    icon: images.reflection,
  },
  {
    title: "Growth",
    description:
      "Helping fund critical programs for personal growth, financial literacy, and life skills.",
    icon: images.discovery,
  },
  {
    title: "Impact",
    description: "Making a tangible impact in our community.",
    icon: images.empowerment,
  },
];

import { Box, Container, Grid, Typography } from "@mui/material";

const CurriculumHeader = ({ title,subHeading, backgroundImage, details }) => {
  return (
    <Box
      className="flex-column all_center text-center main_slider"
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "auto",
        color: "var(--color-white)",
        // height: "85vh",
        height: {
          xs: "60vh",
          sm: "75vh",
          md: "80vh",
          lg: "85vh",
        },
      }}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={4}
          alignItems="center"
          justifyContent="center"
          sx={{
            padding: { xs: 4, md: 8, sm: 0 },
          }}
        >
          <Grid item xs={12} md={10} className="all_center flex-column">
            <Box>
              <Typography
                gutterBottom
                className="fw-600 main_header styled_header text-light"
                mb={1}
              >
                {title}
              </Typography>
             {subHeading?.length ? <Typography
                gutterBottom
                className="fw-600 text-light"
                mb={1}
              >
                {subHeading}
              </Typography>:null}
            </Box>
            <Grid className="d-flex gap-1 main_services">
              {details?.map((item, index) => (
                <Box key={index} display="flex" alignItems="center">
                  <span className="text-darksky"> {item.icon}</span>
                  <Typography
                    sx={{ ml: 1 }}
                    className="d-flex"
                    flexWrap="nowrap"
                  >
                    {item.label}
                  </Typography>
                </Box>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default CurriculumHeader;

import { Box } from "@mui/material";

export default function ImagesDetail({ userImages }) {
  const bgColors = ["#FF5733", "#33FF57", "#3357FF", "#F39C12", "#9B59B6"];

  return (
    <Box
      className="images_hero bg-none all_center"
      px={0}
      sx={{ justifyContent: "start" }}
    >
      {userImages?.map((imgSrc, index) => (
        <img
          key={index}
          src={imgSrc}
          alt={`User ${index + 1}`}
          className="user_icon"
          style={{
            marginLeft: index === 0 ? "0" : "-10%",
            backgroundColor: bgColors[index % bgColors.length],
          }}
        />
      ))}
    </Box>
  );
}

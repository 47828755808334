import { Container } from "@mui/material";
import NavigatorAccordion from "../../../components/NavigatorAccordion";
import PageHeader from "../../../components/PageHeader ";

export default function SelfDiscovery({ moduleData, pageData }) {
  return (
    <Container maxWidth="lg" sx={{ py: 5 }} className="main_container">
      <PageHeader {...pageData} />
      <NavigatorAccordion data={moduleData} checked="More Details" />
    </Container>
  );
}

import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import WindowIcon from "@mui/icons-material/Window";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import images from "../../../assets/images";

const Founder = () => {
  return (
    <Box className="main_container">
      <Container maxWidth="lg" sx={{ py: 5 }} className="founder_main">
        <Grid container spacing={4} alignItems="stretch">
          {/* Left Section - Text Content */}
          <Grid item xs={12} md={6} className="space_grid">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                p: 3,
                height: "100%",
              }}
            >
              <Box className="d-flex justify-content-start  gap-1" mb={3}>
                <Box className="rounded-btns all_center cursor-pointer" p={2}>
                  <WindowIcon fontSize="20px" className="window_icon" />
                </Box>
                <Typography
                  variant="button"
                  color="primary"
                  className="rounded-btns cursor-pointer text-darksky all_center capitalize_text"
                  py={1}
                  px={2}
                >
                  About Kimberly Sutherland
                </Typography>
              </Box>
              <Typography className="styled_header font-poppins fw-600">
                MEET THE FOUNDER
              </Typography>
              <Typography className="f-18 font-lora">
                Kimberly Sutherland, founder of Centerboard Wellness, is
                passionate about helping individuals chart a meaningful course
                toward personal growth and well- being. With a deep passion for
                self-discovery, she has created a supportive space where
                individuals can set goals, track progress, and celebrate their
                journey. Through Centerboard Wellness, Kimberly empowers others
                to navigate life’s challenges with clarity, resilience, and
                purpose.
              </Typography>
              {/* <Box>
                <Typography className="f-18 font-lora">
                  Kimberly’s empathetic approach and unwavering commitment to
                </Typography>
                <Typography
                  className="f-18 font-lora"
                  sx={{ width: { lg: "90%", md: "100%", xs: "90%" } }}
                >
                  transformation guide clients through life’s challenges,
                  ensuring
                </Typography>
                <Typography
                  className="f-18 font-lora"
                  sx={{ width: { lg: "90%", md: "100%", xs: "80%" } }}
                >
                  they feel empowered, grounded, and ready to thrive. Through
                </Typography>
                <Typography
                  className="f-18 font-lora"
                  sx={{ width: { lg: "75%", xs: "70%" } }}
                >
                  Centerboard Wellness, she continues to inspire others to
                  navigate life with clarity, resilience, and purpose.
                </Typography>
              </Box> */}

              <Box position="relative">
                <Button
                  variant="contained"
                  className="contact_btn mb-0"
                  endIcon={<ArrowOutwardIcon />}
                >
                  Meet Kimberly
                </Button>
                <img
                  src={images.map}
                  alt="map"
                  className="map_icon pos-absolute"
                />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <img src={images.founder} alt="founder" className="fix_img" />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Founder;

import { Card, CardContent, Container, Grid, Typography } from "@mui/material";

const Categories = ({ categories }) => {
  return (
    <Container>
      <Grid container spacing={3} justifyContent="center" sx={{ mt: 3 }}>
        {categories?.map((category, index) => (
          <Grid item xs={12} sm={6} md={4} lg={2.4} key={index}>
            <Card
              sx={{
                height: "100%",
                borderRadius: 2,
                boxShadow: 3,
              }}
            >
              <CardContent>
                {category.icon}
                <Typography
                  className="f-18 font-poppins fw-500 text-black"
                  sx={{ mt: 2 }}
                >
                  {category.title}
                </Typography>
                <Typography
                  className="f-14 font-lora fw-400 color-para"
                  sx={{ mt: 1 }}
                >
                  {category.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Categories;

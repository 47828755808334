import WindowIcon from "@mui/icons-material/Window";
import { Box, Container, Typography } from "@mui/material";
import images from "../../../../assets/images";
import StatementContainer from "../../../components/StatementContainer";

const GrowthCurriculum = ({ growthData }) => {
  return (
    <Container maxWidth="lg" sx={{ py: 5 }} className="main_container">
      <Box className="all_center gap-1" mb={3}>
        <Box className="rounded-btns all_center cursor-pointer" p={2}>
          <WindowIcon fontSize="20px" className="window_icon" />
        </Box>
        <Typography
          variant="button"
          color="primary"
          className="rounded-btns cursor-pointer text-darksky capitalize_text"
          py={1}
          px={2}
        >
          Introduction
        </Typography>
      </Box>
      <Box className="all_center">
        <Box className="text-center w-75">
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            className="styled_header fw-600 font-poppins"
          >
            Introduction to personal growth and self-awareness.{" "}
          </Typography>
          <Typography
            variant="body1"
            align="center"
            color="text.secondary"
            className="f-18"
          >
            Welcome to Your Journey! You’re the captain of your ship, ready to
            set sail toward a brighter future. Let’s prepare for the voyage by
            understanding where you are now and where you want to go.
          </Typography>
        </Box>
      </Box>

      <StatementContainer image={images.growth} data={growthData} />

    
    </Container>
  );
};

export default GrowthCurriculum;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://www.centerboardwellnesscenter.com/api",
    prepareHeaders: (headers, { endpoint }) => {
      const token = localStorage.getItem("authToken");
      console.log(token, "tokenoi");

      if (token && endpoint !== "loginUser" && endpoint !== "createUser") {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["Signup", "Post", "Intake"],

  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => "/auth/signup",
      providesTags: ["Signup"],
    }),

    createUser: builder.mutation({
      query: (userData) => ({
        url: "/auth/signup",
        method: "POST",
        body: userData,
      }),
      invalidatesTags: ["Signup"],
    }),

    loginUser: builder.mutation({
      query: (credentials) => ({
        url: "/auth/signin",
        method: "POST",
        body: credentials,
      }),
    }),

    submitIntakeForm: builder.mutation({
      query: (intakeData) => ({
        url: "/hipa-compliance/intake",
        method: "POST",
        body: intakeData,
      }),
      invalidatesTags: ["Intake"],
    }),

    getIntakeForms: builder.query({
      query: () => "/hipa-compliance/intake",
      providesTags: ["Intake"],
    }),

    changePassword: builder.mutation({
      query: (passwordData) => ({
        url: "/change-password",
        method: "PATCH",
        body: passwordData,
      }),
    }),
  }),
});

export const {
  useGetUsersQuery,
  useCreateUserMutation,
  useLoginUserMutation,
  useSubmitIntakeFormMutation,
  useGetIntakeFormsQuery,
  useChangePasswordMutation,
} = apiSlice;

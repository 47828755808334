import { Facebook, Instagram, LinkedIn } from "@mui/icons-material";
import WindowIcon from "@mui/icons-material/Window";
import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import images from "../../../assets/images";

const CurrentSponser = () => {
  const sponsors = [
    {
      name: "Alex Morgan",
      title: "CEO Wellness Hub",
      imgUrl: images.ceo,
    },
    {
      name: "Ayzel Jacqueline",
      title: "CEO Yoga Club",
      imgUrl: images.director,
    },
    {
      name: "John Doe",
      title: "CEO Mindfulness",
      imgUrl: images.manager,
    },
  ];

  // Slider settings
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoPlay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <Box sx={{ py: 4 }}>
      <Box className="all_center gap-1" mb={3}>
        <Box className="rounded-btns all_center cursor-pointer" p={2}>
          <WindowIcon fontSize="20px" className="window_icon" />{" "}
        </Box>
        <Typography
          variant="button"
          color="primary"
          className="rounded-btns cursor-pointer text-darksky"
          py={1}
          px={2}
        >
          Our Current Sponsors
        </Typography>
      </Box>
      <Slider {...settings}>
        {sponsors.map((sponsor, index) => (
          <Box
            key={index}
            sx={{
              padding: 2,
              borderRadius: "8px",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <Box
              component="img"
              src={sponsor.imgUrl}
              alt={sponsor.name}
              sx={{
                width: "100%",
                height: "auto",
                objectFit: "cover",
              }}
            />
            <Box
              sx={{
                position: "absolute",
                bottom: 12,
                left: 15,
                color: "white",
                padding: 2,
                textAlign: "start",
              }}
            >
              <Typography className="fw-500 font-poppins text-light f-18">
                {sponsor.name}
              </Typography>
              <Typography className="fw-500 font-lora text-light f-16">
                {sponsor.title}
              </Typography>
            </Box>
            <Box
              sx={{
                position: "absolute",
                bottom: 25,
                right: -20,
                transform: "translateX(-50%)",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <IconButton sx={{ color: "white" }}>
                <LinkedIn className="f-18" />
              </IconButton>
              <IconButton sx={{ color: "white" }}>
                <Instagram className="f-18" />
              </IconButton>
              <IconButton sx={{ color: "white" }}>
                <Facebook className="f-18" />
              </IconButton>
            </Box>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default CurrentSponser;

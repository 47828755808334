import {
  AccessTime,
  Layers,
  Loop,
  MenuBook,
  People,
} from "@mui/icons-material";
import GppBadIcon from "@mui/icons-material/GppBad";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  LinearProgress,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import images from "../../../assets/images";
import QuizHeader from "../pages/components/QuizHeader";
import RequirementCard from "../pages/components/RequirementCard";
import AlertComponent from "../pages/EnrolledCourses/components/AlertComponent";

export default () => {
  const courseDetails = [
    { icon: <AccessTime fontSize="small" />, text: "30 minutes" },
    { icon: <HelpOutlineIcon fontSize="small" />, text: "30 Questions" },
    { icon: <Layers fontSize="small" />, text: `3 modules` },
    { icon: <People fontSize="small" />, text: `22 students` },
    { icon: "🏆", text: "300 XP" },
  ];
  const questions = [
    {
      question: "Which penalty is most common for a first-time DUI offense?",
      options: [
        "Warning only",
        "Life ban",
        "Fine & suspension",
        "No consequences",
      ],
      correct: 2,
    },
    {
      question:
        "What is the legal blood alcohol concentration (BAC) limit in most states?",
      options: ["0.02%", "0.05%", "0.08%", "0.1%"],
      correct: 2,
    },
    {
      question: "What happens if you refuse a breathalyzer test?",
      options: ["Nothing", "License suspension", "Fine only", "Jail time"],
      correct: 1,
    },
    // Add more questions up to 30
  ];
  const totalQuestions = questions.length;
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(30 * 60);
  const [timerActive, setTimerActive] = useState(true);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [completedQuestions, setCompletedQuestions] = useState(0);
  const [incorrectAnswers, setIncorrectAnswers] = useState(0);
  const [attemptedQuestions, setAttemptedQuestions] = useState(new Set());
  const [lastIncorrectQuestion, setLastIncorrectQuestion] = useState(null);

  // Handle Option Selection
  const handleSelect = (event) => {
    setSelectedAnswer(parseInt(event.target.value));
  };

  const handleNext = () => {
    if (selectedAnswer === null) return;

    if (!attemptedQuestions.has(currentQuestion)) {
      setAttemptedQuestions((prev) => new Set(prev).add(currentQuestion));

      if (selectedAnswer !== questions[currentQuestion].correct) {
        setShowAlert(true);
        setIncorrectAnswers((prev) => prev + 1);
        setLastIncorrectQuestion(currentQuestion);
        setTimeout(() => setShowAlert(false), 3000);
      } else {
        setShowAlert(true);
        setLastIncorrectQuestion(null);
        setCompletedQuestions((prev) => prev + 1);
        setTimeout(() => setShowAlert(false), 3000);
      }
    }

    if (currentQuestion < totalQuestions - 1) {
      setCurrentQuestion((prev) => prev + 1);
      setSelectedAnswer(null);
    } else {
      console.log("Quiz Completed!");
      navigate("/dashboard/enrolled-courses/end-quiz");
    }
  };
  // Previous Question
  const handlePrevious = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
      setSelectedAnswer(
        attemptedQuestions.has(currentQuestion - 1)
          ? questions[currentQuestion - 1].options.indexOf(
              questions[currentQuestion - 1].options[
                questions[currentQuestion - 1].correct
              ]
            )
          : null
      );
    }
  };

  useEffect(() => {
    let intervalId;
    if (timerActive && timeLeft > 0) {
      intervalId = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [timerActive, timeLeft]);

  const handleExit = () => {
    setTimerActive(false);
    setTimeLeft(0);
  };

  // Calculate minutes and seconds
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  const correctAnswer =
    questions[lastIncorrectQuestion]?.options[
      questions[lastIncorrectQuestion]?.correct
    ];

  return (
    <Box className="d-flex justify-content-between align-items-center flex-wrap">
      <Box>
        <QuizHeader
          title="Final Quiz"
          user={{ name: "Michael Jenkins", avatar: images.kimAvatar }}
          details={courseDetails}
        />
      </Box>
      <Box className="d-flex gap-1 flex-wrap">
        <Button
          className="border-card-1 border-r-8 text-darksky font-poppins f-16 gap-1"
          onClick={handleExit}
        >
          Exit <GppBadIcon fontSize="small" />
        </Button>

        {/* Timer display */}

        <Typography
          variant="h5"
          className="d-flex gap-1 border-card-1 border-r-8 text-black font-poppins f-29"
          sx={{ padding: "5px 20px" }}
        >
          Time Left: &nbsp;
          {minutes.toString().padStart(2, "0")}:
          {seconds.toString().padStart(2, "0")}
        </Typography>
      </Box>
      {/* progress slider */}
      <Grid container spacing={2} sx={{ p: 2 }}>
        {/* 8-Column Box */}
        <Grid item xs={12} md={9}>
          {/* Progress Bar */}
          <Box sx={{ mt: 2 }}>
            <LinearProgress
              variant="determinate"
              value={(attemptedQuestions.size / totalQuestions) * 100}
              sx={{
                my: 2,
                p: "6px",
                borderRadius: "8px",
                backgroundColor: "lightgray",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#00ACC1",
                },
              }}
            />
            <Typography className="text-blue fw-500 font-poppins f-14 text-start">
              Question {currentQuestion + 1} of {totalQuestions}
            </Typography>
          </Box>

          {/* Question Box */}
          <Paper
            elevation={3}
            sx={{
              px: 3,
              mt: 2,
              py: 5,
              borderRadius: "12px",
              //   backgroundColor: "#F5F9FC",
            }}
          >
            <Typography
              className="penalities text-center fw-500 f-14 font-poppins"
              mb={3}
            >
              DUI Penalties & Fines
            </Typography>
            <Typography
              className="text-start fw-500 sub_header font-poppins"
              mb={4}
            >
              {questions[currentQuestion].question}
            </Typography>

            {/* Options */}
            <RadioGroup
              value={selectedAnswer}
              onChange={handleSelect}
              sx={{
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "10px",
                "@media (max-width: 600px)": {
                  gridTemplateColumns: "1fr",
                },
              }}
              className="game_grid"
            >
              {questions[currentQuestion].options.map((option, index) => (
                <FormControlLabel
                  key={index}
                  value={index}
                  control={
                    <Radio
                      sx={{
                        color:
                          selectedAnswer === index &&
                          questions[currentQuestion].correct !== index
                            ? "red"
                            : undefined,
                        "&.Mui-checked": {
                          color:
                            selectedAnswer === index &&
                            questions[currentQuestion].correct !== index
                              ? "red"
                              : "var(--color-lightGreen)",
                        },
                      }}
                    />
                  }
                  label={option}
                  className="w-100 f-16 font-lora "
                  sx={{
                    border:
                      selectedAnswer === index
                        ? questions[currentQuestion].correct === index
                          ? "2px solid var(--color-lightGreen)"
                          : "2px solid red"
                        : "1px solid #ccc",
                    borderRadius: "8px",
                    padding: "8px 16px",
                    textAlign: "left",
                    backgroundColor:
                      selectedAnswer === index
                        ? questions[currentQuestion].correct === index
                          ? "var(--color-lightBgGreen)"
                          : "rgba(255, 0, 0, 0.1)"
                        : "white",
                  }}
                />
              ))}
            </RadioGroup>
          </Paper>

          {/* Navigation Buttons */}
          <Box
            className="d-flex justify-content-between align-items-center"
            mt={3}
          >
            <Button
              variant="outlined"
              disabled={currentQuestion === 0}
              className="next_btn f-16"
              onClick={handlePrevious}
              sx={{
                backgroundColor:
                  currentQuestion === 0 ? "white !important" : "inherit",
                color: "white",
                "&.Mui-disabled": { backgroundColor: "white", color: "gray" },
              }}
            >
              ← Previous Question
            </Button>

            <Button
              variant="outlined"
              onClick={handleNext}
              disabled={selectedAnswer === null}
              className="next_btn f-16 pb-0"
              sx={{
                backgroundColor:
                  selectedAnswer === null ? "white !important" : "primary.main",
                color: "white",
                "&.Mui-disabled": { backgroundColor: "white", color: "gray" },
              }}
            >
              {currentQuestion === totalQuestions - 1
                ? "Finish Quiz"
                : "Next Question →"}
            </Button>
          </Box>
        </Grid>

        {/* 4-Column Box */}
        <Grid item xs={12} md={3} className="d-flex flex-column gap-1 w-100">
          <Card elevation={3} className="radius-12" p={2}>
            <CardContent>
              <Box display="flex" alignItems="center" gap={1} mb={1}>
                <Loop sx={{ color: "var(--color-darkSky)" }} />
                <Typography className="f-16 font-poppins fw-500 text-black">
                  Quiz Progress
                </Typography>
              </Box>
              <Typography className="f-12 font-poppins fw-400 color-para">
                Completed
              </Typography>
              <Typography className="text-end f-12 font-poppins fw-400 color-para">
                {attemptedQuestions.size}/{totalQuestions}
              </Typography>
              <LinearProgress
                variant="determinate"
                value={(attemptedQuestions.size / totalQuestions) * 100}
                sx={{
                  p: "6px",
                  borderRadius: "8px",
                  backgroundColor: "lightgray",
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#00ACC1",
                  },
                }}
              />

              <Typography
                className="text-end f-12 font-poppins fw-400 color-para"
                mt={2}
              >
                {completedQuestions}/{totalQuestions}
              </Typography>
              <Typography className="f-12 font-poppins fw-400 color-para">
                Correct Answers
              </Typography>

              <Typography className="text-end f-12 font-poppins fw-400 color-para">
                {incorrectAnswers}/{totalQuestions}
              </Typography>
              <Typography className="f-12 font-poppins fw-400 color-para">
                Incorrect Answers
              </Typography>
            </CardContent>
          </Card>
          {/* Modules Coverage Card */}
          <Card elevation={3} className="radius-12">
            <CardContent>
              <Box display="flex" alignItems="center" gap={1} mb={1}>
                <MenuBook sx={{ color: "var(--color-darkSky)" }} />
                <Typography className="f-16 font-poppins fw-500 text-black">
                  Modules Coverage
                </Typography>
              </Box>

              <RadioGroup defaultValue="level1">
                <FormControlLabel
                  value="level1"
                  control={<Radio color="success" />}
                  label="Level I Education"
                />
                {/* <CircularProgress value="100" variant="determinate" /> */}
                <FormControlLabel
                  value="level2"
                  control={<Radio />}
                  label="Level II Education"
                />
                <FormControlLabel
                  value="level3"
                  control={<Radio />}
                  label="Level III Therapy"
                />
              </RadioGroup>
            </CardContent>
          </Card>

          <Box>
            <RequirementCard
              title="Achievements"
              requirement="Complete this quiz to claim the purple badge."
              badge={images.rankIcon}
            />
          </Box>
        </Grid>
      </Grid>
      {showAlert && (
        <AlertComponent
          correctAnswer={
            lastIncorrectQuestion !== null
              ? questions[lastIncorrectQuestion]?.options[
                  questions[lastIncorrectQuestion]?.correct
                ]
              : "Kai's impressed! You're on fire"
          }
          image={
            lastIncorrectQuestion !== null ? images.wrongQuiz : images.corrected
          }
          isCorrect={lastIncorrectQuestion === null}
        />
      )}
    </Box>
  );
};

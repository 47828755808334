import {
  AccessTime,
  Layers,
  People,
  SignalCellularAlt,
} from "@mui/icons-material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import StarIcon from "@mui/icons-material/Star";
import { Box, Breadcrumbs, Button, Link, Typography } from "@mui/material";
import images from "../../../assets/images";
import QuizHeader from "../pages/components/QuizHeader";
import ReadyCard from "./components/ReadyCard";

export default () => {
  const courseDetails = [
    { icon: <AccessTime fontSize="small" />, text: "2 Weeks" },
    { icon: <SignalCellularAlt fontSize="small" />, text: "Intermediate" },
    { icon: <Layers fontSize="small" />, text: `3 modules` },
    { icon: <People fontSize="small" />, text: `22 students` },
    { icon: "🏆", text: "4.9" },
  ];

  const quizTips = [
    {
      title: "Study First",
      subtitle: "Quiz Tip",
      description: "Review materials in the resource section.",
      iconType: "study",
    },
    {
      title: "Time Matters",
      subtitle: "Quiz Tip",
      description: "You have 1 minute per question.",
      iconType: "time",
    },
    {
      title: "No Going Back",
      subtitle: "Quiz Tip",
      description: "Once you move forward, you can't return.",
      iconType: "back",
    },
    {
      title: "Scoring System",
      subtitle: "Quiz Tip",
      description: "300 points total, 10 points per question.",
      iconType: "score",
    },
  ];
  return (
    <>
      <Box className="d-flex justify-content-between align-items-center flex-wrap">
        <QuizHeader
          title="Final Quiz"
          user={{ name: "Michael Jenkins", avatar: images.kimAvatar }}
          details={courseDetails}
        />
        <Button variant="outlined" className="light-border">
          {/* Badge Icon */}
          <Box className="all_center circular-gradient-icon">
            <StarIcon sx={{ color: "white", fontSize: 18 }} />
          </Box>

          {/* Text */}
          <Typography className="color-para f-14 text capitalize_text font-poppins">
            Claim your badge
          </Typography>
        </Button>
      </Box>
      <Box
        sx={{ p: 1, flexWrap: "wrap" }}
        className="d-flex align-items-center"
      >
        {/* Icon */}
        <PlayCircleOutlineIcon fontSize="small" sx={{ mr: 1 }} />

        {/* Breadcrumbs Navigation */}
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            href="#"
            underline="hover"
            className="color-para font-poppins fw-500 f-16"
          >
            Enrolled Courses
          </Link>
          <Link
            href="education-level"
            underline="hover"
            className="color-para font-poppins fw-500 f-16"
          >
            DUI A Holistic Journey
          </Link>
          <Typography className="text-darksky font-poppins fw-500 f-16">
            Final Quiz
          </Typography>
        </Breadcrumbs>
      </Box>

      <Box>
        <Typography
          className="text-black sub_header font-poppins fw-500"
          mt={3}
        >
          Quiz Tips:
        </Typography>
        <Box className="d-flex flex-wrap" mt={2}>
          {quizTips.map((tip, index) => (
            <ReadyCard
              key={index}
              title={tip.title}
              subtitle={tip.subtitle}
              description={tip.description}
              iconType={tip.iconType}
            />
          ))}

          <Link
            href="quiz-game"
            variant="contained"
            className="contact_btn f-16 fw-500  text-light"
            sx={{ mt: 2, py: "10px" }}
          >
            Yes, I am ready
          </Link>
        </Box>
      </Box>
    </>
  );
};

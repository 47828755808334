import AccessTimeIcon from "@mui/icons-material/AccessTime";
import BarChartIcon from "@mui/icons-material/BarChart";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DescriptionIcon from "@mui/icons-material/Description";
import PersonIcon from "@mui/icons-material/Person";
import QuizIcon from "@mui/icons-material/Quiz";
import ScheduleIcon from "@mui/icons-material/Schedule";
import SchoolIcon from "@mui/icons-material/School";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import WindowIcon from "@mui/icons-material/Window";
import images from "../images";

export const holisticBannerData = [
  { icon: <AccessTimeIcon />, label: "2 Weeks" },
  { icon: <BarChartIcon />, label: "Intermediate" },
  { icon: <ViewModuleIcon />, label: "2 Modules" },
  { icon: <QuizIcon />, label: "1 Quiz" },
  { icon: <SchoolIcon />, label: "22 Students" },
  { icon: <PersonIcon />, label: "Michael Jenkins" },
];

export const duiJourneyData = {
  title:
    "Navigating Out of the DUI Port: A Journey to Self-Awareness and Change",
  content: [
    "At Centerboard Wellness Center, we believe in addressing both the external challenges of the legal system and the internal journey of personal growth. As you embark on this dual path, rest assured that we're here to guide you every step of the way.",
    "Embracing Safe Roads: Understanding Colorado's Journey to Responsible Driving",
    "Welcome aboard! At Centerboard Wellness Center, we are dedicated to helping you navigate the road to responsible driving and personal growth. Our program is designed to provide you with the tools, knowledge, and support necessary to make informed and positive decisions.",
    "Driving under the influence is a critical issue that affects not only individuals but also families and communities. Through education, self-awareness, and accountability, our program aims to empower participants to take charge of their actions, understand the consequences, and build a safer future.",
  ],
  imageUrl: images.dui,
  imageAlt: "DUI Holistic Journey Image",
};

export const programsData = {
  title: "Your Path to Change Begins Here.",
  description:
    "Join us on this transformative journey toward safer roads and responsible driving. Together, we can build a safer community and a brighter future.",
  icon: <WindowIcon fontSize="20px" className="window_icon" />,
  buttonText: "Program Offerings",
};

export const programsAccordianData = [
  {
    title: "Level I Education",
    moduleInfo: "6 sessions • 12 hours • 6 weeks",
    description:
      "Focusing on foundational knowledge and decision-making regarding impaired driving and responsible habits, aligned with Level I education.",
  },
  {
    title: "Level II Education",
    moduleInfo: "12 sessions • 24 hours • 12 weeks",
    description:
      "Explores deeper concepts, including decision-making strategies and the role of accountability in reducing recidivism.",
  },
  {
    title: "Track A Therapy",
    moduleInfo: "21 sessions • 42 hours • ~5 months",
    description:
      "Designed to address individual needs through therapeutic interventions for long-term behavior change.",
  },
  {
    title: "Track B Therapy",
    moduleInfo: "26 sessions • 52 hours • ~6.5 months",
    description:
      "Designed to address individual needs through therapeutic interventions for long-term behavior change.",
  },
  {
    title: "Track C Therapy",
    moduleInfo: "34 sessions • 68 hours • ~8.5 months",
    description:
      "Designed to address individual needs through therapeutic interventions for long-term behavior change.",
  },
  {
    title: "Track D Therapy",
    moduleInfo: "43 sessions • 86 hours • ~10.5 months",
    description:
      "Designed to address individual needs through therapeutic interventions for long-term behavior change.",
  },
];

export const drivesChangeData = {
  title: "Navigating Toward Better Choices",
  description:
    "Every decision behind the wheel matters—our program helps you stay informed, accountable, and committed to safer choices.",
  icon: <WindowIcon fontSize="20px" className="window_icon" />,
  buttonText: "Program Objectives",
};
export const drivesData = [
  {
    title: "Raise Awareness",
    description:
      "Empower participants to recognize the risks of impaired driving and commit to safer choices.",
    icon: images.reflection,
  },
  {
    title: "Foster Accountability",
    description:
      "Help participants take ownership of their decisions and recognize how their actions affect themselves and the community.",
    icon: images.discovery,
  },
  {
    title: "Promote Positive Change",
    description:
      "Offer strategies for making safer choices, using alternative transportation, and seeking support.",
    icon: images.empowerment,
  },
];

export const classDetails = [
  {
    imgSrc: <DescriptionIcon />,
    title: "Materials Provided:",
    description:
      "Embracing Safe Roads: Understanding Colorado's Journey to Responsible Driving' by Centerboard Wellness Center",
  },
  {
    imgSrc: <ScheduleIcon />,
    title: "Class Times:",
    description: [
      {
        title: "Level I Education",
        description:
          "6 sessions | 12 hours | Estimated Duration: 1.5 months (6 weeks)",
      },
      {
        title: "Level II Education",
        description: "12 sessions | 24 hours | Estimated Duration: 3 months",
      },
      {
        title: "Level II Therapy Tracks",
        description: [
          {
            title: "Track A",
            description:
              "21 sessions | 42 hours | Estimated Duration: 5 months",
          },
          {
            title: "Track B",
            description:
              "26 sessions | 52 hours | Estimated Duration: 6.5 months",
          },
          {
            title: "Track C",
            description:
              "34 sessions | 68 hours | Estimated Duration: 8.5 months",
          },
          {
            title: "Track D",
            description:
              "43 sessions | 86 hours | Estimated Duration: 11 months",
          },
        ],
      },
    ],
  },
  {
    imgSrc: <TextSnippetIcon />,
    title: "Requirements:",
    description:
      "Completion of Level II Education is required before beginning Level II Therapy.",
  },
];

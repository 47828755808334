import VerifiedIcon from "@mui/icons-material/Verified";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";
import React, { useState } from "react";
import "./price.css";

const PricingPlans = ({ pricingPlans }) => {
  const [planType, setPlanType] = useState("monthly");

  const makePayment = async (plan) => {
    console.log(plan, "plan9");
    const stripe = await loadStripe(
      "pk_test_51R3fm7P6cPQgeUhuP9qd2NRCmVDxuOipcGjMzddtKoSkry3e7UK9QnH4iTZqVJg5cQtpyojdxYKwHapw1Zgcmht800jQZQI1ZZ"
    );

    // Send the selected plan's data to the backend
    const body = {
      products: [{ name: plan.title, price: plan.price, quantity: 1 }],
    };

    const headers = {
      "Content-Type": "application/json",
    };

    const response = await fetch("/create-checkout-session", {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    });

    const session = await response.json();
    console.log("Session ID:", session.id);

    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      console.error(result.error.message);
    }
  };

  return (
    <Container maxWidth="lg" className="text-center" sx={{ py: 4, mb: 3 }}>
      <Box className="text-center investment_section">
        <Typography
          align="center"
          gutterBottom
          className="styled_header fw-600 font-poppins"
        >
          Invest in Your Wellness Journey to thrive{" "}
        </Typography>
        <Typography
          variant="body1"
          align="center"
          color="text.secondary"
          className="f-18 color-para"
        >
          Choose a membership that fits your wellness goals. With expert
          guidance, personalized programs, and a supportive community, your
          journey to better health starts now. Find the plan that works for you!{" "}
        </Typography>
      </Box>
      <Box className="all_center">
        <Tabs
          value={planType}
          onChange={(e, newValue) => setPlanType(newValue)}
          centered
          className="price_tab"
          sx={{
            "& .MuiTabs-indicator": { display: "none" },
          }}
        >
          <Tab
            className="f-14 font-poppins fw-500"
            label="Monthly"
            value="monthly"
          />
          <Tab
            className="f-14 font-poppins fw-500 "
            label="Annual"
            value="annual"
          />
        </Tabs>
      </Box>
      <Grid
        container
        spacing={3}
        className={
          pricingPlans[planType][1] === "Transformation Journey"
            ? "card_grid all_center"
            : "all_center"
        }
        sx={{ mt: 3 }}
      >
        {pricingPlans[planType].map((plan, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box
              className={
                plan.title !== "Transformation Journey"
                  ? ""
                  : "card_height_rec card_bg"
              }
            >
              <Box
                className={`${
                  plan.title === "Transformation Journey"
                    ? "pricing_card "
                    : "price-cards-borders"
                }`}
              >
                {plan.title === "Transformation Journey" ? (
                  <Typography
                    className="f-18 fw-600 font-poppins text-light plan_title mb-0"
                    pt={2}
                  >
                    RECOMMENDED{" "}
                  </Typography>
                ) : null}
                <Card
                  sx={{
                    p: "5px",
                    backgroundColor: "transparent",
                    borderRadius: "24px",
                  }}
                  className={
                    plan.title !== "Transformation Journey"
                      ? "card_height"
                      : "h-100"
                  }
                >
                  <CardContent
                    className={`text-start  ${
                      plan.title === "Transformation Journey"
                        ? "pricing_card_content"
                        : ""
                    }`}
                  >
                    <Typography
                      gutterBottom
                      className="f-20 font-poppins fw-500 text-black mb-0"
                    >
                      {plan.title}
                    </Typography>
                    <Typography
                      gutterBottom
                      className="f-14 font-lora fw-400 color-para"
                      mb={1}
                    >
                      {plan.subTitle}
                    </Typography>
                    <Typography className="styled_subheder_header font-poppins fw-500 text-black">
                      {plan.price}{" "}
                      <span style={{ fontSize: "16px" }}>/ per {planType}</span>
                    </Typography>
                    <ul style={{ textAlign: "left", paddingLeft: "0px" }}>
                      {plan.features.map((feature, i) => (
                        <Typography
                          key={i}
                          className="f-14 font-poppins fw-500 color-para mb-1 d-flex align-items-center"
                        >
                          <VerifiedIcon
                            sx={{ mr: "10px" }}
                            className="text-darksky"
                          />{" "}
                          {feature}
                        </Typography>
                      ))}
                    </ul>

                    <Button
                      variant="contained"
                      fullWidth
                      sx={{ mt: 2 }}
                      className="contact_btn f-16 w-100 mb-0"
                      // type="submit"
                      mt={5}
                      onClick={() => makePayment(plan)}
                    >
                      Select Plan
                    </Button>
                    {plan.title === "Transformation Journey" && (
                      <a href="" className="text-center">
                        <Typography
                          mt={2}
                          className="text-darkblue font-poppins f-14 fw-500"
                        >
                          Contact Us
                        </Typography>
                      </a>
                    )}

                    <Typography
                      className={
                        plan.title === "Transformation Journey"
                          ? "transformation_btn"
                          : ""
                      }
                    ></Typography>
                  </CardContent>
                </Card>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default PricingPlans;

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import WindowIcon from "@mui/icons-material/Window";
import { Box, Container, Grid, Typography } from "@mui/material";

const LegalHandbook = ({ data }) => {
  if (!data) {
    return <div>Loading...</div>;
  }

  const { title, content, imageUrl, imageAlt, detail } = data;

  return (
    <Container maxWidth="lg" sx={{ py: 5, mt: 3 }}>
      <Grid container spacing={4} alignItems="stretch">
        <Grid item xs={12} md={6}>
          <Box className="all_center gap-1" mb={3} justifyContent="start">
            <Box className="rounded-btns all_center cursor-pointer" p={2}>
              <WindowIcon fontSize="20px" className="window_icon" />
            </Box>
            <Typography
              variant="button"
              color="primary"
              className="rounded-btns cursor-pointer text-darksky capitalize_text"
              py={1}
              px={2}
            >
Introduction            </Typography>
          </Box>
          <Box>
            <Typography className="styled_header fw-600 font-poppins" m>
              {title}
            </Typography>
            {content.map((paragraph, index) => (
              <Typography
                mt={2}
                mb={1}
                className={`font-lora align-items-center color-para ${
                  index === 1
                    ? "fw-600 text-black font-poppins sub_header"
                    : "f-18"
                }`}
                key={index}
              >
                {paragraph}
              </Typography>
            ))}

            {detail &&
              detail.map((paragraph, index) => (
                <Box
                  className="d-flex align-items-center gap-1"
                  justifyContent="start"
                  mx={2}
                >
                  <FiberManualRecordIcon sx={{ fontSize: "8px", mt: "px" }} />
                  <Typography
                    className="font-lora align-items-center color-para"
                    key={index}
                  >
                    {paragraph}
                  </Typography>
                </Box>
              ))}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              borderRadius: "10px",
              overflow: "hidden",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={imageUrl}
              alt={imageAlt}
              className="object-fit-cover"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                display: "block",
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LegalHandbook;

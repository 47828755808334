import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { Box, Button, CardMedia, Typography } from "@mui/material";
import images from "../../../../assets/images";

export default function JourneyCurriculum() {
  return (
    <Box maxWidth="lg" sx={{ py: 5 }} className="main_container">
      <Box
        className="all_center"
        sx={{
          flexDirection: { xs: "column", md: "row" },
          padding: "20px",
        }}
      >
        <Box>
          <CardMedia
            component="img"
            image={images.women}
            alt="women Image"
            className="fix_img"
          />
        </Box>

        <Box
          position="relative"
          sx={{
            width: { xs: "100%", md: "50%" },
            padding: "20px",
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <Box className="boll"></Box>
          <Typography variant="h5" className="journey_header">
            Your Journey is Just Beginning. Keep exploring, keep growing, and
            remember—you’re the captain of your ship, and you have the power to
            navigate life’s seas with confidence and purpose.
          </Typography>
          <Button
            variant="contained"
            className="contact_btn f-16"
            endIcon={<ArrowOutwardIcon />}
          >
            Start Your Journey Today!
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import BarChartIcon from "@mui/icons-material/BarChart";
import CheckIcon from "@mui/icons-material/Check";
import PersonIcon from "@mui/icons-material/Person";
import QuizIcon from "@mui/icons-material/Quiz";
import SchoolIcon from "@mui/icons-material/School";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import WindowIcon from "@mui/icons-material/Window";
import images from "../images";

export const podcastData = [
  { icon: <AccessTimeIcon />, label: "2 Weeks" },
  { icon: <BarChartIcon />, label: "Intermediate" },
  { icon: <ViewModuleIcon />, label: "2 Modules" },
  { icon: <QuizIcon />, label: "1 Quiz" },
  { icon: <SchoolIcon />, label: "10 Students" },
  { icon: <PersonIcon />, label: "Michael Jenkins" },
];

// todo
export const educationPodcast = {
  title: "Navigation DUI Prevention: A Podcast Journey",
  content: [
    "Welcome to the Vision Quest Podcast! We uncover the real impact of DUI, legal consequences, and behavior change. Through expert interviews, real-life stories, and insightful discussions, we explore how fear, attitude, and incentives shape choices. Our goal is simple: help individuals make responsible decisions, stay out of legal trouble, and build a safer community.",
    "Hypothesis for the Podcast.",
  ],
  detail: [
    "Fear of DUI consequences motivates behavior change",
    "Attitude shifts help people reject drinking & driving",
    "Incentives (like alternative transportation) provide real solutions",
  ],
  imageUrl: images.education,
  imageAlt: "education",
};

export const faceFearData = {
  title: "Fear, Attitude & Incentives: The Road to Safer Decisions",
  description:
    "This podcast takes a deep dive into the real-life consequences of DUI, exploring the legal, emotional, and financial impact. Through expert interviews and personal stories, we uncover how fear, attitude, and incentives shape behavior and influence better choices. Our mission is simple: reduce DUI-related offenses and promote responsible decision-making.",
  icon: <WindowIcon fontSize="20px" className="window_icon" />,
  buttonText: "Podcast Plan Overview",
};

export const faceFearDetail = [
  {
    title: "Theme",
    detail: [
      {
        subHeading: "Fear",
        para: "Consequences of DUI (legal, financial, personal safety)",
        icon: images.fear,
      },
      {
        subHeading: "Attitude",
        para: "Breaking myths & changing perceptions",
        icon: images.attitude,
      },
      {
        subHeading: "Incentive",
        para: "Rewards & alternative solutions for safer decisions",
        icon: images.incentive,
      },
    ],

    description:
      "Help people understand the risks of drinking and driving and how their choices affect their future.",
    icon: images.reflection,
  },
  {
    title: "Goal",
    detail: [
      "Raise awareness about DUI’s impact on individuals & society",
      "Provide real-life insights from legal experts & those affected",
      "Offer alternative solutions that encourage safe choices",
    ],
    icon: images.discovery,
  },
  {
    title: "Target Audience",
    detail: [
      "DUI offenders looking to learn & prevent repeat offenses",
      "Legal professionals handling DUI cases",
      "Counselors & recovery specialists supporting individuals post-DUI",
      "General public interested in making informed choices",
    ],
    icon: images.empowerment,
  },
];

export const discussionTopics = [
  {
    title: "Fear:",
    questions: [
      "What are the biggest dangers of drinking and driving that people often overlook?",
      "Can you share a real-life story where a DUI had devastating consequences?",
      "How does fear play a role in encouraging better decision-making?",
      "Safety",
    ],
  },
  {
    title: "Attitude:",
    questions: [
      "What are the most common myths about drinking and driving?",
      "How can education and awareness reshape public attitudes?",
      "What strategies can help shift someone’s mindset from ‘It won’t happen to me’ to taking DUI risks seriously?",
    ],
  },
  {
    title: "Incentive",
    questions: [
      "What are the biggest motivators for people to avoid drinking and driving?",
      "Have you seen any success stories where incentives helped change behavior?",
      "What role do communities, laws, and incentives play in promoting responsible choices?",
    ],
  },
];

export const sampleQuestions = {
  title: "Exploring DUI Through Expert Insights",
  description:
    "Our interviews dive deep into the real consequences of DUI, exploring its legal, emotional, and social impact. Through expert insights and real-life experiences, we uncover what drives behavior and how individuals can make safer choices. By focusing on fear, attitude, and incentives, these discussions provide a comprehensive understanding of the factors influencing DUI decisions and the strategies for positive change.",
  icon: <WindowIcon fontSize="20px" className="window_icon" />,
  buttonText: "Interview Framework",
};

export const surveyData = [
  {
    title: "Pre-Podcast Survey:",
    description:
      "Gauge initial knowledge, attitudes, and behaviors toward DUI.",
    imageUrl: images.pdOne,
  },
  {
    title: "Post-Podcast Survey:",
    description: "Measure how perspectives shift after listening.",
    imageUrl: images.pdTwo,
  },
  {
    title: "Engagement Metrics:",
    description: "Track listener demographics, feedback, and interaction.",
    imageUrl: images.pdThree,
  },
];

export const podcastStructure = {
  title: "Podcast Series: Navigating the Impact of DUI",
  subHeading:
    "🚗 Fear | Attitude | Incentive – Understanding the choices that shape our future",
  description:
    "This podcast explores the real consequences of DUI, including legal penalties, financial burdens, and rehabilitation strategies. Through expert interviews, real-life stories, and cognitive intervention techniques, we provide practical solutions for preventing repeat offenses and making responsible decisions.",
  icon: <WindowIcon fontSize="20px" className="window_icon" />,
  buttonText: "Podcast Structure",
};

export const podcastStructureData = [
  {
    title: "Immediate Consequences",
    icon: <CheckIcon className="text-blue" />,
    moduleInfo: "Phase I • 10 Points",
    description:
      "Overview of the immediate legal, financial, and procedural consequences of a DUI.",
    subHeading: "What happens immediately after a DUI arrest.",
    sections: [
      {
        title: "What happens immediately after a DUI arrest.",
        icon: <CheckIcon className="text-blue" />,
        items: [
          "Introduction – Overview of podcast purpose and themes.",
          "Police Interaction – What happens during a DUI stop and arrest process.",
          "Tow Truck & Impounded Vehicles – Costs and logistics of getting your car back.",
          "CDOT & DUI Prevention – How the Colorado Department of Transportation works to prevent DUIs.",
          "Jail & Detox – First 24 hours after a DUI: booking, detox, and immediate consequences.",
          "Emergency Room & Morgue Visits – DUI-related injuries, fatalities, and their impact.",
          "Pre-Trial Release & Bail Process – How people are released after a DUI arrest.",
          "Bonds & Financial Penalties – Breaking down the costs of getting out of jail.",
        ],
      },
    ],
  },
  {
    title: "Legal Ramifications",
    moduleInfo: "Phase II • 10 Points",
    subHeading:
      "Focusing on the court process, penalties, and additional charges after a DUI conviction.",
    description:
      "Detailed insights into legal consequences, court proceedings, and financial penalties.",
    sections: [
      {
        title:
          "Focusing on the court process, penalties, and additional charges after a DUI conviction.",
        icon: <CheckIcon className="text-blue" />,
        items: [
          "Motor Vehicle (MV) Court/Judge – DUI hearings, case outcomes, and sentencing.",
          "MV Fines – Cost breakdown, legal fees, and financial burden.",
          "Interlock – Ignition interlock devices, compliance requirements, and monitoring.",
          "SR22/Insurance – How high-risk insurance works and its long-term financial impact.",
          "Retaking the Driver’s Test – Steps to regain driving privileges post-DUI.",
          "DUI Classes & Therapy Requirements – Court-mandated education and therapy requirements for DUI offenders.",
          "Judge’s Role in Sentencing – How judges determine penalties and rehabilitation requirements.",
          ". Criminal Defense Attorney Perspective – What legal defenses exist and what to expect.",
          "Prosecutor's Perspective – How DUI cases are charged and prosecuted.",
          "Immigration Consequences – How a DUI affects non-citizens (deportation risks, visa issues).",
          "Personal Injury & DUI Accidents – Liability if you hurt someone while driving under the influence",
          "DWAI vs. DUI – Understanding the legal differences between these charges",
          "Child Endangerment & Social Services Involvement – What happens if a minor is in the car during a DUI? How does child endangerment impact sentencing? When does Social Services (CPS) get involved, and what are the consequences?",
        ],
      },
    ],
  },
  {
    title: "Behavioral Health & Education",
    moduleInfo: "Phase III • 10 Points",
    description:
      "Educational framework and resources to help individuals understand DUI laws and responsibility.",
    sections: [
      {
        title:
          "Understanding laws, treatment programs, and how education prevents repeat offenses.",
        icon: <CheckIcon className="text-blue" />,
        items: [
          "Government Laws & Policies – Legislative framework around DUI offenses.",
          "Behavioral Health Administration (BHA) Programs – Support and educational resources for substance use recovery.",
          "DUI Classes: Overview of mandatory education.",
          "DUI Education & Treatment Programs – What is required in a DUI class? Role of therapy in addressing addiction-related DUI offenses.",
        ],
      },
    ],
  },
  {
    title: "Sentencing & Compliance",
    moduleInfo: "Phase IV • 10 Points",
    description:
      "A deep dive into the roles of attorneys, judges, and specific DUI laws.",
    sections: [
      {
        title:
          "Exploring penalties, compliance requirements, and monitoring programs.",
        icon: <CheckIcon className="text-blue" />,
        items: [
          "Jail, Home Detention, and Work Release – Alternative sentencing options.",
          "Fines & Restitutions – Financial responsibilities of DUI offenders..",
          "Probation Requirements – Rules offenders must follow to avoid jail.",
          "UA Monitoring – Understanding substance testing and compliance",
          "Community Service – How community service is used as a penalty.",
          "MADD (Mothers Against Drunk Driving) – Advocacy and victim impact panels",
          "Alcohol Ankle Monitors – How technology is used to prevent reoffending.",
        ],
      },
    ],
  },
  {
    title: "Rehabilitation & Financial Planning",
    moduleInfo: "Phase V • 10 Points",
    description: "Understanding sentencing options and post-DUI monitoring.",
    sections: [
      {
        title:
          "Providing tools, financial guidance, and resources for individuals post-DUI.",
        subHeading: "💼 Employment Challenges",
        headingDetail: [
          "Understanding how a DUI impacts job opportunities and what industries allow individuals with a record.",
          "Navigating employer background checks and how to present yourself in interviews.",
          "Steps to regain or transition into stable employment after a DUI.",
        ],
        subHeadingsTwo: "🚗 Alternative Transportation Solutions",
        headingsTwo: [
          "How to get around without driving – Exploring Uber, Lyft, public transit, and community ride programs.",
          "Financial assistance for transportation – Options for discounted services for DUI offenders.",
          "Planning long-term mobility solutions to avoid repeat offenses and regain independence.",
        ],
        financialPlanning: "💰 DUI Financial Planning & Budgeting",
        financialPlanningHeader: "📌 Understanding the Cost of a DUI:",
        financialPlanningDetail: [
          "Court fines, legal fees, and hidden costs.",
          "Insurance increases (SR-22) and long-term financial impact.",
          "Costs for DUI classes, interlock devices, and monitoring.",
        ],
        congnitiveHeader:
          "📌 Cognitive Intervention: Managing Your Funds to Avoid Future Crises",
        cognitiveDetail: [
          "Identifying financial stressors that can lead to poor decision-making.",
          "Budgeting strategies to handle DUI-related expenses while maintaining rent, food, and daily needs.",
          "Building financial stability to prevent future legal issues and avoid reoffending.",
        ],
        reliefHeader: "📌 Financial Relief & Payment Options",
        reliefDetail: [
          "Payment plans for fines and legal fees.",
          "State & nonprofit resources for individuals facing financial hardship.",
          "State & nonprofit resources for individuals facing financial hardship.",
        ],
        icon: <CheckIcon className="text-blue" />,
      },
    ],
  },
  {
    title: "Reintegration & Long-Term Support",
    moduleInfo: "Phase VI • 10 Points",
    description:
      "Guidance on reentering society and overcoming post-DUI challenges.",
    sections: [
      {
        title: "Helping individuals rebuild their lives after a DUI",
        icon: <CheckIcon className="text-blue" />,
        items: [
          "Social Services & Support Networks – Accessing help after a DUI conviction.",
          "Employment Assistance – Resources for job training and placement after a DUI.",
          "Long-Term Transportation Planning – Getting back on the road safely and legally.",
        ],
      },
    ],
  },
];

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const ConfidenceContainer = ({
  videoUrl,
  images,
  contentData,
  heading,
  imageUrl,
  header,
}) => {
  const renderDescription = (description) => {
    if (Array.isArray(description)) {
      return description.map((line, index) => (
        <Box
          key={index}
          className="d-flex align-items-start gap-1"
          justifyContent="start"
          mt={1}
        >
          <FiberManualRecordIcon sx={{ fontSize: "8px", mt: "8px" }} />
          <Typography className="f-18 font-lora" px="20px">
            <span className="fw-500 font-poppins">{line.title}</span> :{" "}
            {Array.isArray(line.description)
              ? renderDescription(line.description)
              : line.description}
          </Typography>
        </Box>
      ));
    } else {
      return (
        <Typography className="f-18 font-lora">
          <FiberManualRecordIcon sx={{ fontSize: "8px", mr: "10px" }} />
          {description}
        </Typography>
      );
    }
  };

  return (
    <Grid container spacing={4} alignItems="stretch">
      <Grid item xs={12} md={6} className="d-flex align-items-stretch">
        {videoUrl && <video src={videoUrl} controls className="video_card" />}
        {imageUrl && (
          <img
            src={imageUrl}
            className="video_card"
            alt={`curriculum detail icon`}
          />
        )}
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {header && (
          <Typography
            className="styled_header fw-600 font-poppins"
            pb={1}
            gutterBottom
          >
            {header}
          </Typography>
        )}

        {heading && (
          <Box position="relative" zIndex={1}>
            <img
              src={images.commaOne}
              alt="comma"
              className="comma_one pos-absolute"
            />
            <Typography
              variant="h4"
              fontWeight="bold"
              gutterBottom
              className="styled_header font-poppins fw-600"
            >
              {heading}
            </Typography>
            <img
              src={images.commaTwo}
              alt="comma"
              className="comma_two pos-absolute"
            />
          </Box>
        )}

        {contentData.map((item, index) => (
          <Box key={index} className="ocean_card">
            <Typography className="f-20 fw-600" my={1}>
              {item.imgSrc}
            </Typography>
            <Typography className="f-18 fw-600" my={1}>
              {item.title}
            </Typography>
            {renderDescription(item.description)}
            {item.subDiscription && (
              <Typography className="f-18 font-lora">
                <FiberManualRecordIcon sx={{ fontSize: "8px", mr: "10px" }} />
                {item.subDiscription}
              </Typography>
            )}
          </Box>
        ))}
      </Grid>
    </Grid>
  );
};

export default ConfidenceContainer;

import { FormControl, FormLabel, MenuItem, Select, FormHelperText } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

const CustomDropdown = ({ label, name, control, rules, options, error, helperText, ...props }) => {
  return (
    <>
      <FormLabel
        sx={{
          marginBottom: "8px",
          display: "block",
        }}
        className="text-black fw-500 font-poppins f-14 mt-1"
      >
        {label}
      </FormLabel>
      <FormControl fullWidth error={error}>
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field }) => (
            <Select
              {...field}
              displayEmpty
              sx={{
                backgroundColor: "rgb(250, 250, 250)",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: error ? "rgb(211, 47, 47)" : "rgb(224, 224, 224)",
                  borderRadius: "8px",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: error ? "rgb(211, 47, 47)" : "rgb(33, 150, 243)",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: error ? "rgb(211, 47, 47)" : "rgb(33, 150, 243)",
                },
                "& .MuiSelect-select": {
                  padding: "10px 14px",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-outlined.MuiSelect-nativeInput": {
                  color: field.value ? "inherit" : "rgb(158, 158, 158)",
                },
              }}
              {...props}
            >
              <MenuItem value="" disabled>
                {`Select ${label}`}
              </MenuItem>
              {options.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        {error && helperText && (
          <FormHelperText error sx={{ marginLeft: "14px", marginTop: "3px" }}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export default CustomDropdown;
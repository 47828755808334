import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from "@mui/material";
import React from "react";
import images from "../../../../../assets/images";

const Programs = () => {
  return (
    <Box
      className="radius-16 bg-cover bg-position-center all_center"
      sx={{
        backgroundImage: `url(${images.program})`,
        p: 5,
      }}
    >
      <Container>
        <Grid
          container
          spacing={4}
          alignItems="center"
          className="text-white font-poppin fw-500"
        >
          <Grid item xs={12} md={6}>
            <Typography className="sub_header fw-600 font-poppins" gutterBottom>
              DUI/DWAI Penalties & Programs Are Designed To:{" "}
            </Typography>

            <List>
              {[
                "Promote Responsible Behavior",
                "Deter Future Offenses",
                "Ensure the Safety of Individuals & the Community",
              ].map((text, index) => (
                <ListItem
                  key={index}
                  className="d-flex"
                  alignItems="flex-start"
                >
                  <ListItemIcon sx={{ mt: 0 }}>
                    <TipsAndUpdatesIcon sx={{ color: "#fff" }} />
                  </ListItemIcon>
                  <Typography className="f-18 fw-500 font-lora">
                    {text}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Grid>
          <Typography className="f-18 fw-500 font-poppins" mt={3}>
            Taking the necessary steps to address the situation responsibly and
            fulfilling legal requirements can help you move forward and make
            positive changes in your life.
          </Typography>
        </Grid>
      </Container>
    </Box>
  );
};

export default Programs;

import { Box, Container } from "@mui/material";
import images from "../../../../assets/images";
import LegalHandbook from "../../../components/LegalHandbook";
import NavigatorAccordion from "../../../components/NavigatorAccordion";
import PageHeader from "../../../components/PageHeader ";
import WhyWorks from "../../../components/WhyWorks";
import Empowering from "./components/Empowering";
import HandbookReview from "./components/HandbookReview";
import WelcomeHandbook from "./components/WelcomeHandbook";

export default function LegalKnowledges({
  legalData,
  legalHandBook,
  whyItMatetrs,
  accordionData,
  pageData,
  reviewhandlebookDAta,
  highlightsAccordianData,
  highlightsData,
}) {
  return (
    <Box mb={2}>
      <WelcomeHandbook
        title="Welcome to the Legal Knowledge Handbook"
        backgroundImage={images?.legalBanner}
        details={legalData}
      />
      <LegalHandbook data={legalHandBook} />
      <Box
        className="all_center bg-warm flex-column maze_container"
        sx={{ my: 5 }}
      >
        <PageHeader {...pageData} />
        <WhyWorks imageSrc={images.matters} data={whyItMatetrs} />
      </Box>

      <Container maxWidth="lg" sx={{ py: 5 }} className="main_container">
        <PageHeader {...highlightsData} />
        <NavigatorAccordion data={highlightsAccordianData} checked="Details" />
      </Container>
      <Box sx={{ pb: 5 }} className="">
        <Empowering />
      </Box>
      <HandbookReview
        accordionData={accordionData}
        reviewhandlebookDAta={reviewhandlebookDAta}
      />
    </Box>
  );
}

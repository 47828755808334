import WindowIcon from "@mui/icons-material/Window";
import { Box, Grid, Typography } from "@mui/material";
import images from "../../../assets/images";

export default function Helm() {
  return (
    <Box>
      <Box className="all_center gap-1">
        <Box className="rounded-btns all_center cursor-pointer" p={2}>
          <WindowIcon fontSize="20px" className="window_icon" />{" "}
        </Box>
        <Typography
          variant="button"
          color="primary"
          className="rounded-btns cursor-pointer text-darksky"
          py={1}
          px={2}
        >
          MEET THE CREW
        </Typography>
      </Box>
      <Box sx={{ py: 6, px: 3 }}>
        <Typography className="styled_header fw-500 text-center">
          Kimberly Sutherland and the crew at &nbsp;
          <Box component="span" className="fw-400 wellness_header">
            Centerboard Wellness
          </Box>{" "}
          are here to navigate alongside you, offering strength-based support on
          your journey to growth and well-being
        </Typography>

        {/* Content Section */}
        <Grid container spacing={4} sx={{ mt: 1, alignItems: "center" }}>
          <Grid item xs={12} md={6}>
            <Box>
              <img
                src={images.mission}
                alt="Team high-five"
                style={{ width: "100%", display: "block" }}
              />
            </Box>
          </Grid>

          {/* About Us & Mission Section */}
          <Grid item xs={12} md={6}>
            {/* About Us */}
            <Typography className="fw-500 font-poppins text-black sub_header">
              About Us
            </Typography>
            <Typography className="fw-500 font-lora color-para f-18" mt={1}>
              With a deep passion for helping individuals overcome life's
              challenges, Kimberly has charted a course for transformative
              journeys that empower people to steer their lives in a healthier,
              more fulfilling direction.
            </Typography>

            {/* Our Mission */}
            <Typography
              className="fw-500 font-poppins text-black sub_header"
              mt={5}
            >
              Our Mission
            </Typography>
            <Typography className="fw-500 font-lora color-para f-18" mt={1}>
              Our mission is to provide the tools, support, and resources that
              empower you to navigate life’s challenges, build resilience, and
              achieve your goals. We focus on a person-centered approach,
              ensuring you take the helm of your own journey with confidence and
              purpose, guiding yourself toward success.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export const pricingPlans = {
  monthly: [
    {
      title: "Self-Awareness Starter",
      subTitle: "Invest in your personal growth",
      price: "$49",
      features: [
        "12-Week Daily Journal",
        "Self-reflection & goal setting",
        "Case management tools",
        "Therapy & DUI support",
        "Community support",
      ],
    },
    {
      title: "Transformation Journey",
      subTitle: "Invest in deeper self-discovery",
      price: "$99",
      recommended: true,
      features: [
        "All Starter Features",
        "Daily Treasure Hunt",
        "Cognitive intervention",
        "Interactive quizzes",
        "Gamification",
        "Personalized progress tracking",
      ],
    },
    {
      title: "Mastery & Resilience",
      subTitle: "Invest in lasting transformation",
      price: "$149",
      features: [
        "All Transform Plan Features",
        "DUI Education",
        "Therapy Modules",
        "Advanced workshops",
        "1:1 coaching sessions",
        "Exclusive events",
        "Priority support",
      ],
    },
  ],
  annual: [
    {
      title: "Self-Awareness Starter",
      price: "$490",
      features: [
        "12-Week Daily Journal",
        "Self-reflection & goal setting",
        "Case management tools",
        "Therapy & DUI support",
        "Community support",
      ],
    },
    {
      title: "Transformation Journey",
      price: "$990",
      recommended: true,
      features: [
        "All Starter Features",
        "Daily Treasure Hunt",
        "Cognitive intervention",
        "Interactive quizzes",
        "Gamification",
        "Personalized progress tracking",
      ],
    },
    {
      title: "Mastery & Resilience",
      price: "$1,490",
      features: [
        "All Transform Plan Features",
        "DUI Education",
        "Therapy Modules",
        "Advanced workshops",
        "1:1 coaching sessions",
        "Exclusive events",
        "Priority support",
      ],
    },
  ],
};

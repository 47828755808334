import AccessTimeIcon from "@mui/icons-material/AccessTime";
import BarChartIcon from "@mui/icons-material/BarChart";
import PersonIcon from "@mui/icons-material/Person";
import QuizIcon from "@mui/icons-material/Quiz";
import SchoolIcon from "@mui/icons-material/School";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import { Box } from "@mui/material";
import images from "../../../../assets/images";
import CurriculumHeader from "../../../components/CurriculumHeader";

const courseDetails = [
  { icon: <AccessTimeIcon />, label: "6 Weeks" },
  { icon: <BarChartIcon />, label: "All Levels" },
  { icon: <ViewModuleIcon />, label: "5 Navigation Charts" },
  { icon: <QuizIcon />, label: "0 Quizzes" },
  { icon: <SchoolIcon />, label: "10 Students" },
  { icon: <PersonIcon />, label: "Kim Sutherland" },
];

const DetailCurriculum = () => {
  return (
    <Box>
      <CurriculumHeader
        title="Navigating the Seas of Self-Discovery"
        backgroundImage={images?.seaBanner}
        details={courseDetails}
      />
    </Box>
  );
};

export default DetailCurriculum;

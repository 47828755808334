import { Box, Typography } from "@mui/material";
import React from "react";

const RequirementCard = ({ requirement, title, badge, badgeIcon }) => {
  return (
    <Box
      sx={{
        maxWidth: 600,
        p: 2,
        width: "100%",
      }}
      className="radius-16 req_hero text-start"
    >
      <Typography
        my={1}
        className="text-black font-poppins f-18 fw-500 d-flex align-items-center"
      >
        {badge ? <img src={badge} alt="badge" /> : badgeIcon}
        {title ? title : "Requirements:"}
      </Typography>
      <Typography className="color-para font-lora f-14 fw-500">
        {requirement}
      </Typography>
    </Box>
  );
};

export default RequirementCard;

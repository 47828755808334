import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LockIcon from "@mui/icons-material/Lock";
import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import images from "../../../../../assets/images";
import "../../../dashboard.css";

const QuizTab = ({ isQuizUnlocked, setIsQuizUnlocked }) => {
  return (
    <Box sx={{ textAlign: "center", mt: 4 }}>
      <Stack spacing={1}>
        <Typography
          onClick={() => setIsQuizUnlocked(!isQuizUnlocked)}
          className="text-darkblue font-poppins sub_header fw-500 gap-1 d-flex align-items-center mb-1"
        >
          <HelpOutlineIcon sx={{ color: "var(--color-darkSky)" }} />
          Quiz
        </Typography>
      </Stack>
      <Box
        className={`position-relative ${
          isQuizUnlocked ? "robotic-unlocked" : "robotic-locked"
        }`}
      >
        <Box
          sx={{
            border: "2px solid #D3E3FF",
            borderRadius: 2,
            p: 2,
            mt: 3,
            maxWidth: 400,
            textAlign: "left",
          }}
          className="position-relative"
        >
          <Stack direction="row" spacing={1} className="align-items-start">
            <InfoOutlinedIcon sx={{ color: "var(--color-darkSky)" }} />
            <Typography className="f-14 font-poppins text-black">
              {isQuizUnlocked
                ? "All hands on deck! The quiz awaits your command!"
                : "No shortcuts, sailor! Complete your voyage before the final test!"}
            </Typography>
          </Stack>

          <Box
            sx={{
              position: "absolute",
              bottom: -10,
              width: 0,
              height: 0,
              borderLeft: "10px solid transparent",
              borderRight: "10px solid transparent",
              borderTop: "10px solid #D3E3FF",
            }}
            className={isQuizUnlocked ? "right-dir" : "left-dir"}
          />
        </Box>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
        <img
          src={isQuizUnlocked ? images.startQuiz : images.quizRobot}
          alt="Quiz Robot"
          width={250}
          height={250}
          style={{ maxWidth: "100%", height: "auto" }}
        />
      </Box>

      <Button
        component={Link}
        to="/dashboard/enrolled-courses/final-quiz"
        variant="contained"
        className="contact_btn f-16"
        endIcon={!isQuizUnlocked && <LockIcon />}
        sx={{ mt: 2 }}
        disabled={!isQuizUnlocked}
        onClick={() => setIsQuizUnlocked(true)}
      >
        Take the Quiz
      </Button>
    </Box>
  );
};

export default QuizTab;

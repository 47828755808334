import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";

const EngagementCard = ({ title, description, buttonText, icon }) => {
  return (
    <Card
      elevation={1}
      sx={{ borderRadius: 3, pt: 2, textAlign: "left" }}
      className="h-100 radius-16 card_border"
    >
      <CardContent>
        <Typography
          className="styled_subheder_header fw-500 font-poppins "
          gutterBottom
        >
          <span className="mx-1 text-darksky">{React.createElement(icon)}</span>
          {title}
        </Typography>
        <Typography
          className="font-lora f-18 fw-500 color-para"
          pb={1}
          paragraph
        >
          {description}
        </Typography>
        <Button
          variant="contained"
          className="contact_btn f-16 m-0 capitalize_text"
          endIcon={<ArrowOutwardIcon />}
        >
          {buttonText}{" "}
        </Button>
      </CardContent>
    </Card>
  );
};

const Community = ({ engagementData }) => {
  console.log(engagementData, "engagementData");

  return (
    <Box sx={{ textAlign: "center", py: 3 }}>
      <Grid container spacing={3} justifyContent="center">
        {engagementData?.map((item, index) => (
          <Grid item xs={12} sm={6} md={6} key={index}>
            <EngagementCard {...item} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Community;

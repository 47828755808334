import NewReleasesIcon from "@mui/icons-material/NewReleases";
import { Box, Button, Container, Typography, Divider } from "@mui/material";
import { useState } from "react";
import GrievanceModal from "../GrievanceModal";

const DisabilityStatement = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  return (
    <Box
      className="all_center flex-column text-center"
      my={3}
      sx={{
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        backdropFilter: "blur(10px)",
        borderRadius: "16px",
        padding: "20px",
        border: "1px solid rgba(255, 255, 255, 0.2)",
      }}
    >
      <Box pb={1}>
        <NewReleasesIcon
          sx={{ color: "var(--color-darkSky)", fontSize: "60px" }}
        />
      </Box>

      {/* Heading */}
      <Typography
        variant="h5"
        mb={1}
        className="styled_subheder_header text-darksky font-poppins fw-600"
      >
        Disability & Grievance Statement{" "}
      </Typography>

      {/* Description */}
      <Container maxWidth="md">
        <Typography
          variant="body1"
          className="sub_header font-lora align-items-center text-white"
        >
          Centerboard Wellness Center is committed to inclusivity and
          accessibility, ensuring that all individuals, regardless of ability,
          have equal access to our programs and services.Our goal is to create a
          supportive environment where participants can grow without barriers.
        </Typography>

        {/* Requesting Accommodations */}
        <Box className="text-start">
          <Typography
            variant="body1"
            className="sub_header font-lora align-items-center text-white"
            mt={2}
            sx={{ lineHeight: "2" }}
          >
            <strong>Requesting Accommodations</strong>
            <br />
            If you need reasonable accommodations to participate, please
            contact:
            <br />
            <span style={{ fontWeight: "bold" }}>Kimberly Sutherland</span>
            <br />
            📞 720-324-0008
            <br />
            ✉️ info@centerboardwellness.com
            <br />
            We encourage early requests to allow enough time for arrangements.
          </Typography>
          <Divider
            sx={{ my: 2, backgroundColor: "var(--color-darkSky)" }}
          ></Divider>

          <Typography className="text-white font-lora sub-header">
            If you believe that your rights under the Americans with
            Disabilities Act (ADA) or any other accessibility protections have
            been violated, you have the right to file a grievance.
          </Typography>
          <Button
            variant="contained"
            className="contact_btn f-16 capitalize_text"
            type="submit"
            onClick={handleOpenModal}
          >
            Filing a Grievance
          </Button>
        </Box>
      </Container>
      <GrievanceModal open={modalOpen} handleClose={handleCloseModal} />
    </Box>
  );
};

export default DisabilityStatement;

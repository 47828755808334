import { Box, Container } from "@mui/material";
import images from "../../assets/images";
import AnchorCustomSection from "../components/AnchorCustomSection";
import InfoCardGrid from "../components/InfoCard";
import PageHeader from "../components/PageHeader ";
import StartingComponent from "../components/StartingComponent";
import Helm from "./components/Helm";
import Inspiration from "./components/Inspiration";
import WellnessFeatures from "./components/WellnessFeatures";
import ScreenDivider from "../components/ScreenDivider";

export default function AboutUs({ skilledData, skilledDetailData, features }) {
  return (
    <Box mb={2}>
      <AnchorCustomSection
        title="The Crew of Centerboard: Your Compass, Your Journey"
        subTitle="Kimberly Sutherland and the crew at Centerboard Wellness navigate alongside you, offering strength-based support on your journey to growth and well-being."
        backgroundImage={images?.aboutusBanner}
      />
      <Container maxWidth="lg" sx={{ py: 5, mt: 3 }} className="main_container">
        <Helm />
      </Container>

      <ScreenDivider />
      <Container maxWidth="lg" sx={{ py: 5 }} className="main_container">
        <Inspiration />
      </Container>
      <ScreenDivider />

      <Container maxWidth="lg" sx={{ py: 5, mt: 3 }} className="main_container">
        <PageHeader {...skilledData} />
        <InfoCardGrid data={skilledDetailData} />{" "}
      </Container>
      <Container maxWidth="lg" sx={{ py: 0 }} className="main_container">
        <WellnessFeatures features={features} />
      </Container>

      <Container maxWidth="lg" sx={{ py: 5, mt: 3 }} className="main_container">
        <StartingComponent
          backgroundImage={images.winds}
          headerText="Fair Winds and Smooth Seas—Welcome Aboard!"
          paragraph="Whether you're seeking personal growth, overcoming challenges, or setting a new course in life, Centerboard Wellness Center is here to guide you. With Kimberly and her team by your side, you'll have the support and tools to navigate your journey and unlock the treasures within."
          buttonText="Set Sail with Us"
          onButtonClick={() => console.log("Button clicked!")}
        />
      </Container>
    </Box>
  );
}

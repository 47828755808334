import { Button, Container, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import images from "../../assets/images";

const PasswordChanged = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Password reset link sent to:", email);
  };

  return (
    <Container
      component="main"
      className="all_center"
      sx={{ minHeight: "100vh" }}
    >
      <Paper
        elevation={3}
        className="border-card-1 radius-12 bg-lightsky auth_card text-center"
        sx={{ p: 4 }}
      >
        <img src={images.confirmed} />

        <Typography className="styled_subheder_header fw-600 text-black">
          Password Changed{" "}
        </Typography>
        <Typography className="fw-500 font-lora f-16 color-para" gutterBottom>
          Your password has been changed successfully!{" "}
        </Typography>
        <Button
          to="/login-account"
          component={Link}
          type="submit"
          fullWidth
          variant="contained"
          className="contact_btn"
        >
          Sign In{" "}
        </Button>
      </Paper>
    </Container>
  );
};

export default PasswordChanged;

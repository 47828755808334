import { Box, Container } from "@mui/material";
import images from "../../../../../assets/images";
import ConfidenceContainer from "../../../../components/ConfidenceContainer";
import CurriculumHeader from "../../../../components/CurriculumHeader";
import InfoCardGrid from "../../../../components/InfoCard";
import PageHeader from "../../../../components/PageHeader ";
import WhyWorks from "../../../../components/WhyWorks";
import "../../../style/treasure.css";
import LifeFocus from "./components/LifeFocus";
import StrengthFocus from "./components/StrengthFocus";
import TreasureReviews from "./components/TreasureReviews";

export default function DailyTreasure({
  trasuresData,
  gemsModule,
  infoCardData,
  leadershipModule,
  leadershipData,
  lifeData,
  reviewsData,
  accordionData,
  whyItWorks,
  strengthData,
  personalData,
}) {
  return (
    <>
      <Box mb={2}>
        <CurriculumHeader
          title="The Captain’s Treasure Hunt"
          backgroundImage={images?.treasureBanner}
          details={trasuresData}
        />
      </Box>
      <Container maxWidth="lg" sx={{ py: 5 }} className="main_container">
        <PageHeader {...gemsModule} />
        <InfoCardGrid data={infoCardData} />{" "}
      </Container>

      <Container maxWidth="lg" sx={{ py: 5 }} className="main_container">
        <PageHeader {...leadershipModule} />

        <Box mt={4} mb={3}>
          <ConfidenceContainer
            imageUrl={images.leadBanner}
            images={images}
            contentData={leadershipData}
          />
        </Box>
      </Container>
      <Container
        maxWidth="lg"
        className="main_container navigation_card"
        sx={{ borderRadius: "16px" }}
      >
        <LifeFocus data={lifeData} />
      </Container>
      <StrengthFocus strengthData={strengthData} personalData={personalData} />

      <Box className="all_center bg-warm" sx={{ my: 5 }}>
        <WhyWorks imageSrc={images.whyworks} data={whyItWorks} />
      </Box>
      <TreasureReviews
        reviewsData={reviewsData}
        accordionData={accordionData}
      />
    </>
  );
}

import React from "react";
import { Box, Typography, Stack, Avatar } from "@mui/material";

const QuizHeader = ({ title, user, details }) => {
  return (
    <Box>
      <Typography
        className="sub_header fw-500 font-poppins text-black"
        gutterBottom
      >
        {title}
      </Typography>
      <Stack
        direction="row"
        spacing={2}
        className="flex-wrap align-items-center"
        sx={{ mb: 3 }}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <Avatar className="object-fit-contain" src={user.avatar} sx={{ width: 24, height: 24 }} />
          <Typography className="color-para">{user.name}</Typography>
        </Stack>

        {details.map((detail, index) => (
          <Stack
            key={index}
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{ color: "var(--color-darkSky)" }}
          >
            {detail.icon}
            <Typography className="color-para">{detail.text} </Typography>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};

export default QuizHeader;

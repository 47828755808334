import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import WindowIcon from "@mui/icons-material/Window";
import {
  Box,
  Button,
  Card,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import images from "../../../assets/images";

const EmbarkJourney = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 4, mt: 4 }} className="main_container">
      <Grid container spacing={4} alignItems="center">
        {/* Text Section */}
        <Grid item xs={12} md={6}>
          <Box className="d-flex align-items-center gap-1">
            <Box className="rounded-btns all_center cursor-pointer" p={2}>
              <WindowIcon fontSize="20px" className="window_icon" />
            </Box>
            <Typography
              variant="button"
              color="primary"
              className="rounded-btns text-darksky cursor-pointer capitalize_text"
              py={1}
              px={2}
            >
              About Us
            </Typography>
          </Box>
          <Typography
            variant="h4"
            className="styled_header font-poppins fw-600"
            pt={2}
          >
            Set sail on a journey of self-discovery and growth.{" "}
          </Typography>
          <Typography variant="body1" sx={{ mt: 2 }} className="f-18 font-lora">
            At Centerboard Wellness Center, we help you navigate your path to
            growth and well-being. Like a trusted compass at sea, we offer
            guidance, tools, and support to help you sail through life’s
            challenges with confidence and resilience.
          </Typography>
          <Button
            variant="contained"
            className="contact_btn"
            endIcon={<ArrowOutwardIcon />}
          >
            Learn More
          </Button>
          {/* Additional Thumbnails */}
          <Box
            gap={1}
            spacing={2}
            sx={{ mt: 4 }}
            className="d-flex justify-content-between"
          >
            {[images.embOne, images.embTwo, images.embthree].map(
              (src, index) => (
                <Box key={index} className="w-100">
                  <img
                    src={src}
                    alt={`Thumbnail ${index + 1}`}
                    className="embark_thumnails"
                  />
                </Box>
              )
            )}
          </Box>
        </Grid>
        {/* Image Section */}
        <Grid item xs={12} md={6}>
          <Card sx={{ borderRadius: "16px", overflow: "hidden" }}>
            <CardMedia
              component="img"
              height="350"
              image={images.embB}
              alt="Journey Image"
              className="fix_img"
            />
          </Card>
          {/* Bottom Section */}
          <Typography variant="h5" className="text-blue fw-600 " pt={3}>
            Ready to embark on your journey?
          </Typography>
          <Typography variant="body1" sx={{ mt: 1 }} className="f-18 font-lora">
            Our programs are designed to support individuals from all walks of
            life, helping you set the course for a healthier, more fulfilling
            future. Whether you're looking to build better habits, enhance
            emotional well-being, or gain new perspectives, our comprehensive
            resources and supportive community are here to guide you.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default EmbarkJourney;

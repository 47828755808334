import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Typography,
} from "@mui/material";
import React from "react";

const JoinConversation = () => {
  return (
    <Container maxWidth="md" sx={{ textAlign: "center", py: 5 }}>
      {/* Heading */}
      <Typography className="styled_header fw-600 font-poppins mb-1">
        Join the Conversation
      </Typography>

      {/* Description */}
      <Typography className="f-18 fw-500 font-lora text-para mb-1">
        Each week, I will host an interview with professionals from different
        fields to hear their honest perspectives. We’re here to explore real
        solutions and make a difference.
      </Typography>

      {/* CTA Button */}
      <Button
        variant="contained"
        className="contact_btn f-16"
        endIcon={<ArrowOutwardIcon />}
        sx={{ mt: 2 }}
      >
        Take the Survey Now{" "}
      </Button>

      {/* Informational Card */}
      <Box mt={2}>
        <Card
          variant="outlined"
          className="d-flex align-items-start bg-warm"
          sx={{
            p: 2,
            borderRadius: 3,
            border: "1px solid #ddd",
            boxShadow: "none",
          }}
        >
          <LightbulbIcon
            sx={{ fontSize: 30, mt: 2, color: "var(--color-darkBlue)" }}
          />
          <CardContent className="text-align-left">
            <Typography className="f-18 fw-600 font-poppins text-darkblue">
              Every Choice Matters.
            </Typography>
            <Typography className="f-18 fw-500 font-poppins text-para" mt={1}>
              Drinking and driving affects lives. Knowledge is power, and
              responsible decisions save lives. Stay informed. Stay responsible.{" "}
              <Typography component="span" fontWeight="bold">
                Join us in making a difference.
              </Typography>
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default JoinConversation;

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PsychologyIcon from "@mui/icons-material/Psychology";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";

const PodcastDiscussion = ({ imageUrl, discussionTopics }) => {
  return (
    <Box sx={{ py: 4, px: { xs: 2, md: 4 } }}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={6}>
          <Box
            component="img"
            src={imageUrl}
            alt="Podcast Discussion"
            sx={{
              width: "100%",
              height: "auto",
              borderRadius: "12px",
              objectFit: "cover",
            }}
          />
        </Grid>

        {/* Right Side - Discussion Topics */}
        <Grid item xs={12} md={6}>
          {discussionTopics.map((topic, index) => (
            <Accordion
              key={index}
              defaultExpanded={index === 0}
              className="mb-0 feel_accordian card_summery"
              sx={{
                boxShadow: "none",
                mb: "10px",
                background: "transparent",
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box className="d-flex align-items-center gap-1">
                  {/* <DirectionsBoatOutlinedIcon
                    fontSize="medium"
                    className="text-incentive"
                  /> */}

                  {topic.title && (
                    <Typography className="fw-600 text-black font-poppins">
                      {topic.title === "Fear" ? (
                        <ReportProblemIcon
                          sx={{
                            fontSize: "25px",
                            mr: "10px",
                            color: "var(--color-darkSky)",
                          }}
                        />
                      ) : topic.title === "Incentive" ? (
                        <MonetizationOnIcon
                          sx={{
                            fontSize: "25px",
                            mr: "10px",
                            color: "var(--color-darkSky)",
                          }}
                        />
                      ) : (
                        <PsychologyIcon
                          sx={{
                            fontSize: "25px",
                            mr: "10px",
                            color: "var(--color-darkSky)",
                          }}
                        />
                      )}
                    </Typography>
                  )}

                  <Typography
                    className={`f-18 text-black text-incentive ${
                      index === 0 ? "text-incentive" : "text-black"
                    } `}
                    fontWeight="bold"
                  >
                    {topic.title}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  {topic.questions.map((question, qIndex) => (
                    <li key={qIndex}>
                      <Typography className="f-18 font-lora color-para">
                        {question}
                      </Typography>
                    </li>
                  ))}
                </ul>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default PodcastDiscussion;

import { Button, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useChangePasswordMutation } from "../../../redux/api/apiSlice";
import CustomInput from "../../components/CustomInput";

const PasswordChangeForm = () => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });
  const [changePassword, { isLoading, isError, error }] =
    useChangePasswordMutation();

  const onSubmit = async (data) => {
    const { currentPassword, newPassword } = data;
    try {
      await changePassword({
        current_password: currentPassword,
        new_password: newPassword,
        confirm_password: newPassword,
      }).unwrap();

      Swal.fire({
        icon: "success",
        title: "Password Updated Successfully!",
        text: "Your password has been changed successfully.",
      });
      reset({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops!",
        text: error?.message || "Something went wrong. Please try again later.",
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomInput
            label="Current Password"
            name="currentPassword"
            type="password"
            control={control}
            rules={{ required: "Current password is required" }}
            error={!!errors.currentPassword}
            helperText={errors.currentPassword?.message}
            placeholder="Enter current password"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomInput
            label="New Password"
            name="newPassword"
            type="password"
            control={control}
            rules={{
              required: "New password is required",
              minLength: {
                value: 6,
                message: "Password must be at least 6 characters long",
              },
            }}
            error={!!errors.newPassword}
            helperText={errors.newPassword?.message}
            placeholder="Enter new password"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomInput
            label="Confirm New Password"
            name="confirmPassword"
            type="password"
            control={control}
            rules={{
              required: "Please confirm your new password",
              validate: (value) =>
                value === control._formValues.newPassword ||
                "Passwords do not match",
            }}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword?.message}
            placeholder="Confirm new password"
          />
        </Grid>
        <Grid item xs={12} className="text-end" mt={1}>
          <Button
            className="contact_btn m-0"
            variant="contained"
            color="primary"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? "Updating..." : "Update Password"}
          </Button>
        </Grid>
      </Grid>

      {isError && (
        <div style={{ color: "red", marginTop: "10px" }}>
          <p>Error: {error?.message || "Something went wrong"}</p>
        </div>
      )}
    </form>
  );
};

export default PasswordChangeForm;

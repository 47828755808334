import AccessTimeIcon from "@mui/icons-material/AccessTime";

import { School } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import StarIcon from "@mui/icons-material/Star";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Stack,
  Typography,
} from "@mui/material";

const ModuleTab = ({ modulesData }) => {
  return (
    <Box sx={{ width: "100%", maxWidth: 900, mx: "auto", p: 2 }}>
      {/* Header */}
      <Stack direction="row" spacing={1} alignItems="center" mb={2}>
        <Typography className="text-darkblue font-poppins sub_header fw-500 gap-1 d-flex align-items-center mb-1">
          <LibraryBooksIcon sx={{ color: "var(--color-darkSky)" }} />
          Modules
        </Typography>
      </Stack>

      {/* Modules List */}
      {modulesData.map((module, index) => (
        <Accordion key={index} sx={{ borderRadius: 2, boxShadow: 1, mb: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Stack sx={{ width: "100%" }}>
              {/* Module Title */}
              <Typography className="text-black font-poppins f-20 fw-500">
                {module.level}
              </Typography>

              {/* Module Info */}
              <Stack direction="row" spacing={2} alignItems="center" mt={0.5}>
                {module.duration && (
                  <Stack direction="row" spacing={0.5} alignItems="center">
                    <AccessTimeIcon
                      fontSize="small"
                      sx={{ color: "var(--color-darkSky)" }}
                    />
                    <Typography className="font-poppins color-para f-14">
                      {module.duration}
                    </Typography>
                  </Stack>
                )}
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <MenuBookIcon
                    fontSize="small"
                    sx={{ color: "var(--color-darkSky)" }}
                  />
                  <Typography className="font-poppins color-para f-14">
                    {module.lessons}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <StarIcon
                    fontSize="small"
                    sx={{ color: "var(--color-darkSky)" }}
                  />
                  <Typography className="font-poppins color-para f-14">
                    {module.xp}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </AccordionSummary>

          {/* Module Details */}
          <AccordionDetails>
            <Divider sx={{ mb: 2 }} />
            <Stack direction="row" spacing={1} alignItems="center" mb={1}>
              <School sx={{ color: "var(--color-darkSky)" }} />{" "}
              <Typography className="text-darkblue font-poppins f-20 fw-500">
                What You’ll Learn:
              </Typography>
            </Stack>
            <Typography variant="body2" whiteSpace="pre-line">
              {module.details}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default ModuleTab;

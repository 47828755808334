import DescriptionIcon from "@mui/icons-material/Description";
import ExploreIcon from "@mui/icons-material/Explore";
import { Box, Grid, Typography } from "@mui/material";
import images from "../../../../assets/images";
import CustomAppBar from "../../../components/CustomAppBar";

const badges = [
  {
    level: "Silver Badge - Level 1",
    unlock:
      "Completing your first activity (meditation, journal, or wellness challenge)",
    xp: 500,
    icon: images.silver,
  },
  {
    level: "Gold Badge - Level 2",
    unlock: "7-day streak in any wellness activity",
    xp: 1000,
    icon: images.gold,
  },
  {
    level: "Dark Gray Badge - Level 3",
    unlock: "Reaching 2000 XP through activities & courses",
    xp: 2000,
    icon: images.darkBadge,
  },
  {
    level: "Purple Badge - Level 4",
    unlock: "Completing All DUI Modules and Quiz",
    xp: 3300,
    icon: images.purple,
  },
  {
    level: "Blue Badge - Level 5",
    unlock: "Maintaining a 14-day streak in self-awareness challenges",
    xp: 4000,
    icon: images.blueBadge,
  },
];
const appBarData = [
  {
    title: "Home It Works",
    icon: <DescriptionIcon />,
    xp: "10 XP",
    notifications: 2,
    profileImage: images.kimAvatar,
  },
];

const HowItWorks = () => {
  return (
    <Box sx={{ py: 3 }}>
      <CustomAppBar {...appBarData[0]} />
      <Box className="d-flex align-items-start position-relative" my={3}>
        <Box
          gap={2}
          className="border-card-1 radius-16 bg-lightsky d-flex align-items-center w-100"
          p={3}
        >
          <Box>
            <Typography className="d-flex align-items-center gap-1 mb-1 sub_header text-black font-poppins fw-500">
              <ExploreIcon /> Wellness Navigator: Badge & Rank System
            </Typography>
            <Typography className="color-para f-16 font-poppins fw-500 font-lora">
              Find your path to self-awareness and growth through milestones and
              achievements!
            </Typography>
          </Box>
        </Box>
        <Box
          component="img"
          src={images.workRobot}
          alt="Astronaut"
          className="work_robot"
        />
      </Box>
      <Typography className="text-black font-poppins fw-500 f-20" mb={2}>
        Badges & Unlocking Criteria
      </Typography>
      <Grid container spacing={2} className="flex-column" mt={2}>
        {badges.map((badge, index) => (
          <Grid item xs={12} sm={12} md={8} key={index}>
            <Box className="d-flex align-items-start gap-1">
              <Box>
                {" "}
                <img
                  className="object-fit-contain"
                  src={badge.icon}
                  alt="badges"
                />
              </Box>
              <Box>
                <Typography
                  className="fw-500 d-flex align-items-center text-black font-poppins fw-500 f-18"
                  mb={1}
                >
                  {badge.level}
                </Typography>
                <Typography
                  className="text-black font-poppins fw-500 f-16"
                  mb={1}
                >
                  Unlocks at:{" "}
                  <span className="color-para font-lora fw-500 f-16">
                    {badge.unlock}
                  </span>
                </Typography>
                <Typography className="text-black font-poppins fw-500 f-16">
                  XP Needed:
                  <span className="color-para font-lora fw-500 f-16">
                    {" "}
                    {badge.xp} XP
                  </span>
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default HowItWorks;

import { ExpandLess, ExpandMore } from "@mui/icons-material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import { Collapse, Menu, MenuItem } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import PropTypes from "prop-types";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import images from "../../assets/images";
import "./navbar.css";

const StyledMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 10,
    border: "1px solid #fff",
    backgroundColor: "var(--color-darkBlue)",
    background:
      "linear-gradient(to bottom, rgb(111 169 192 / 70%), rgb(0 152 203 / 70%))",
    backdropFilter: "blur(10px)",
    color: "#fff",
    boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)",
    minWidth: 200,
  },
  "& .MuiMenuItem-root": {
    fontSize: "16px",
    fontWeight: "500",
    padding: "10px 20px",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.15)",
      transform: "scale(1.05)",
    },
  },
}));

const drawerWidth = 240;
const navItems = [
  "Home",
  "Meet-the-Crew",
  "explorer-guide",
  "Captains Quarters",
  "Treasure Chest",
];

function NavbarApp(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openCrew, setOpenCrew] = React.useState(false);

  const navigate = useNavigate();

  const token = localStorage.getItem("authToken");
  console.log(token, "authToken");

  // ✅ Logout function
  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("user");
    navigate("/login-account");
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleCrewClick = () => {
    setOpenCrew(!openCrew);
  };
  const drawer = (
    <Box className="central_navbar text-white">
      <IconButton
        onClick={handleDrawerToggle}
        sx={{ position: "absolute", right: 10, top: 10 }}
      >
        <CloseIcon sx={{ color: "var(--color-white)" }} />
      </IconButton>
      <Link to="/">
        <img className="img_logo" src={images.logoIcon} alt="logo" />
      </Link>
      <Divider />
      <List>
        {/* {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton
              component={Link}
              to={`/${item.toLowerCase()}`}
              onClick={handleDrawerToggle}
            >
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))} */}

        <ListItem disablePadding>
          <ListItemButton onClick={handleCrewClick}>
            <ListItemText primary="Meet-the-Crew" />
            {openCrew ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Collapse in={openCrew} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              component={Link}
              to="/about"
              onClick={handleDrawerToggle}
              sx={{ pl: 4 }}
            >
              <ListItemText primary="About Us" />
            </ListItemButton>
            <ListItemButton
              component={Link}
              to="/sponsers"
              onClick={handleDrawerToggle}
              sx={{ pl: 4 }}
            >
              <ListItemText primary="Sponsors" />
            </ListItemButton>
          </List>
        </Collapse>

        {/* ✅ Show Login/Logout in the Drawer */}
        <ListItem disablePadding>
          {console.log(token, "token8")}
          {token ? (
            <ListItemButton onClick={handleLogout}>
              <ListItemText primary="Logout" />
            </ListItemButton>
          ) : (
            <ListItemButton
              component={Link}
              to="/login-account"
              onClick={handleDrawerToggle}
            >
              <ListItemText primary="Login" />
            </ListItemButton>
          )}
        </ListItem>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{ backgroundColor: "var(--color-darkBlue)", py: "0px" }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }}>
            <Link to="/">
              <img className="img_logo" src={images.logoIcon} alt="logo" />
            </Link>
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "block" },
              gap: { lg: "30px", md: "10px" },
            }}
            className="d-flex"
          >
            {/* {navItems.map((item) => (
              <Button
                key={item}
                component={Link}
                to={`/${item.toLowerCase()}`}
                className={`navbar-item f-18 ${
                  location.pathname === `/${item.toLowerCase()}` ? "active" : ""
                }`}
                sx={{ color: "#fff" }}
              >
                {item}
              </Button>
            ))} */}

            {navItems.map((item) =>
              item === "Meet-the-Crew" ? (
                <React.Fragment key={item}>
                  <Button
                    aria-controls="meet-the-crew-menu"
                    aria-haspopup="true"
                    onClick={handleMenuOpen}
                    sx={{
                      color: "#fff",
                      gap: "5px",
                    }}
                    className="navbar-item f-16"
                  >
                    Meet the Crew
                    <KeyboardArrowDownOutlinedIcon sx={{ fontSize: "18px" }} />
                  </Button>
                  <StyledMenu
                    id="meet-the-crew-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    MenuListProps={{
                      "aria-labelledby": "meet-the-crew-button",
                    }}
                  >
                    <MenuItem
                      onClick={handleMenuClose}
                      component={Link}
                      to="/about"
                      style={{ borderBottom: "1px solid #fff" }}
                    >
                      About Us
                    </MenuItem>
                    <MenuItem
                      onClick={handleMenuClose}
                      component={Link}
                      to="/sponsers"
                    >
                      Anchor Partners{" "}
                    </MenuItem>
                  </StyledMenu>
                </React.Fragment>
              ) : (
                <Button
                  key={item}
                  component={Link}
                  to={`/${item.toLowerCase().replace(/\s+/g, "-")}`}
                  className={`navbar-item f-16 ${
                    location.pathname ===
                    `/${item.toLowerCase().replace(/\s+/g, "-")}`
                      ? "active"
                      : ""
                  }`}
                  sx={{ color: "#fff" }}
                >
                  {item.replace(/-/g, " ")}
                </Button>
              )
            )}

            {token ? (
              <Button
                onClick={handleLogout}
                sx={{ color: "#fff" }}
                className="f-16"
              >
                Logout
              </Button>
            ) : (
              <Button
                component={Link}
                to="/login-account"
                sx={{ color: "#fff" }}
                className="f-16"
              >
                Login
              </Button>
            )}
          </Box>
          <Box className="d-flex justify-content-end" sx={{ flexGrow: 1 }}>
            <Button
              component={Link}
              to="/create-account"
              variant="contained"
              className="contact_btn f-16"
              endIcon={<ArrowOutwardIcon />}
            >
              Register
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

NavbarApp.propTypes = {
  window: PropTypes.func,
};

export default NavbarApp;

import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PsychologyIcon from "@mui/icons-material/Psychology";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";

const InfoCard = ({ title, description, icon, detail }) => {
  return (
    <Card
      className="info_card all_center flex-column w-100 radius-12"
      sx={{
        p: 3,
      }}
    >
      <Box className="text-start d-flex w-100" justifyContent="start">
        <img src={icon} alt="icons" className="info_icon" />
      </Box>
      <CardContent
        className="text-start d-flex flex-column p-0 w-100"
        justifyContent="start"
      >
        <Typography
          className="fw-600 text-black font-poppins info_header"
          mt={3}
          mx={1}
          mb={1}
        >
          {title}
        </Typography>
        {Array.isArray(detail) ? (
          detail.map((item, index) => (
            <Box key={index} mt={2}>
              {typeof item === "object" ? (
                <Box className="d-flex">
                  {item.subHeading && (
                    <Box className="d-flex align-items-start">
                      {item.subHeading && (
                        <Typography className="fw-600 text-black font-poppins d-flex align-items-center">
                          {item.subHeading === "Fear" ? (
                            <ReportProblemIcon
                              sx={{
                                fontSize: "16px",
                                mr: "10px",
                                mt: "3px",
                                color: "var(--color-darkBlue)",
                              }}
                            />
                          ) : item.subHeading === "Incentive" ? (
                            <MonetizationOnIcon
                              sx={{
                                fontSize: "16px",
                                mr: "10px",
                                mt: "3px",
                                color: "var(--color-darkBlue)",
                              }}
                            />
                          ) : (
                            <PsychologyIcon
                              sx={{
                                fontSize: "16px",
                                mr: "10px",
                                mt: "3px",
                                color: "var(--color-darkBlue)",
                              }}
                            />
                          )}
                        </Typography>
                      )}

                      {/* {item.subHeading && (
                        <Typography className="fw-600 text-black font-poppins">
                          {item.subHeading}
                        </Typography>
                      )} */}
                    </Box>
                  )}

                  <Box className="d-flex">
                    <Typography className="fw-600 text-black font-poppins">
                      {item.subHeading} : &nbsp;
                      <span className="fw-400 color-para font-lora f-18">
                        {item.para}
                      </span>
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <ul className="m-0">
                  <li className="fw-400 color-para font-lora f-18">{item}</li>
                </ul>
              )}
            </Box>
          ))
        ) : (
          <Typography className="fw-400 color-para font-lora f-18" mt={1}>
            {description}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

const InfoCardGridDetail = ({ data }) => {
  return (
    <Grid container spacing={2} sx={{ my: 3 }}>
      {data.map((card, index) => (
        <Grid item xs={12} sm={6} md={4} key={index} className="all_center">
          <InfoCard
            title={card.title}
            description={card.description}
            icon={card.icon}
            detail={card.detail}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default InfoCardGridDetail;

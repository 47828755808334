import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import images from "../../../assets/images";

const BecomeSponser = () => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${images.spnsr})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        alignItems: "center",
        p: 5,
      }}
    >
      <Container>
        <Grid container spacing={4} className="all_center">
          <Grid item xs={12} md={6}>
            <Typography
              className="styled_header fw-600 font-poppins"
              gutterBottom
            >
              Want to Become a Sponsor?
            </Typography>
            <Typography className="f-18 color-para fw-500 font-poppins">
              Interested in joining our mission? Contact us at{" "}
              <span className="text-black fw-600">
                info@centerboardwellness.com
              </span>{" "}
              to learn more about partnership opportunities.
            </Typography>
            <Button
              component={Link}
              to="/contact-us"
              variant="contained"
              className="contact_btn f-16"
              endIcon={<ArrowOutwardIcon />}
              sx={{ mt: 2 }}
            >
              Contact Us
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default BecomeSponser;

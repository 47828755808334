import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import CheckIcon from "@mui/icons-material/Check";
import {
  Box,
  Button,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import images from "../../../../../../assets/images";

const StrengthFocus = ({ personalData, strengthData }) => {
  return (
    <Container sx={{ py: 5 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box className="focus_card">
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography className="styled_subheder_header font-poppins fw-600">
                Personal Focus Elements
              </Typography>
              {personalData?.map((item, index) => (
                <Box key={index} sx={{ mt: 2 }}>
                  <Box className="d-flex w-100 align-items-center gap-1">
                    <img src={images.focus} />
                    <Typography
                      className="f-18 text-black fw-600 font-poppins"
                      my={1}
                    >
                      {item.title}
                    </Typography>
                  </Box>
                  <Typography className="f-18 font-lora fw-500 colo-para">
                    {item.description}
                  </Typography>
                </Box>
              ))}
            </CardContent>
          </Box>
        </Grid>

        {/* Strength-Based Practices */}
        <Grid item xs={12} md={6}>
          <Box className="position-relative">
            <Box className="focus_card">
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography className="styled_subheder_header font-poppins fw-600">
                  Strength-Based Practices
                </Typography>
                {strengthData?.map((item, index) => (
                  <Box
                    key={index}
                    className="d-flex w-100 align-items-start gap-1"
                    my={2}
                  >
                    <CheckIcon className="text-midlight" sx={{ mr: 1 }} />
                    <Typography className="f-18 font-lora fw-500 colo-para">
                      {item}
                    </Typography>
                  </Box>
                ))}

                <Button
                  variant="contained"
                  className="contact_btn mb-0"
                  endIcon={<ArrowOutwardIcon />}
                >
                  Enroll Now
                </Button>
                <img
                  src={images.compass}
                  alt="compass"
                  className="fix_img compass_img object-fit-contain"
                />
              </CardContent>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default StrengthFocus;

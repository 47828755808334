import EmailIcon from "@mui/icons-material/Email";
import {
  Box,
  Button,
  Container,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Password reset link sent to:", email);
  };

  return (
    <Container
      component="main"
      maxWidth="lg"
      className="all_center"
      sx={{ minHeight: "100vh" }}
    >
      <Paper
        elevation={3}
        className="border-card-1 radius-12 bg-lightsky auth_card text-center"
        sx={{ p: 4 }}
      >
        <Typography className="styled_subheder_header fw-600 text-black">
          Forgot Password?
        </Typography>
        <Typography
          className="fw-500 font-lora f-16 color-para"
          gutterBottom
          mb={4}
        >
          Enter the email associated with your account.
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
          <TextField
            fullWidth
            label="Email"
            type="email"
            variant="outlined"
            InputProps={{
              startAdornment: <EmailIcon color="action" sx={{ mr: 1 }} />,
            }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <Button
            to="/otp-verification"
            component={Link}
            type="submit"
            fullWidth
            variant="contained"
            className="contact_btn"
          >
            Send Code
          </Button>
        </Box>
        <Typography className="color-para font-lora fw-500">
          Remember Password?{" "}
          <a href="/login-account" className="text-darksky font-poppins">
            Sign In
          </a>
        </Typography>
      </Paper>
    </Container>
  );
};

export default ForgotPassword;

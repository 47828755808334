import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";

const WeeksPlanTable = ({ headers, data }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <TableContainer component={Paper} sx={{ overflowX: "auto" }}>
      <Table aria-label="responsive table">
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell
                key={header}
                align="left"
                className="bg-light text-blue fw-500 f-14 font-poppins"
                sx={{
                  borderRight:
                    index !== headers.length - 1 ? "1px solid gray" : "none",
                }}
              >
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {Object.values(row).map((cell, cellIndex, arr) => (
                <TableCell
                  key={cellIndex}
                  align="left"
                  className="text-black fw-500 f-14 font-poppins"
                  sx={{
                    borderRight:
                      cellIndex !== arr.length - 1 ? "1px solid gray" : "none",
                  }}
                >
                  {cell}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default WeeksPlanTable;

import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import WindowIcon from "@mui/icons-material/Window";
import { Box, Button, Typography } from "@mui/material";

export default ({
  buttonText,
  title,
  subtitle,
  description,
  icon,
  linkButton,
  subHeading,
}) => {
  return (
    <Box>
      <Box className="all_center gap-1" mb={3}>
        <Box className="rounded-btns all_center cursor-pointer" p={2}>
          {icon ? icon : <WindowIcon fontSize="20px" className="window_icon" />}{" "}
        </Box>
        <Typography
          variant="button"
          color="primary"
          className="rounded-btns cursor-pointer text-darksky capitalize_text"
          py={1}
          px={2}
        >
          {buttonText}
        </Typography>
      </Box>
      <Box className="all_center">
        <Box className="text-center w-75">
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            className="styled_header fw-600 font-poppins"
          >
            {title}
          </Typography>

          {subHeading && (
            <Typography className="sub_header fw-500 font-poppins" mb={2}>
              {subHeading}
            </Typography>
          )}

          {description && (
            <Typography
              variant="body1"
              align="center"
              color="text.secondary"
              className="f-18"
            >
              {description}
            </Typography>
          )}
          {subtitle ? (
            <Typography
              variant="body1"
              align="center"
              color="text.secondary"
              className="f-18"
            >
              {subtitle}
            </Typography>
          ) : null}

          {linkButton && (
            <Button
              variant="contained"
              className="contact_btn f-16"
              endIcon={<ArrowOutwardIcon />}
            >
              {linkButton}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

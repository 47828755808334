import WindowIcon from "@mui/icons-material/Window";
import { Box, Container, Tab, Tabs } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import AccordionCard from "../../../../../components/AccordianCard";
import PageHeader from "../../../../../components/PageHeader ";
import ReviewCard from "../../../../../components/ReviewCard";

export default function TreasureReviews({ reviewsData, accordionData }) {
  const [value, setValue] = useState(0);
  const [visibleCount, setVisibleCount] = useState(3);

  const handleShowMore = () => {
    setVisibleCount((prevCount) => prevCount + 3);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const data = {
    title: "Your Compass to Clarity – FAQs for a Smooth Voyage",
    icon: <WindowIcon fontSize="20px" className="window_icon" />,
    buttonText: "Frequency Asked Questiuons",
  };
  return (
    <Box>
      {/*Compass   */}
      <Container maxWidth="lg" sx={{ py: 5 }} className="main_container">
        <PageHeader {...data} />
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              TabIndicatorProps={{ style: { display: "none" } }}
            >
              <Tab
                sx={{
                  color: "var(--color-para)",
                  "&.Mui-selected": {
                    color: "var(--color-darkSky)",
                    borderBottom: "2px solid var(--color-darkSky)",
                  },
                }}
                label="Reviews"
                className="fw-600"
                {...a11yProps(0)}
              />
              <Tab
                className="fw-600"
                sx={{
                  color: "var(--color-para)",
                  "&.Mui-selected": {
                    color: "var(--color-darkSky)",
                    borderBottom: "2px solid var(--color-darkSky)",
                  },
                }}
                label="Faqs"
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            {reviewsData?.map((review, index) => (
              <ReviewCard
                key={index}
                name={review.name}
                avatar={review.avatar}
                rating={review.rating}
                review={review.review}
                date={review.date}
              />
            ))}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Box className="text-center">
              {accordionData.slice(0, visibleCount).map((item, index) => (
                <AccordionCard
                  key={index}
                  title={item.title}
                  content={item.content}
                />
              ))}
              {visibleCount < accordionData.length && (
                <Box
                  onClick={handleShowMore}
                  sx={{ textDecoration: "underline", pt: 1 }}
                  className="text-darksky"
                >
                  Load More
                </Box>
              )}
            </Box>
          </CustomTabPanel>
        </Box>
      </Container>
    </Box>
  );
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

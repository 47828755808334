import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import WindowIcon from "@mui/icons-material/Window";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const DiscoverCard = ({ link, image, title, description, buttonLabel }) => (
  <Box className="styledCard all_center card_border">
    <img
      className="styledCardMedia"
      alt="card_images"
      src={image}
      title={title}
    />
    <Box className="contentOverlay">
      <Typography variant="h6" className="text-white pb-1">
        {title}
      </Typography>
      <Typography className="f-18 text-white" mb={1}>
        {description}
      </Typography>
    </Box>
    <Box sx={{ justifyContent: "flex-end" }} className="started_btn d-flex">
      <Link to={`/curriculum/${link}`}>
        <Button
          variant="contained"
          className="contact_btn m-0 capitalize_text"
          endIcon={<ArrowOutwardIcon />}
        >
          {buttonLabel}
        </Button>
      </Link>
    </Box>
  </Box>
);

const HolisticAppraoch = ({ holisticCardsData }) => {
  return (
    <Container maxWidth="lg" sx={{ py: 5 }} className="main_container">
      <Grid>
        <Box className="all_center gap-1" mb={3}>
          <Box className="rounded-btns all_center cursor-pointer" p={2}>
            <WindowIcon fontSize="20px" className="window_icon" />
          </Box>
          <Typography
            variant="button"
            color="primary"
            className="rounded-btns cursor-pointer text-darksky capitalize_text"
            py={1}
            px={2}
          >
            Features
          </Typography>
        </Box>
        <Grid container className="all_center">
          <Grid item xs={12} lg={8}>
            <Box className="text-center">
              <Typography
                variant="h4"
                align="center"
                gutterBottom
                className="styled_header fw-600 font-poppins"
              >
                A Holistic Approach to Learning{" "}
              </Typography>
              <Typography
                variant="body1"
                className="f-18 font-lora align-items-center"
              >
                Dive into interactive lessons, quizzes, and tools designed to
                help you master the seas of self-awareness and well-being. Each
                curriculum is your guide to smoother sailing.{" "}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          className="justify-content-center d-flex"
          spacing={4}
          mt={1}
        >
          {holisticCardsData.map((card, index) => (
            <Grid
              item
              lg={4}
              md={4}
              sm={6}
              key={index}
              className="justify-content-center d-flex"
            >
              <DiscoverCard {...card} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};

export default HolisticAppraoch;

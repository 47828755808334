import { Container } from "@mui/material";
import images from "../../../assets/images";
import ConfidenceContainer from "../../components/ConfidenceContainer";

const Ocean = ({ contentData }) => {
  return (
    <Container maxWidth="lg" sx={{ py: 5 }} className="main_container">
      <ConfidenceContainer
        videoUrl="https://assets.mixkit.co/videos/4119/4119-720.mp4"
        images={images}
        contentData={contentData}
        heading="Life is an ocean—let us be your compass to navigate with confidence."
      />
      {/* <Grid container spacing={4} alignItems="stretch">
        <Grid item xs={12} md={6} className="d-flex align-items-stretch">
          <video
            src="https://assets.mixkit.co/videos/4119/4119-720.mp4"
            controls
            className="video_card"
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box position="relative" zIndex={1}>
            <img
              src={images.commaOne}
              alt="comma"
              className="comma_one pos-absolute"
            />
            <Typography
              variant="h4"
              fontWeight="bold"
              gutterBottom
              className="styled_header font-poppins fw-600"
            >
              Life is an ocean—let us help you navigate it with confidence.
            </Typography>
            <img
              src={images.commaTwo}
              alt="comma"
              className="comma_two pos-absolute"
            />
          </Box>
          {contentData.map((item, index) => (
            <Box key={index} className="ocean_card">
              <img
                src={item.imgSrc}
                alt="ocean_icon"
                className="ocean_icon object-fit-contain"
              />
              <Typography className="f-16 fw-600" my={1}>
                {item.title}
              </Typography>
              <Typography className="f-18 font-lora">
                {item.description}
              </Typography>
            </Box>
          ))}
        </Grid>
      </Grid> */}
    </Container>
  );
};

export default Ocean;

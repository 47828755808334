import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import WindowIcon from "@mui/icons-material/Window";
import {
  Box,
  Button,
  Card,
  CardMedia,
  Container,
  Grid,
  Paper,
  Typography,
} from "@mui/material";

const ChartPath = ({
  programs,
  aboutText,
  header,
  subHeader,
  btnText,
  imageUrl,
}) => {
  return (
    <Container maxWidth="lg" sx={{ py: 5 }} className="main_container">
      <Box className="all_center gap-1" mb={3}>
        <Box className="rounded-btns all_center cursor-pointer" p={2}>
          <WindowIcon fontSize="20px" className="window_icon" />
        </Box>
        <Typography
          variant="button"
          color="primary"
          className="rounded-btns cursor-pointer text-darksky capitalize_text"
          py={1}
          px={2}
        >
          {aboutText}
        </Typography>
      </Box>
      <Box className="text-center">
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          className="styled_header fw-600 font-poppins"
        >
          {header && header}
        </Typography>
        <Typography
          variant="body1"
          align="center"
          color="text.secondary"
          className="f-18 color-para"
        >
          {subHeader}
        </Typography>
        {btnText && (
          <Button
            variant="contained"
            className="contact_btn  mb-0"
            endIcon={<ArrowOutwardIcon />}
          >
            {btnText}
          </Button>
        )}
      </Box>

      <Grid container spacing={4} alignItems="center" mt={1}>
        <Grid item xs={12} md={6}>
          <CardMedia
            component="img"
            height="350"
            image={imageUrl}
            alt="Image"
            className="fix_img"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            {programs.map((program, index) => (
              <Paper
                className="main_programms"
                key={index}
                sx={{
                  padding: (theme) => theme.spacing(3),
                  textAlign: "left",
                  color: (theme) => theme.palette.text.primary,
                  display: "flex",
                  alignItems: "flex-start",
                  gap: (theme) => theme.spacing(2),
                  height: "100%",
                  backgroundColor: "transparent",
                  boxShadow: "none",
                  flexBasis: { xs: "100%", sm: "50%", md: "33.33%", lg: "25%" },
                }}
              >
                <Card sx={{ borderRadius: "12px", overflow: "hidden" }}>
                  <img
                    src={program.icon}
                    alt={`Thumbnail ${index + 1}`}
                    className="chart_icon"
                  />
                </Card>
                <Box sx={{ flex: 1 }}>
                  <Typography
                    variant="h6"
                    className="chart_header"
                    sx={{
                      fontWeight: 600,
                      marginBottom: (theme) => theme.spacing(0.5),
                    }}
                  >
                    {program.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    className="f-16 font-lora"
                    sx={{
                      color: (theme) => theme.palette.text.secondary,
                    }}
                  >
                    {program.description}
                  </Typography>
                </Box>
              </Paper>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ChartPath;

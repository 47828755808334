import WindowIcon from "@mui/icons-material/Window";
import {
  Box,
  Container,
  Grid,
  Rating,
  styled,
  Typography,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import { useState } from "react";
import images from "../../../../assets/images";
import AccordionCard from "../../../components/AccordianCard";
import PageHeader from "../../../components/PageHeader ";
import ReviewCard from "../../../components/ReviewCard";
import ImagesDetail from "../../components/ImagesDetail";

export default function Review({ accordionData }) {
  const [value, setValue] = useState(0);
  const [visibleCount, setVisibleCount] = useState(3);

  const handleShowMore = () => {
    setVisibleCount((prevCount) => prevCount + 3);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const StyledRating = styled(Rating)({
    "& .MuiRating-iconFilled": {
      color: "#FFC107",
    },
    "& .MuiRating-iconHover": {
      color: "#FFEB3B",
    },
  });
  const imagesData = [images.oneIcon, images.twoIcon, images.threeIcon];

  const data = {
    title: "Don’t just take our word for it.",
    icon: <WindowIcon fontSize="20px" className="window_icon" />,
    buttonText: "Reviews",
  };

  const reviews = [
    {
      name: "John Smith",
      avatar: images.reviewTwo,
      rating: 4.9,
      review:
        "The Life Focus Elements gave me a whole new perspective on balance and personal growth. I feel more aligned and in control than ever before. Like the course a lot. The progress tracking keeps me motivated every day!",
      date: "Monday, 12/13/2024 2:00 PM",
    },
    {
      name: "Emma Johnson",
      avatar: images.reviewOne,
      rating: 4.7,
      review:
        "This course has transformed the way I see my goals. The lessons are structured well, and I love the interactive elements.",
      date: "Tuesday, 12/14/2024 10:30 AM",
    },
    {
      name: "Michael Brown",
      avatar: images.reviewThree,
      rating: 5.0,
      review:
        "Absolutely fantastic! The best self-improvement program I've taken. Highly recommended!",
      date: "Wednesday, 12/15/2024 5:45 PM",
    },
  ];

  return (
    <Container maxWidth="lg" sx={{ py: 5 }} className="main_container">
      <PageHeader {...data} />

      <Grid item xs={12} md={6}>
        <Box className="all_center" gap={4} mb={1}>
          <Box>
            <ImagesDetail userImages={imagesData} />
          </Box>
          <Box>
            <StyledRating
              value={5}
              readOnly
              precision={0.5}
              emptyIcon={
                <span className="material-symbols-outlined">star_outline</span>
              }
            />
            <Typography variant="body2" gutterBottom>
              4.8 (20 reviews){" "}
            </Typography>
          </Box>
        </Box>
      </Grid>

      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            TabIndicatorProps={{ style: { display: "none" } }}
          >
            <Tab
              sx={{
                color: "var(--color-para)",
                "&.Mui-selected": {
                  color: "var(--color-darkSky)",
                  borderBottom: "2px solid var(--color-darkSky)",
                },
              }}
              label="Reviews"
              className="fw-600"
              {...a11yProps(0)}
            />
            <Tab
              className="fw-600"
              sx={{
                color: "var(--color-para)",
                "&.Mui-selected": {
                  color: "var(--color-darkSky)",
                  borderBottom: "2px solid var(--color-darkSky)",
                },
              }}
              label="Faqs"
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          {reviews.map((review, index) => (
            <ReviewCard
              key={index}
              name={review.name}
              avatar={review.avatar}
              rating={review.rating}
              review={review.review}
              date={review.date}
            />
          ))}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Box className="text-center">
            {accordionData.slice(0, visibleCount).map((item, index) => (
              <AccordionCard
                key={index}
                title={item.title}
                content={item.content}
              />
            ))}
            {visibleCount < accordionData.length && (
              <Box
                onClick={handleShowMore}
                sx={{ textDecoration: "underline", pt: 1 }}
                className="text-darksky"
              >
                Load More
              </Box>
            )}
          </Box>
        </CustomTabPanel>
      </Box>
    </Container>
  );
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

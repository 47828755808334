import {
  AccessTime,
  Layers,
  People,
  SignalCellularAlt,
} from "@mui/icons-material";
import DescriptionIcon from "@mui/icons-material/Description";
import EastIcon from "@mui/icons-material/East";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  LinearProgress,
  Typography,
} from "@mui/material";
import images from "../../../../assets/images";
import QuizHeader from "../components/QuizHeader";

export default () => {
  const courseDetails = [
    { icon: <AccessTime fontSize="small" />, text: "2 Weeks" },
    { icon: <SignalCellularAlt fontSize="small" />, text: "Intermediate" },
    { icon: <Layers fontSize="small" />, text: `3 modules` },
    { icon: <People fontSize="small" />, text: `22 students` },
    { icon: "🏆", text: "4.9" },
  ];
  return (
    <>
      <Box className="d-flex justify-content-between align-items-center flex-wrap">
        <QuizHeader
          title="Final Quiz"
          user={{ name: "Michael Jenkins", avatar: images.kimAvatar }}
          details={courseDetails}
        />
        <Button
          className="border-card-1 border-r-8 text-darksky font-poppins f-16 gap-1"
          sx={{ p: "10px" }}
        >
          Check my new badge <EastIcon />
        </Button>
      </Box>

      <Box className="all_center text-center">
        <Box mt={5} maxWidth="sm">
          <img
            className="object-fit-contain robot_img"
            src={images.startQuiz}
            alt="robot_img"
          />
          <Typography
            className="sub_header text-black fw-600 font-poppins"
            gutterBottom
          >
            Woah! You unlocked a new badge!
          </Typography>
          <Typography className="font-16 color-para font-lora fw-500">
            Mistakes are just part of the journey—keep learning, improve your
            score, and enjoy your new badge!
          </Typography>
        </Box>
      </Box>

      <Box className="all_center">
        <Typography
          my={3}
          px={1}
          className="border-1 req_hero text-start color-para font-poppins f-18 fw-500 d-flex align-items-center"
        >
          <img src={images.rankIcon} alt="badge" /> Claim your badge
        </Typography>
      </Box>
      <Box className="all_center flex-column" gap={1} mt={1}>
        <Accordion
          sx={{ boxShadow: "none", backgroundColor: "transparent" }}
          className="badge_card border-card-1 border-r-8"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary" />}
            sx={{
              minHeight: "50px",
              padding: "0px 10px",
            }}
          >
            <Box display="flex" alignItems="center" gap={1}>
              <EmojiObjectsIcon
                size={20}
                sx={{ color: "var(--color-darkSky)" }}
              />
              <Typography className="text-black fw-500 font-poppins f-16">
                Quiz Summary{" "}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box className="d-flex justify-content-between" gap={1} mb={1}>
              <Typography className="f-12 font-poppins fw-400 color-para">
                Completed
              </Typography>
              <Typography className="text-end f-12 font-poppins fw-400 color-para">
                20/30
              </Typography>
            </Box>

            <LinearProgress
              variant="determinate"
              value={(20 / 30) * 100}
              sx={{
                p: "6px",
                borderRadius: "8px",
                backgroundColor: "lightgray",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#00ACC1",
                },
              }}
            />

            <Typography
              className="text-end f-12 font-poppins fw-400 color-para"
              mt={2}
            >
              20/33
            </Typography>
            <Typography className="f-12 font-poppins fw-400 color-para">
              Correct Answers
            </Typography>

            <Typography className="text-end f-12 font-poppins fw-400 color-para">
              33/100{" "}
            </Typography>
            <Typography className="f-12 font-poppins fw-400 color-para">
              Incorrect Answers
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{ boxShadow: "none", backgroundColor: "transparent" }}
          className="badge_card border-card-1 border-r-8"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary" />}
            sx={{
              minHeight: "50px",
              padding: "0px 10px",
            }}
          >
            <Box display="flex" alignItems="center" gap={1}>
              <DescriptionIcon
                size={20}
                sx={{ color: "var(--color-darkSky)" }}
              />
              <Typography className="text-black fw-500 font-poppins f-16">
                Modules Coverage
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>Modules Coverage</AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};

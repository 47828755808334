import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useLoginUserMutation } from "../../redux/api/apiSlice";

export default function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [loginUser, { isLoading, error }] = useLoginUserMutation();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState("");

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const onSubmit = async (data) => {
    try {
      setLoginError(""); // Reset error before making request
      const loginResult = await loginUser(data).unwrap();

      // ✅ Save token & user info in localStorage
      localStorage.setItem("authToken", loginResult.token);
      localStorage.setItem("user", JSON.stringify(loginResult.user));

      console.log("User logged in:", loginResult);

      // ✅ Redirect to home page after login
      navigate("/");
    } catch (err) {
      console.error("Error logging in:", err);
      setLoginError("Invalid email or password. Please try again.");
    }
  };

  return (
    <Container maxWidth="xs" className="all_center flex-column">
      <Typography
        variant="h4"
        className="text-center"
        gutterBottom
        sx={{
          mt: { xs: "20px", sm: "30px", md: "40px", lg: "70px" },
          mb: { lg: "20px" },
        }}
      >
        Login
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              type="email"
              {...register("email", { required: "Email is required" })}
              error={!!errors.email}
              helperText={errors.email?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Password"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              {...register("password", { required: "Password is required" })}
              error={!!errors.password}
              helperText={errors.password?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          {/* ✅ Display login error message if authentication fails */}
          {loginError && (
            <Grid item xs={12}>
              <Typography color="error">{loginError}</Typography>
            </Grid>
          )}

          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              className="btn-hover color-1 fw-600 text-darksky bg-lightsky w-100 mb-1"
              disabled={isLoading}
            >
              {isLoading ? "Logging In..." : "Log In"}
            </Button>

            <Button
              fullWidth
              className="btn-hover color-1 fw-600 text-darksky bg-lightsky w-100"
            >
              <Link
                to="/signup"
                className="text-decoration-none text-darksky bg-lightsky w-100"
              >
                Sign Up
              </Link>{" "}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}

import CheckIcon from "@mui/icons-material/Check";
import WindowIcon from "@mui/icons-material/Window";
import images from "../images";

export const legalHandbookData = {
  title: "Find guiding lights to navigate life’s storms",
  content: [
    "At Centerboard Wellness Center, we understand that every journey is unique. Whether you are here for personal growth, self-discovery, or addressing legal challenges, we are your steadfast companions as you navigate through the complexities of the judicial system.",
    "Basic Legal Knowledge Handbook",
    "Our 'Basic Legal Knowledge Handbook' is designed as a practical resource to provide clarity, guidance, and empowerment for those facing legal matters. It is more than just a guide to the law—it's a tool for self-awareness, confidence, and effective advocacy during this pivotal time.",
  ],
  imageUrl: images.legalHandbook,
  imageAlt: "Legal Handbook Image",
};

export const whyItMatetrs = {
  title: "Why It Matters",
  description: "The Daily Treasure Hunt to Self-Awareness Game is designed to:",
  benefits: [
    {
      id: "01",
      text: "Understanding the legal system can be daunting",
    },
    {
      id: "02",
      text: "Our goal is to simplify the complexities, ensuring you are informed and prepared.",
    },
    {
      id: "03",
      text: "By blending legal knowledge with personal empowerment, we aim to guide you toward a journey of self-awareness and resilience.",
    },
  ],
};

export const confidenceData = {
  title: "Navigate the Legal Maze with Confidence & Clarity",
  description:
    "This handbook serves as your Navigation Manual, equipping you with essential legal insights while fostering inner clarity and personal growth.",
  icon: <WindowIcon fontSize="20px" className="window_icon" />,
  buttonText: "About the Handbook",
};

export const handbookaccordionData = [
  {
    title: "How do the modules work?",
    content:
      "Our modules focus on key aspects of well-being, such as self-awareness, goal setting, progress tracking, and life balance. Each module includes guided exercises, reflection prompts, and actionable steps to help you grow.",
  },
  {
    title: "Can I track my progress?",
    content:
      "The Life Focus Elements—Earth, Water, Air, Fire, and Space—represent different aspects of personal growth. They help you understand and align your emotions, thoughts, and actions with your overall well-being.",
  },
  {
    title: "Is this suitable for beginners?",
    content:
      "Absolutely! Our modules are designed for people of all levels, including beginners who want to improve their self-awareness and goal-setting skills.",
  },
  {
    title: "What are the Life Focus Elements?",
    content:
      "Absolutely! Our modules are designed for people of all levels, including beginners who want to improve their self-awareness and goal-setting skills.",
  },
  {
    title: "Can I track my progress?",
    content:
      "Absolutely! Our modules are designed for people of all levels, including beginners who want to improve their self-awareness and goal-setting skills.",
  },
  {
    title: "Who can benefit from Centerboard Wellness?",
    content:
      "Absolutely! Our modules are designed for people of all levels, including beginners who want to improve their self-awareness and goal-setting skills.",
  },
  {
    title: "What is Centerboard Wellness?",
    content:
      "Absolutely! Our modules are designed for people of all levels, including beginners who want to improve their self-awareness and goal-setting skills.",
  },
];

export const reviewhandlebookDAta = [
  {
    name: " Marcus D.",
    avatar: images.reviewTwo,
    rating: 4.9,
    review:
      "This course helped me understand the real consequences of my choices. I’ve changed how I think about alcohol and driving completely.",
    date: "Tuesday, 1/23/2024, 10:00 AM",
    level: "Completed Level II Therapy - Track B",
  },
  {
    name: " Nicole R",
    avatar: images.reviewOne,
    rating: 4.7,
    review:
      "This course has transformed the way I see my goals. The lessons are structured well, and I love the interactive elements.",
    date: "Friday, 2/16/2024, 1:00 PM",
    level: "Completed Level I Education",
  },
  {
    name: "Michael Brown",
    avatar: images.reviewThree,
    rating: 5.0,
    review:
      "Absolutely fantastic! The best self-improvement program I've taken. Highly recommended!",
    date: "Wednesday, 12/15/2024 5:45 PM",
  },
];

export const highlightsData = {
  title: "Highlights of the Handbook",
  description:
    "Through a holistic approach, we aim to demystify the legal process and support you in making informed decisions.",
  icon: <WindowIcon fontSize="20px" className="window_icon" />,
  buttonText: "Highlights",
};

export const highlightsAccordianData = [
  {
    title: "Constitutional Amendment Rights",
    moduleInfo: "Week 1 • 10 Points",
    description:
      "Understand the fundamental rights that protect you within the legal system. Learn how to exercise these rights effectively.",
    sections: [
      {
        title: (
          <div className="d-flex align-items-center text-blue">
            <img
              src={images.degree}
              className="accord_icon"
              alt="Degree Icon"
              style={{ width: "24px", height: "24px", marginRight: "8px" }}
            />
            What You’ll Learn:
          </div>
        ),
        icon: <CheckIcon className="text-midlight" />,
        items: [
          "Understand the fundamental rights that protect you within the legal system.",
          "Learn how to exercise these rights effectively.",
        ],
      },
    ],
  },
  {
    title: "Roles of Courtroom Participants",
    moduleInfo: "Week 1 • 10 Points",
    description:
      "Get to know the key players in legal proceedings, including judges, attorneys, and witnesses. Gain clarity on how each role impacts your case.",
    sections: [
      {
        title: (
          <div className="d-flex align-items-center text-blue">
            <img
              src={images.degree}
              className="accord_icon"
              alt="Degree Icon"
              style={{ width: "24px", height: "24px", marginRight: "8px" }}
            />
            What You’ll Learn:
          </div>
        ),
        icon: <CheckIcon className="text-midlight" />,
        items: [
          "Get to know the key players in legal proceedings, including judges, attorneys, and witnesses.",
          "Gain clarity on how each role impacts your case.",
        ],
      },
    ],
  },
  {
    title: "Understanding the Defense Attorney and Client Relationship",
    moduleInfo: "Week 1 • 10 Points",
    description:
      "Discover the importance of collaboration with your defense attorney. Learn how to actively participate in your defense strategy.",
    sections: [
      {
        title: (
          <div className="d-flex align-items-center text-blue">
            <img
              src={images.degree}
              className="accord_icon"
              alt="Degree Icon"
              style={{ width: "24px", height: "24px", marginRight: "8px" }}
            />
            What You’ll Learn:
          </div>
        ),
        icon: <CheckIcon className="text-midlight" />,
        items: [
          "Discover the importance of collaboration with your defense attorney.",
          "Learn how to actively participate in your defense strategy.",
        ],
      },
    ],
  },
  {
    title: "Understanding and Appreciating Charges",
    moduleInfo: "Week 1 • 10 Points",
    description:
      "Decode the charges against you and understand their implications. Explore the potential consequences and their impact on your future.",
    sections: [
      {
        title: (
          <div className="d-flex align-items-center text-blue">
            <img
              src={images.degree}
              className="accord_icon"
              alt="Degree Icon"
              style={{ width: "24px", height: "24px", marginRight: "8px" }}
            />
            What You’ll Learn:
          </div>
        ),
        icon: <CheckIcon className="text-midlight" />,
        items: [
          "Decode the charges against you and understand their implications.",
          "Explore the potential consequences and their impact on your future.",
        ],
      },
    ],
  },
  {
    title: "Statement of Alleged Facts",
    moduleInfo: "Week 1 • 10 Points",
    description:
      "Learn how the facts of your case are presented and scrutinized in court. Understand their significance in shaping your defense.",
    sections: [
      {
        title: (
          <div className="d-flex align-items-center text-blue">
            <img
              src={images.degree}
              className="accord_icon"
              alt="Degree Icon"
              style={{ width: "24px", height: "24px", marginRight: "8px" }}
            />
            What You’ll Learn:
          </div>
        ),
        icon: <CheckIcon className="text-midlight" />,
        items: [
          "Learn how the facts of your case are presented and scrutinized in court.",
          "Understand their significance in shaping your defense.",
        ],
      },
    ],
  },
  {
    title: "Plea Options and Outcomes",
    moduleInfo: "Week 1 • 10 Points",
    description:
      "Explore the range of pleas, including guilty, not guilty, and no contest. Understand the consequences tied to each option.",
    sections: [
      {
        title: (
          <div className="d-flex align-items-center text-blue">
            <img
              src={images.degree}
              className="accord_icon"
              alt="Degree Icon"
              style={{ width: "24px", height: "24px", marginRight: "8px" }}
            />
            What You’ll Learn:
          </div>
        ),
        icon: <CheckIcon className="text-midlight" />,
        items: [
          "Explore the range of pleas, including guilty, not guilty, and no contest.",
          "Understand the consequences tied to each option.",
        ],
      },
    ],
  },
  {
    title: "Plea Bargains",
    moduleInfo: "Week 1 • 10 Points",
    description:
      "Gain insight into the negotiation process and how plea bargains work. Learn how they can influence the outcome of your case.",
    sections: [
      {
        title: (
          <div className="d-flex align-items-center text-blue">
            <img
              src={images.degree}
              className="accord_icon"
              alt="Degree Icon"
              style={{ width: "24px", height: "24px", marginRight: "8px" }}
            />
            What You’ll Learn:
          </div>
        ),
        icon: <CheckIcon className="text-midlight" />,
        items: [
          "Gain insight into the negotiation process and how plea bargains work.",
          "Learn how they can influence the outcome of your case.",
        ],
      },
    ],
  },
  {
    title: "Defense Strategy",
    moduleInfo: "Week 1 • 10 Points",
    description:
      "Discover how defense attorneys build a case to protect your rights. Become an active participant in shaping your defense.",
    sections: [
      {
        title: (
          <div className="d-flex align-items-center text-blue">
            <img
              src={images.degree}
              className="accord_icon"
              alt="Degree Icon"
              style={{ width: "24px", height: "24px", marginRight: "8px" }}
            />
            What You’ll Learn:
          </div>
        ),
        icon: <CheckIcon className="text-midlight" />,
        items: [
          "Discover how defense attorneys build a case to protect your rights.",
          "Become an active participant in shaping your defense.",
        ],
      },
    ],
  },
  {
    title: "Range of Sentences",
    moduleInfo: "Week 1 • 10 Points",
    description:
      "Understand the spectrum of potential outcomes, from leniency to severe penalties. Learn how these may apply to your case.",
    sections: [
      {
        title: (
          <div className="d-flex align-items-center text-blue">
            <img
              src={images.degree}
              className="accord_icon"
              alt="Degree Icon"
              style={{ width: "24px", height: "24px", marginRight: "8px" }}
            />
            What You’ll Learn:
          </div>
        ),
        icon: <CheckIcon className="text-midlight" />,
        items: [
          "Understand the spectrum of potential outcomes, from leniency to severe penalties.",
          "Learn how these may apply to your case.",
        ],
      },
    ],
  },
  {
    title: "Trial Process and Testifying",
    moduleInfo: "Week 1 • 10 Points",
    description:
      "Familiarize yourself with each stage of a trial, from jury selection to the verdict. Learn best practices for testifying in court.",
    sections: [
      {
        title: (
          <div className="d-flex align-items-center text-blue">
            <img
              src={images.degree}
              className="accord_icon"
              alt="Degree Icon"
              style={{ width: "24px", height: "24px", marginRight: "8px" }}
            />
            What You’ll Learn:
          </div>
        ),
        icon: <CheckIcon className="text-midlight" />,
        items: [
          "Familiarize yourself with each stage of a trial, from jury selection to the verdict.",
          "Learn best practices for testifying in court.",
        ],
      },
    ],
  },
  {
    title: "Courtroom Behavior and Decorum",
    moduleInfo: "Week 1 • 10 Points",
    description:
      "Understand the importance of respectful behavior in court. Learn how to present yourself effectively.",
    sections: [
      {
        title: (
          <div className="d-flex align-items-center text-blue">
            <img
              src={images.degree}
              className="accord_icon"
              alt="Degree Icon"
              style={{ width: "24px", height: "24px", marginRight: "8px" }}
            />
            What You’ll Learn:
          </div>
        ),
        icon: <CheckIcon className="text-midlight" />,
        items: [
          "Understand the importance of respectful behavior in court.",
          "Learn how to present yourself effectively.",
        ],
      },
    ],
  },
];

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import EventIcon from "@mui/icons-material/Event";
import { Box, Card, IconButton, Typography } from "@mui/material";
import { addDays, eachDayOfInterval, format, subDays } from "date-fns";
import React, { useState } from "react";

const ProfileCalendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());

  // Get the current 7-day period (starting from today)
  const startDate = currentDate;
  const endDate = addDays(currentDate, 6);
  const weekDays = eachDayOfInterval({ start: startDate, end: endDate });

  const handlePrevWeek = () => {
    setCurrentDate(subDays(currentDate, 7));
  };

  const handleNextWeek = () => {
    setCurrentDate(addDays(currentDate, 7));
  };

  return (
    <Card
      sx={{
        mt: 3,
        py: 3,
        borderRadius: 3,
        boxShadow: 3,
        backgroundColor: "#00ACC1",
        color: "white",
        textAlign: "center",
      }}
    >
      {/* Calendar Header */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <IconButton onClick={handlePrevWeek} sx={{ color: "white" }}>
          <ChevronLeftIcon />
        </IconButton>
        <Typography
          variant="h6"
          fontWeight="bold"
          display="flex"
          alignItems="center"
          gap={1}
        >
          <EventIcon /> {format(currentDate, "MMMM yyyy")}
        </Typography>
        <IconButton onClick={handleNextWeek} sx={{ color: "white" }}>
          <ChevronRightIcon />
        </IconButton>
      </Box>

      {/* Display Week Days and Dates */}
      <Box display="flex" justifyContent="space-between" mt={2} gap={1} px={3}>
        {weekDays.map((day, index) => {
          const isSelected =
            format(day, "yyyy-MM-dd") === format(selectedDate, "yyyy-MM-dd");

          return (
            <Box
              key={index}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                sx={{
                  background: isSelected ? "#fff" : "",
                  color: isSelected ? "#00ACC1" : "",
                  borderRadius: "60px",
                  py: "10px",
                }}
                className="text-center"
              >
                <Typography sx={{ fontSize: 14, opacity: 0.8 }}>
                  {format(day, "EEE")}
                </Typography>
                <IconButton
                  onClick={() => setSelectedDate(day)}
                  sx={{
                    color: isSelected ? "#00ACC1" : "white",
                    backgroundColor: isSelected ? "white" : "transparent",
                    borderRadius: "50%",
                    width: 36,
                    height: 36,
                    marginTop: 1,
                    "&:hover": {
                      backgroundColor: isSelected ? "white" : "#00ACC1",
                      color: isSelected ? "#00ACC1" : "white",
                    },
                  }}
                >
                  {format(day, "d")}
                </IconButton>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Card>
  );
};

export default ProfileCalendar;

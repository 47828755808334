import { Box, Grid, Paper, Typography } from "@mui/material";
import images from "../../../../../../assets/images";

const LifeFocus = ({ data }) => {
  return (
    <Box className="life_card text-center position-relative">
      <img src={images.lifeSix} alt="Compass" className="object-fit-contain compass_icon" />
      <Typography className="styled_header font-poppins fw-600">
        Life Focus Elements
      </Typography>
      <Typography variant="body1" className="f-18 font-lora">
        Use the signs in nature as your compass for daily awareness and growth.
      </Typography>

      <Grid container spacing={3} justifyContent="center" mt={2}>
        {data?.map((element, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Paper
              sx={{
                padding: 3,
                textAlign: "start",
                borderRadius: "12px",
                boxShadow: 2,
                height: "150px",
              }}
            >
              <img
                src={element.icon}
                alt={element.title}
                className="object-fit-contain life_icon"
              />
              <Typography variant="h6" fontWeight="bold">
                {element.title}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {element.description}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>

      <img src={images.lifeSeven} alt="Helm" className="object-fit-contain helm_icon" />
    </Box>
  );
};

export default LifeFocus;

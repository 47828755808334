import BoltIcon from "@mui/icons-material/Bolt";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import { Box, Button, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import images from "../../../../assets/images";

const modalContent = [
  {
    title: "Welcome to the Wellness Centerboard!",
    message:
      "Ahoy, explorer! I'm Kai, your friendly deep-sea buddy! Ready to uncover secrets, earn badges, and ride the tide to greatness? Grab your snorkel, and let’s GO!",
    icon: <EmojiPeopleIcon fontSize="large" sx={{ color: "#00ACC1" }} />,
  },
  {
    title: "Track Your Progress!",
    message:
      "Stay on top of your goals with real-time insights. Unlock new levels and challenge yourself to grow every day!",
  },
  {
    title: "Join the Community!",
    message:
      "Connect with like-minded explorers, share experiences, and support each other on the journey to wellness!",
  },
];

const PricingPlans = () => {
  const [modalIndex, setModalIndex] = useState(0);
  const [autoSwitch, setAutoSwitch] = useState(true);

  useEffect(() => {
    if (autoSwitch) {
      const interval = setInterval(() => {
        setModalIndex((prevIndex) => (prevIndex + 1) % modalContent.length);
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [autoSwitch]);

  const handleNext = () => {
    setModalIndex((prevIndex) => (prevIndex + 1) % modalContent.length);
    setAutoSwitch(false);
  };

  const handlePrevious = () => {
    setModalIndex(
      (prevIndex) => (prevIndex - 1 + modalContent.length) % modalContent.length
    );
    setAutoSwitch(false);
  };

  return (
    <Container maxWidth="lg" sx={{ textAlign: "center", py: 4 }}>
      <Box className="d-flex align-items-end gap-1">
        <Box
          sx={{
            width: "100%",
            maxWidth: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
          }}
          className="border-card-1 radius-16 bg-lightsky"
        >
          <Box className="align-items-start d-flex gap-1">
            <BoltIcon className="text-darksky" />
            <Box className="d-flex flex-column">
              <Typography className="f-16 fw-500 font-poppins text-black text-start">
                {modalContent[modalIndex].title}
              </Typography>
              <Typography
                mt={1}
                className="f-14 fw-400 font-lora color-para text-start"
              >
                {modalContent[modalIndex].message}
              </Typography>
            </Box>
          </Box>

          <Box className="d-flex justify-content-between">
            <Box className="d-flex gap-1" mt={1}>
              <Button
                sx={{ textDecoration: "underline" }}
                onClick={handlePrevious}
                className="f-14 fw-500 font-poppins text-darksky"
              >
                Previous
              </Button>
              <Button
                variant="contained"
                onClick={handleNext}
                sx={{ textDecoration: "underline" }}
                className="f-14 fw-500 font-poppins bg-darksky"
              >
                Next
              </Button>
            </Box>
            <img
              className="object-fit-contain cursor-pointer"
              src={images.fish}
              alt="fish"
            />
          </Box>
        </Box>
        <Box>
          <img
            className="object-fit-contain"
            src={images.smallRobot}
            alt="robot_img"
          />
        </Box>
      </Box>
    </Container>
  );
};

export default PricingPlans;

import BoltIcon from "@mui/icons-material/Bolt";
import StarsIcon from "@mui/icons-material/Stars";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Avatar, Box, Card, LinearProgress, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import images from "../../../../assets/images";
import ProfileCalendar from "./ProfileCalender";

const CircularAvatarWrapper = styled(Box)({
  position: "relative",
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  width: 100,
  height: 100,
});

const CircularProgressBg = styled(Box)({
  position: "absolute",
  width: "100%",
  height: "100%",
  borderRadius: "50%",
  border: "5px solid #E0E0E0",
});

const CircularProgress = styled(Box)(({ progress }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  borderRadius: "50%",
  border: "5px solid #00ACC1",
  clipPath: "inset(0 0 50% 0)",
  transform: `rotate(${progress * 3.6}deg)`,
  transformOrigin: "center",
}));

export default function ProfileCard() {
  const xp = 10;
  const maxXP = 500;
  const progress = (xp / maxXP) * 100;

  return (
    <Box textAlign="center">
      {/* Profile Card */}
      <Card
        sx={{
          p: 3,
          textAlign: "center",
          borderRadius: 3,
          boxShadow: 3,
          //   maxWidth: 300,
          mx: "auto",
        }}
      >
        {/* Circular Avatar with Progress */}
        <CircularAvatarWrapper>
          <CircularProgressBg />
          <CircularProgress progress={progress} />
          <Avatar
            src={images.kimAvatar}
            sx={{
              width: 88,
              height: 88,
              mx: "auto",
              border: "4px solid white",
              position: "relative",
              //   zIndex: 1,
            }}
          />
          {/* Rank Badge Fix */}
          <img
            src={images.rankIcon}
            alt="rank_icon"
            style={{
              position: "absolute",
              bottom: 10,
              right: -7,
              width: 35,
              height: 35,
              backgroundColor: "white",
              borderRadius: "50%",
              padding: 2,
              boxShadow: "0px 2px 5px rgba(0,0,0,0.2)",
            }}
          />
        </CircularAvatarWrapper>

        {/* Profile Name */}
        <Typography
          className="text-darkblue f-14 fw-500 font-poppins all_center gap-1"
          sx={{ mt: 1 }}
        >
          Sarah Luke <VerifiedIcon color="success" fontSize="small" />
        </Typography>
        <Box mt={2}>
          <StarsIcon fontSize="large" sx={{ color: "grey" }} />
        </Box>
        {/* Streak Icon */}
        <Box className="all_center" gap={1} mt={1}>
          <BoltIcon className="text-darksky" />
          <Typography className="color-para f-14 fw-500 font-poppins">
            1 Day Streak
          </Typography>
        </Box>

        {/* XP Progress Bar */}
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{
            p: "4px",
            borderRadius: "4px",
            backgroundColor: "#E0E0E0",
            my: 2,
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#00ACC1",
            },
          }}
        />
        <Typography variant="caption">
          {xp}/{maxXP} XP to Level 1
        </Typography>
        {/* Calendar Section */}

        <Box> 
          <ProfileCalendar />
        </Box>
      </Card>
    </Box>
  );
}

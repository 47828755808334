import { Checkbox, FormControlLabel, FormLabel } from "@mui/material";
import React from "react";

const CustomCheckbox = ({ label, checked, onChange }) => {
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            checked={checked}
            onChange={(e) => onChange(e.target.checked)}
            sx={{
              "&.Mui-checked": {
                color: "var(--color-darkSky)",
              },
            }}
          />
        }
      />
      <FormLabel>{label}</FormLabel>
    </>
  );
};

export default CustomCheckbox;

import { Box, CardContent, Container, Grid, Typography } from "@mui/material";

const WhyWorks = ({ data, imageSrc }) => {
  return (
    <Container maxWidth="lg" sx={{ py: 5 }}>
      <Grid container spacing={4} alignItems="stretch" px={0}>
        <Grid item xs={12} md={5} px={0}>
          <Box
            sx={{
              borderRadius: "10px",
              overflow: "hidden",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              className="object-fit-cover fix_img"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                display: "block",
              }}
              src={imageSrc}
              alt="Mindfulness"
            />
          </Box>
        </Grid>

        {/* Text Section */}
        <Grid item xs={12} md={7}>
          <Typography
            className="styled_header fw-600 font-poppins"
            gutterBottom
          >
            {data.title}
          </Typography>
          <Typography className="f-18 font-lora fw-500 color-para">
            {data.description}
          </Typography>

          {/* Benefits List */}
          {data?.benefits.map((item) => (
            <Box item key={item.id}>
              <Box className="text-start">
                <CardContent className="card_content_works p-0" sx={{ mb: 2 }}>
                  <Typography className="words_id fw-600 font-poppins">
                    {item.id}
                  </Typography>
                  <Typography className="f-18 font-lora fw-500 color-para">
                    {item.text}
                  </Typography>
                </CardContent>
              </Box>
            </Box>
          ))}

          <Typography className="f-18 font-lora fw-500 color-para" sx={{ mt: 3 }}>
            {data.conclusion}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default WhyWorks;

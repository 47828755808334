import {
  LocalFireDepartment,
  Public,
  RocketLaunch,
  WaterDrop,
} from "@mui/icons-material";
import AirIcon from "@mui/icons-material/Air";
import GroupIcon from "@mui/icons-material/Group";
import WifiIcon from "@mui/icons-material/Wifi";
import WindowIcon from "@mui/icons-material/Window";
import images from "../images";

export const streetData = {
  title: "Steer your Ship towards Growth",
  description:
    "Our store combines the principles of personal growth, spiritual exploration, and wellness, offering a unique selection of treasures to motivate, inspire, and support your progress.",
  icon: <WindowIcon fontSize="20px" className="window_icon" />,
  buttonText: "How It Works",
};

export const streetDetailData = [
  {
    title: "Earn Points",
    description:
      "Accumulate points by completing lessons, achieving milestones, and participating in activities.",
    icon: images.reflection,
  },
  {
    title: "Redeem Rewards",
    description:
      "Use your points to unlock curated items that enhance your journey of self-discovery and personal growth.",
    icon: images.discovery,
  },
  {
    title: "Thrive",
    description:
      "Let each reward inspire you to set your sights further on the horizon and continue navigating toward your goals.",
    icon: images.empowerment,
  },
];

export const categoriesData = {
  title: "Product Categories",
  description:
    "Each item is carefully curated to align with the natural elements, reinforcing your connection to the world around you:",
};

export const categoriesDetailData = [
  {
    title: "Earth",
    description:
      "Grounding tools such as meditation stones, plant kits, and ritual décor.",
    icon: <Public fontSize="large" className="text-darksky" />,
  },
  {
    title: "Air",
    description:
      "Items that inspire clarity and thought, including journals, incense, and wind chimes.",
    icon: <AirIcon fontSize="large" className="text-darksky" />,
  },
  {
    title: "Water",
    description:
      "Products for relaxation and reflection, like calming teas, bath soaks, and water-inspired art.",
    icon: <WaterDrop fontSize="large" className="text-darksky" />,
  },
  {
    title: "Fire",
    description:
      "Motivational tools that ignite passion, including candles, goal-setting planners, and energy-boosting kits.",
    icon: <LocalFireDepartment fontSize="large" className="text-darksky" />,
  },
  {
    title: "Space",
    description:
      "Resources to encourage boundless exploration, such as star charts, cosmic guides, and mindfulness exercises.",
    icon: <RocketLaunch fontSize="large" className="text-darksky" />,
  },
];

export const engagementData = [
  {
    icon: GroupIcon,
    title: "Workshops and Gatherings",
    description:
      "Join us for events focused on meditation, energy healing, and metaphysical exploration. Deepen your understanding of the elements while connecting with a vibrant community.",
    buttonText: "Set Sail with the Community",
  },
  {
    icon: WifiIcon,
    title: "Online Resources",
    description:
      "Browse our store and access exclusive resources online. Participate in our provided forums, join webinars with us, and expand your wellness practice with ease.",
    buttonText: "Chart Your Course with Us",
  },
];

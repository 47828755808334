import { Box, Container } from "@mui/material";
import Divider from "@mui/material/Divider";
import { cardsData, contentData, programs } from "../../assets/data/home";
import images from "../../assets/images";
import ChartPath from "./components/ChartPath";
import Discover from "./components/Discover";
import EmbarkJourney from "./components/EmbarkJourney";
import Founder from "./components/Founder";
import Journey from "./components/Journey";
import Ocean from "./components/Ocean";
import SailComponent from "./components/Sail";
import "./home.css";
import ScreenDivider from "../components/ScreenDivider";

export default function Home() {
  return (
    <Box>
      <Journey />
      <EmbarkJourney />

      <ScreenDivider />
      <ChartPath
        aboutText="What We Offer"
        header="Chart Your Path"
        subHeader="Navigate your journey with programs and tools designed to empower you."
        btnText="Explore Our Programs"
        programs={programs}
        imageUrl={images.chartBanner}
      />
      <ScreenDivider />

      <Discover cardsData={cardsData} />
      <Box className="ocean_main">
        <Ocean contentData={contentData} />
      </Box>
      <Founder />
      <Divider />

      <Container maxWidth="lg" className="main_container px-0" sx={{ mt: 4 }}>
        <SailComponent />
      </Container>
    </Box>
  );
}

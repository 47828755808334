import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Box,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from "@mui/material";
import React from "react";
import images from "../../../../../assets/images";

const Empowering = () => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${images.empower})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        p: 5,
      }}
    >
      <Container>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography
              className="styled_header fw-600 font-poppins"
              gutterBottom
              sx={{ color: "var(--color-darkSky)" }}
            >
              Empowering You
            </Typography>
            <Typography className="f-18 text-white fw-500 font-lora mb-1">
              This handbook is more than an educational resource—it's a beacon
              of support. By providing knowledge and fostering self-awareness,
              we aim to:
            </Typography>
            <List>
              {[
                "Protect your rights.",
                "Amplify your voice",
                "Equip you with the tools to advocate for yourself confidently.",
              ].map((text, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <CheckCircleIcon sx={{ color: "var(--color-darkSky)" }} />
                  </ListItemIcon>
                  <Typography className="f-18 text-white fw-500 font-lora">
                    {text}
                  </Typography>
                </ListItem>
              ))}
            </List>

            <Button
              variant="contained"
              className="contact_btn f-16"
              endIcon={<ArrowOutwardIcon />}
              sx={{ mt: 2 }}
            >
              Explore the Episodes
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Empowering;

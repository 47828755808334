import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";

const StartingComponent = ({
  backgroundImage,
  headerText,
  buttonText,
  onButtonClick,
  subHeader,
  paragraph,
}) => {
  return (
    <Box
      className="radius-16 text-center bg-position-center bg-cover"
      sx={{
        position: "relative",
        width: "100%",
        backgroundImage: `url(${backgroundImage})`,
        p: 5,
      }}
    >
      <Container
        sx={{
          position: "relative",
          textAlign: "center",
          color: "white",
          px: 2,
          py: "50px",
        }}
      >
        <Typography className="sail_header fw-600">
          {headerText || ""}
        </Typography>

        {subHeader && (
          <Typography
            className="fw-500 font-lora"
            sx={{ fontSize: { lg: "24px", md: "20px", sm: "18px" } }}
          >
            {subHeader ||
              "Set sail on your journey today. Let’s navigate life together."}
          </Typography>
        )}

        {paragraph && (
          <Typography py={2} className="fw-500 font-lora text-light f-18">
            {paragraph}
          </Typography>
        )}

        {buttonText && (
          <Box className="sail_btn" mt={2}>
            <Button
              variant="contained"
              className="contact_btn mb-0 fw-600"
              endIcon={<ArrowOutwardIcon />}
              onClick={onButtonClick}
            >
              {buttonText || "Start Now"}
            </Button>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default StartingComponent;

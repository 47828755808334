import { Avatar, Box, Grid, Rating, Typography } from "@mui/material";
import React from "react";

const ReviewCard = ({ name, avatar, rating, review, date, level }) => {
  return (
    <Box
      sx={{
        pt: 2,
        borderRadius: 2,
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        gap: 2,
        m: "auto",
        mb: "20px",
      }}
    >
      {/* Avatar */}
      <Avatar src={avatar} alt={name} sx={{ width: 50, height: 50 }} />

      {/* Review Content */}
      <Box sx={{ flex: 1 }}>
        <Typography className="fw-500 f-18 font-poppins text-black">
          {name}
        </Typography>
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <Typography variant="body2" fontWeight={500}>
              {rating}
            </Typography>
          </Grid>
          <Grid item>
            <Rating value={rating} precision={0.1} readOnly size="small" />
          </Grid>
        </Grid>
        <Typography
          variant="body2"
          className="color-para f-16 font-lora"
          mt={1}
        >
          {review}
        </Typography>
      </Box>

      {/* Date */}
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={1}
        justifyContent={"center"}
        alignItems={"flex-end"}
      >
        {level ? (
          <Typography
            variant="caption"
            className="fw-500 f-16 font-poppins text-black"
            sx={{ mt: 1 }}
          >
            🕒 {level}
          </Typography>
        ) : null}
        <Typography
          variant="caption"
          className="fw-500 f-16 font-poppins text-black"
          sx={{ mt: 1 }}
        >
          📅 {date}
        </Typography>
      </Box>
    </Box>
  );
};

export default ReviewCard;

import React, { useState } from 'react';
import { Typography, Tabs, Tab, Box } from '@mui/material';

export default function DashboardCourses() {
  const [tab, setTab] = useState(0); 

  return (
    <div>
      <Typography
        mb={2}
        className="sub_header fw-500 font-poppins text-black"
        gutterBottom
      >
        Courses
      </Typography>
      <Tabs
        value={tab}
        onChange={(e, newValue) => setTab(newValue)}
        aria-label="course tabs"
      >
        <Tab className="f-18 fw-500 font-poppins text-black" label="All Courses" />
        <Tab className="f-18 fw-500 font-poppins text-black" label="Enrolled" />
        <Tab className="f-18 fw-500 font-poppins text-black" label="Completed" />
      </Tabs>
      <Box sx={{ mt: 2 }}>
        {tab === 0 && <Typography>No courses available</Typography>}
        {tab === 1 && <Typography>No enrolled courses</Typography>}
        {tab === 2 && <Typography>No completed courses</Typography>}
      </Box> 
    </div>
  );
}

import { Email, Lock, LockOpen, Person } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import images from "../../assets/images";
import { useCreateUserMutation } from "../../redux/api/apiSlice";

const CreateAccount = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [createUser, { isLoading, error }] = useCreateUserMutation();

  const onSubmit = async (data) => {
    const { firstName, lastName, email, password, confirmPassword } = data;
    if (password !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    const userPayload = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: password,
      confirm_password: password,
      role_id: null,
    };

    try {
      const response = await createUser(userPayload).unwrap();
      console.log("User created successfully:", response);
      navigate("/login-account");
    } catch (err) {
      console.error("Error creating user:", err);
    }
  };
  return (
    <Box py={5} sx={{ backgroundColor: "var(--color-lightSky)" }}>
      <Container
        sx={{
          py: 5,
        }}
      >
        <Box className="all_center">
          <Grid
            container
            spacing={2}
            sx={{ maxWidth: "1200px", width: "100%" }}
          >
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  borderRadius: "10px",
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={images.wellcome}
                  alt="welcome"
                  className="object-fit-cover"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    display: "block",
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box p={3}>
                <Box
                  className="d-flex align-items-center justify-content-between"
                  mb={1}
                >
                  <Typography variant="h4" className="fw-600 text-black">
                    Create Your Account
                  </Typography>
                  {/* <img
                    src={images.wheel}
                    alt="wheel"
                    className="object-fit-contain"
                  /> */}
                </Box>
                <Typography variant="body1" sx={{ color: "#666", mb: 4 }}>
                  Join Centerboard Wellness and navigate towards a healthier,
                  more balanced life. Sign up today to track your progress, set
                  personal goals, and unlock expert-guided wellness programs.
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container spacing={2}>
                    {/* First Name */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        className=""
                        fullWidth
                        label="First Name"
                        variant="outlined"
                        {...register("firstName", {
                          required: "First Name is required",
                        })}
                        error={!!errors.firstName}
                        helperText={errors.firstName?.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Person />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Last Name"
                        variant="outlined"
                        {...register("lastName", {
                          required: "Last Name is required",
                        })}
                        error={!!errors.lastName}
                        helperText={errors.lastName?.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Person />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>

                    {/* Email */}
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Email"
                        variant="outlined"
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value:
                              /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                            message: "Invalid email address",
                          },
                        })}
                        error={!!errors.email}
                        helperText={errors.email?.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Email />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>

                    {/* Create Password */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Create Password"
                        variant="outlined"
                        type="password"
                        {...register("password", {
                          required: "Password is required",
                        })}
                        error={!!errors.password}
                        helperText={errors.password?.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Lock />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>

                    {/* Confirm Password */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Confirm Password"
                        variant="outlined"
                        type="password"
                        {...register("confirmPassword", {
                          required: "Please confirm your password",
                        })}
                        error={!!errors.confirmPassword}
                        helperText={errors.confirmPassword?.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LockOpen />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>

                    {/* Submit Button */}
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        className="contact_btn f-16 w-100"
                        type="submit"
                        // onClick={() => (window.location.href = "/")}
                      >
                        Start Your Journey
                      </Button>
                    </Grid>
                  </Grid>
                </form>
                <Divider sx={{ my: 2 }}>Or</Divider>
                <Button
                  fullWidth
                  sx={{ mb: 2 }}
                  className="border-1 text-black font-poppins gap-1"
                  p={1}
                >
                  Continue with Google
                  <img
                    src={images.google}
                    alt="google"
                    className="object-fit-contain"
                  />
                </Button>
                {/* Link to login */}
                <Typography
                  align="center"
                  mt={1}
                  className="color-para font-lora gap-1 f-16"
                >
                  Already have an account?
                  <a
                    href="/login-account"
                    className="text-darksky font-poppins"
                  >
                    Log in
                  </a>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default CreateAccount;

import { Box, Container } from "@mui/material";
import images from "../../assets/images";
import AnchorCustomSection from "../components/AnchorCustomSection";
import InfoCardGrid from "../components/InfoCard";
import PageHeader from "../components/PageHeader ";
import BecomeSponser from "./components/BecomeSponser";
import CurrentSponser from "./components/CurrentSponser";

export default function Sponsors({ poweredData, poweredDetailData }) {
  return (
    <Box mb={2}>
      <AnchorCustomSection
        title="Our Sponsors & Partners: Helping Us Navigate Forward"
        subTitle="Our journey wouldn’t be possible without the generosity of our sponsors. These guiding stars help us provide life-changing services and support to those who need it most."
        backgroundImage={images?.sponserBanner}
      />

      <Container maxWidth="lg" sx={{ py: 5, mt: 3 }} className="main_container">
        <PageHeader {...poweredData} />
        <InfoCardGrid data={poweredDetailData} />{" "}
      </Container>
      <Container maxWidth="lg" sx={{ pb: 3 }} className="main_container">
        <CurrentSponser />
      </Container>
      <Container maxWidth="lg" sx={{ my: 3 }} className="main_container">
        <BecomeSponser />
      </Container>
    </Box>
  );
}

import WindowIcon from "@mui/icons-material/Window";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import images from "../../../../../assets/images";

const ResearchBased = () => {
  const podcastSections = [
    {
      id: "01",
      title: "Introduction",
      description: "Establish purpose and hypotheses.",
    },
    {
      id: "02",
      title: "Literature Review",
      description:
        "Address themes of fear, attitude, and incentive with DUI statistics and psychological studies.",
      highlight: true,
    },
    {
      id: "03",
      title: "Methodology",
      description:
        "Explain podcast design, survey tools, and engagement strategies.",
    },
    {
      id: "04",
      title: "Results",
      description:
        "Showcase pre- and post-listener data, highlighting changes in behavior and attitudes.",
    },
    {
      id: "05",
      title: "Discussion and Implications",
      description: "Evaluate podcast effectiveness and future directions.",
    },
  ];
  return (
    <Box
      className="radius-16 bg-cover bg-position-center"
      sx={{
        backgroundImage: `url(${images.research})`,
        p: 5,
      }}
    >
      <Container>
        <Grid container spacing={4} className="all_center" py={3}>
          <Grid item xs={12} className="text-center">
            <Box className="all_center gap-1" mb={3}>
              <Box
                className="rounded-btns all_center cursor-pointer"
                borderColor="var(--color-black)"
                p={2}
              >
                <WindowIcon fontSize="20px" className="text-black" />
              </Box>
              <Typography
                variant="button"
                borderColor="var(--color-black)"
                className="rounded-btns cursor-pointer text-black capitalize_text"
                py={1}
                px={2}
              >
                Chapter Structure
              </Typography>
            </Box>
            <Typography
              className="styled_header fw-600 font-poppins"
              gutterBottom
            >
              Integration into a Research-Based Framework{" "}
            </Typography>
            <Typography className="f-18 color-para fw-500 font-lora">
              By aligning this podcast project with research principles, you can
              create a compelling, impactful program that educates, inspires,
              and supports individuals navigating the consequences of DUI.
            </Typography>

            <Box>
              <Grid container spacing={2} justifyContent="center" mt={3}>
                {podcastSections.map((section, index) => (
                  <Grid item xs={12} sm={6} md={2.4} key={index}>
                    <Card
                      className="all_cenetr research_card"
                      sx={{
                        height: "100%",
                      }}
                    >
                      <CardContent className="text-start">
                        <Typography variant="h5">{section.id}</Typography>
                        <Typography
                          variant="h6"
                          className="f-18 color-para fw-500 font-poppins text-doc"
                          sx={{
                            my: 1,
                          }}
                        >
                          {section.title}
                        </Typography>
                        <Typography className="f-16 color-para fw-500 font-lora text-doc">
                          {section.description}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ResearchBased;

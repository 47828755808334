import { Add, Remove } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

const AccordionCard = ({ title, content }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleToggle}
      sx={{
        boxShadow: "none",
        border: "1px solid #E0E0E0",
        borderRadius: "8px",
        backgroundColor: "var(--color-midsky)",
        mb: "20px",
      }}
    >
      <AccordionSummary
        sx={{
          backgroundColor: "#E0F2FE",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontWeight: "bold",
          margin: "0px",
        }}
      >
        <Typography
          sx={{ flexGrow: 1 }}
          className="font-poppins text-black fw-600"
        >
          {title}
        </Typography>
        {expanded ? (
          <Remove className="text-darksky" />
        ) : (
          <Add className="text-darksky" />
        )}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          backgroundColor: "#F7FBFE",
          padding: "12px 16px",
          color: "#616161",
        }}
      >
        <Typography className="text-secondary text-start f-16 font-lora">
          {content}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionCard;

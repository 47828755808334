import { Typography } from "@mui/material";
import Slider from "react-slick";

function SliderComponent() {
  const settings = {
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    cssEase: "linear",
    arrows: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        <Typography className="slider_header fw-600">WELCOME TO</Typography>
        <Typography className="slider_header fw-600">CENTERBOARD </Typography>
        <Typography className="slider_header fw-600">WELLNESS</Typography>
        {/* <Typography className="slider_header fw-600">WELLNESS</Typography> */}
        {/* <Typography className="slider_header fw-600"> </Typography> */}
        <Typography className="slider_header fw-600">WELCOME TO</Typography>
        <Typography className="slider_header fw-600">CENTERBOARD</Typography>
        <Typography className="slider_header fw-600">WELLNESS</Typography>
        <Typography className="slider_header fw-600">WELCOME TO</Typography>
        <Typography className="slider_header fw-600">CENTERBOARD</Typography>
        <Typography className="slider_header fw-600">WELLNESS</Typography>
      </Slider>
    </div>
  );
}

export default SliderComponent;

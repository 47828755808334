import BorderColorIcon from "@mui/icons-material/BorderColor";
import SettingsIcon from "@mui/icons-material/Settings";
import ShieldOutlinedIcon from "@mui/icons-material/ShieldOutlined";
import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import images from "../../../assets/images";
import { useGetIntakeFormsQuery } from "../../../redux/api/apiSlice";
import CustomAppBar from "../../components/CustomAppBar";
import "./hippaform.css";

const HippaEditForm = () => {
  const navigate = useNavigate();
  const {
    data: intakeFormsResponse,
    error,
    isLoading,
  } = useGetIntakeFormsQuery();
  const intakeForm = intakeFormsResponse?.result?.data;
  const [selectedFormId, setSelectedFormId] = useState(null);

  const handleEditProfile = () => {
    navigate("/dashboard/profile-information");
  };

  const handleDeleteProfile = () => {
    alert("Delete Profile clicked!");
  };

  const appBarData = [
    {
      title: "Client Management",
      icon: <SettingsIcon />,
      xp: "6000 XP",
      notifications: 90,
      profileImage: images.kimAvatar,
      onEditProfile: handleEditProfile,
      onDeleteProfile: handleDeleteProfile,
    },
  ];

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleEditClick = (id) => {
    setSelectedFormId(id);
    navigate("/dashboard/hippa-compliance", { state: { formId: id } });
  };

  return (
    <Box className="d-flex justify-content-between align-items-center flex-wrap">
      <CustomAppBar {...appBarData[0]} />

      <Box className="d-flex align-items-center justify-content-between w-100">
        <Typography className="sub_header font-poppins fw-500 text-black">
          Submitted Forms
        </Typography>
        <Button className="contact_abr_btn f-16" sx={{ px: "12px" }}>
          Add New
        </Button>
      </Box>
      {intakeForm && (
        <Box
          key={intakeForm.id}
          className="d-flex align-items-center justify-content-between w-100 border-r-8"
          mt={3}
          sx={{
            p: "12px 10px",
            border: "1px solid #ccc",
            "@media (max-width: 600px)": {
              flexDirection: "column",
              textAlign: "center",
            },
          }}
        >
          <Box className="d-flex align-items-center gap-1">
            <ShieldOutlinedIcon
              sx={{ color: "var(--color-black)", fontSize: "20px" }}
            />
            <Typography className="fw-600 f-20 font-poppins text-black">
              {(
                <span>
                  {intakeForm.first_name} {intakeForm.last_name}
                </span>
              ) || "HIPAA-Compliant Intake Form"}
            </Typography>
          </Box>
          <Button
            className="contact_abr_btn f-16 "
            sx={{ px: "12px" }}
            onClick={() => handleEditClick(intakeForm.id)}
          >
            Edit
            <BorderColorIcon
              sx={{ color: "var(--color-white)", fontSize: "17px", mx: 1 }}
            />
          </Button>
        </Box>
      )}
    </Box>
  );
};
export default HippaEditForm;

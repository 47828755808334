import BuildIcon from "@mui/icons-material/Build";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import images from "../images";

export const worksDetailData = [
  {
    title: "Secures and Private",
    description:
      "Your personal information is protected with HIPAA-compliant encryption, ensuring confidentiality and peace of mind.",
    icon: images.reflection,
  },
  {
    title: "User-Friendly Interface",
    description:
      "Navigate effortlessly through your logbook, from uploading documents to reviewing your progress reports.",
    icon: images.discovery,
  },
  {
    title: "Integrated Tools",
    description:
      "All features are seamlessly connected, providing a holistic view of your personal and financial progress.",
    icon: images.empowerment,
  },
];

export const guiderData = [
  {
    icon: images.leadOne,
    title: "Expense Tracking",
    description:
      "Monitor program costs, resources, and payments to ensure responsible financial management.",
  },
  {
    icon: images.leadTwo,
    title: "Goal Setting and Budgetin",
    description:
      "Create SMART financial goals and use tracking tools to stay on target.",
  },
  {
    icon: images.leadThree,
    title: "Resource Management",
    description:
      "Access guides on managing time and resources effectively to enhance personal and professional growth.",
  },
];

export const logBookData = [
  {
    icon: <SupportAgentIcon sx={{ fontSize: 50, color: "#0097A7" }} />,
    title: "Accountability",
    description:
      "Stay motivated with structured tracking and frequent progress updates.",
  },
  {
    icon: <BuildIcon sx={{ fontSize: 50, color: "#0097A7" }} />,
    title: "Reflection",
    description:
      "Reflect on your achievements, assess your challenges, and set your sights on new horizons.",
  },
  {
    icon: <SelfImprovementIcon sx={{ fontSize: 50, color: "#0097A7" }} />,
    title: "Support",
    description:
      "The logbook empowers you to take control of your journey, offering tools to manage both personal growth and financial well-being.",
  },
];

export const compassData = [
  {
    icon: images.chartOne,
    title: "Intake Forms",
    description:
      "Complete and securely upload essential documents to begin your journey with clarity and confidence.",
  },
  {
    icon: images.chartTwo,
    title: "Adaptive Assessments",
    description:
      "Take personalized assessments designed to adapt to your needs and provide actionable insights into your growth.",
  },
  {
    icon: images.chartThree,
    title: "45-Day Review Reminders",
    description:
      "Receive timely notifications to reflect on progress, update goals, and ensure you're staying on track.",
  },
  {
    icon: images.chartFour,
    title: "Milestone Tracking",
    description:
      "Log achievements and evaluate how far you've come on your journey.",
  },
  {
    icon: images.chartFive,
    title: "Discharge Reports",
    description:
      "Access comprehensive summaries of your progress, personal reflections, and key outcomes upon completion of your program.",
  },
];

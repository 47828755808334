import BalanceIcon from "@mui/icons-material/Balance";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import GolfCourseIcon from "@mui/icons-material/GolfCourse";
import images from "../images";

export const programs = [
  {
    icon: images.chartOne,
    title: "Daily Journaling and Reflection",
    description:
      "Reflect on your journey with guided journaling and meaningful activities to deepen self-awareness.",
  },
  {
    icon: images.chartTwo,
    title: "Interactive Activities and Lessons",
    description:
      "Engage in hands-on, practical tools that make learning and growth more engaging and rewarding.",
  },
  {
    icon: images.chartThree,
    title: "Celebrate Your Progress",
    description:
      "Track your milestones, celebrate achievements, and earn rewards for your progress.",
  },
  {
    icon: images.chartFour,
    title: "Educational Tools for Success",
    description:
      "Gain knowledge and insights that help simplify life’s challenges and support better decision-making.",
  },
  {
    icon: images.chartFive,
    title: "Personal Growth Resources",
    description:
      "Access a wide variety of programs tailored to meet your unique needs and aspirations.",
  },
];

export const cardsData = [
  {
    image: images.discoverOne,
    title: "Curriculum",
    description: "Explore Your Course: A Holistic Approach to Learning",
    buttonLabel: "Get Started",
  },
  {
    image: images.discoverTwo,
    title: "Rewards Store",
    description: "Your Cognitive Compass - Earn, Redeem, Thrive",
    buttonLabel: "Get Started",
  },
  {
    image: images.discoverThree,
    title: "Client Management",
    description: "Your Personal Logbook - Manage Your Journey",
    buttonLabel: "Get Started",
  },
];

export const contentData = [
  {
    imgSrc: <GolfCourseIcon />,
    title: "Chart Your Course:",
    description:
      "Establish a clear path to your personal ambitions by setting defined, actionable goals.",
  },
  {
    imgSrc: <BalanceIcon />,
    title: "Stay Steady Through Storms:",
    description:
      "Life’s currents may be unpredictable, but resilience and adaptability will keep you steady on course.",
  },
  {
    imgSrc: <EmojiEventsIcon />,
    title: "Celebrate Every Victory:",
    description:
      "Every achievement—big or small—is a milestone on your journey. Take time to celebrate your progress.",
  },
];

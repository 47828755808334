import {
  AccessTime,
  Layers,
  People,
  RocketLaunch,
  SignalCellularAlt,
  Star,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import images from "../../../../../assets/images";

// Reusable Course Progress Card Component
const CourseProgressCard = ({
  title,
  description,
  instructor,
  duration,
  level,
  modules,
  students,
  rating,
  progress,
  totalLessons,
  xp,
  icon,
}) => {
  // Course details mapped dynamically
  const courseDetails = [
    { icon: <AccessTime fontSize="small" />, text: duration },
    { icon: <SignalCellularAlt fontSize="small" />, text: level },
    { icon: <Layers fontSize="small" />, text: `${modules} modules` },
    { icon: <People fontSize="small" />, text: `${students} students` },
    { icon: <Star fontSize="small" />, text: rating },
  ];

  return (
    <Card
      sx={{
        borderRadius: 3,
        p: 3,
      }}
      className="border-card-1 bg-lightsky"
    >
      <CardContent>
        {/* Header Section */}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Typography className="text-blue fw-500 font-poppins f-14">
              ● CONTINUE LEARNING
            </Typography>
            <Typography
              className="text-black fw-500 font-poppins sub_header"
              sx={{ mt: 1 }}
            >
              {title}
            </Typography>
          </Box>
          <Box>
            {icon || (
              <RocketLaunch
                fontSize="large"
                sx={{ color: "var(--color-darkSky)" }}
              />
            )}
          </Box>
        </Stack>

        {/* Description */}
        <Typography className="color-para fw-500 font-lora f-16" sx={{ mt: 1 }}>
          {description}
        </Typography>

        {/* Progress Bar */}
        <Box sx={{ mt: 2 }}>
          <LinearProgress
            variant="determinate"
            value={(progress / totalLessons) * 100}
            sx={{
              my: 2,
              p: "6px",
              borderRadius: "8px",
              backgroundColor: "lightgray",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "#00ACC1",
              },
            }}
          />
          <Typography className="text-blue fw-500 font-poppins f-14">
            {progress} of {totalLessons} lessons completed
          </Typography>
        </Box>

        {/* Instructor and Course Info */}
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{ mt: 3, flexWrap: "wrap" }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Avatar src={images.kimAvatar} sx={{ width: 24, height: 24 }} />
            <Typography className="color-para">{instructor}</Typography>
          </Stack>

          {/* Mapping through course details dynamically */}
          {courseDetails.map((detail, index) => (
            <Stack
              key={index}
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{ color: "var(--color-darkSky)" }}
            >
              {detail.icon}
              <Typography className="color-para">{detail.text}</Typography>
            </Stack>
          ))}
        </Stack>

        {/* Buttons and XP */}
        <Box
          mt={3}
          className="d-flex justify-content-between align-items-center"
        >
          <Button
            variant="contained"
            className="contact_btn f-16"
            sx={{ mt: 1, px: "40px" }}
            component={Link}
            to="education-level"
          >
            Resume Learning
          </Button>

          <Typography className="text-darkblue font-lora f-14 xp_hero cursor-pointer">
            🏆 {xp} XP
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CourseProgressCard;

import { FormLabel, FormHelperText } from "@mui/material";
import TextField from "@mui/material/TextField";
import React from "react";
import { Controller } from "react-hook-form";

const CustomInput = ({ label, name, control, rules, error, helperText, type = "text", ...props }) => {
  return (
    <>
      <FormLabel
        sx={{
          marginBottom: "8px",
          display: "block",
        }}
        className="text-black fw-500 font-poppins f-14 mt-1"
      >
        {label}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            type={type}
            error={error}
            sx={{
              backgroundColor: "rgb(250, 250, 250)",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: error ? "rgb(211, 47, 47)" : "rgb(224, 224, 224)",
                  borderRadius: "8px",
                },
                "&:hover fieldset": {
                  borderColor: error ? "rgb(211, 47, 47)" : "rgb(33, 150, 243)",
                },
                "&.Mui-focused fieldset": {
                  borderColor: error ? "rgb(211, 47, 47)" : "rgb(33, 150, 243)",
                },
              },
              "& .MuiInputBase-input": {
                padding: "10px 14px",
              },
              "& .MuiInputBase-input::placeholder": {
                color: "rgb(158, 158, 158)",
                opacity: 1,
              },
            }}
            {...props}
          />
        )}
      />
      {error && helperText && (
        <FormHelperText error sx={{ marginLeft: "14px", marginTop: "3px" }}>
          {helperText}
        </FormHelperText>
      )}
    </>
  );
};

export default CustomInput;
import { Box } from "@mui/material";
import "../Home/home.css";
import Cources from "./components/Cources";
import HolisticAppraoch from "./components/HolisticAppraoch";
import "./style/curriculum.css";

export default function ExplorerGuide({ holisticCardsData }) {
  return (
    <Box>
      <Cources />
      <HolisticAppraoch holisticCardsData={holisticCardsData} />
    </Box>
  );
}

import { AccessTime, School } from "@mui/icons-material";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import React from "react";

import { Box, IconButton, Typography } from "@mui/material";

const VideoCard = ({ title, hours, lessons, xp }) => {
  // Array of items for dynamic rendering
  const infoItems = [
    { icon: <AccessTime fontSize="small" />, label: `${hours} hours` },
    { icon: <MenuBookIcon fontSize="small" />, label: `${lessons} Lessons` },
    { icon: <School fontSize="small" /> , label: `${xp} XP` },
  ];

  return (
    <Box>
      {/* Title Section */}
      <Typography variant="h5" component="div" sx={{ fontWeight: "bold" }}>
        {title}
      </Typography>

      <Box sx={{ display: "flex", gap: "20px", alignItems: "center" }} my={1}>
        {infoItems.map((item, index) => (
          <Box key={index} sx={{ display: "flex", alignItems: "center" }}>
            <IconButton sx={{color:"var(--color-darkSky)"}}>{item.icon}</IconButton>
            <Typography className="font-poppins color-para f-14">
              {item.label}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default VideoCard;

import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";

const InfoCard = ({ title, description, icon }) => {
  return (
    <Card
      className="info_card all_center flex-column w-100"
      sx={{
        p: 3,
        borderRadius: "12px",
      }}
    >
      <Box className="text-start d-flex w-100" justifyContent="start">
        <img src={icon} alt="icons" className="info_icon" />
      </Box>
      <CardContent
        className="text-start d-flex flex-column p-0 w-100"
        justifyContent="start"
      >
        <Typography
          className="fw-600 text-black font-poppins info_header"
          mt={3}
        >
          {title}
        </Typography>
        <Typography
          className="fw-400 color-para font-lora f-18"
          mt={1}
        >
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

const InfoCardGrid = ({ data }) => {
  return (
    <Grid container spacing={2} sx={{ my: 3 }}>
      {data.map((card, index) => (
        <Grid item xs={12} sm={6} md={4} key={index} className="all_center">
          <InfoCard
            title={card.title}
            description={card.description}
            icon={card.icon}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default InfoCardGrid;

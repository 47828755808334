import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import WindowIcon from "@mui/icons-material/Window";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import images from "../../../assets/images";

const DiscoverCard = ({ image, title, description, buttonLabel }) => (
  <Box className="styledCard all_center">
    <img
      className="styledCardMedia"
      alt="card_images"
      src={image}
      title={title}
    />
    <Box className="contentOverlay">
      <Typography variant="h6" className="text-white">
        {title}
      </Typography>
      <Typography className="f-18 text-white" mb={1}>
        {description}
      </Typography>
    </Box>
    <Box
      sx={{ display: "flex", justifyContent: "flex-end" }}
      className="started_btn"
    >
      <Button
        variant="contained"
        className="contact_btn m-0 capitalize_text"
        endIcon={<ArrowOutwardIcon />}
      >
        {buttonLabel}
      </Button>
    </Box>
  </Box>
);

const Discover = ({ cardsData }) => {
  return (
    <Container maxWidth="lg" sx={{ py: 5 }} className="main_container">
      <Grid>
        <Box className="all_center gap-1" mb={3}>
          <Box className="rounded-btns all_center cursor-pointer" p={2}>
            <WindowIcon fontSize="20px" className="window_icon" />
          </Box>
          <Typography
            variant="button"
            color="primary"
            className="rounded-btns cursor-pointer text-darksky capitalize_text"
            py={1}
            px={2}
          >
            Features
          </Typography>
        </Box>
        <Box className="text-center">
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            className="styled_header fw-600 font-poppins"
          >
            Anchor Yourself, Navigate Your Potential.
          </Typography>
          <Typography
            variant="body1"
            align="center"
            color="text.secondary"
            className="f-18 font-lora"
          >
            Chart your goals and navigate your journey with ease.
          </Typography>
        </Box>
        <Grid
          container
          className="justify-content-center d-flex"
          spacing={4}
          mt={1}
        >
          {cardsData.map((card, index) => (
            <Grid
              item
              lg={4}
              md={4}
              sm={6}
              key={index}
              className="justify-content-center d-flex"
            >
              <DiscoverCard {...card} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Discover;

import { Box, CardContent, Grid, Typography } from "@mui/material";

const WellnessFeatures = ({ features, header }) => {
  return (
    <Box className="wellness_feature text-center radius-16">
      <Typography
        className="styled_subheder_header fw-600 font-poppinss text-black"
        p={3}
      >
        {header ? header : "Why Choose Centerboard Wellness Center?"}
      </Typography>

      <Grid container spacing={4} justifyContent="center">
        {features.map((feature, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box
              sx={{
                p: 3,
                borderRadius: 3,
                boxShadow: 0,
                transition: "0.3s",
                "&:hover": { boxShadow: 3 },
                height: "100%",
              }}
            >
              <Box>{feature.icon}</Box>
              <CardContent>
                <Typography variant="h6" fontWeight="bold">
                  {feature.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {feature.description}
                </Typography>
              </CardContent>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default WellnessFeatures;

import { Box, Container, Grid, Typography } from "@mui/material";
import images from "../../../assets/images";
import ImagesDetail from "./ImagesDetail";

const Cources = () => {
  const imagesData = [images.oneIcon, images.twoIcon, images.threeIcon];

  return (
    <Box
      className="flex-column all_center text-center main_slider"
      sx={{
        backgroundImage: `url(${images?.cbanner})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "auto",
        color: "var(--color-white)",
        height: {
          xs: "60vh",
          sm: "75vh",
          md: "80vh",
          lg: "85vh",
        },
      }}
    >
      <Container maxWidth="lg">
        {" "}
        <Grid
          container
          spacing={4}
          alignItems="center"
          justifyContent="center"
          sx={{
            padding: { xs: 4, md: 8 },
          }}
        >
          <Grid item xs={12} md={8} className="all_center flex-column">
            <Box>
              <Typography
                gutterBottom
                className="fw-600 main_header styled_header text-light"
                mb={1}
              >
                Explore Your Courses
              </Typography>
              <Typography className="sub_header text-light">
                Chart your path with our six engaging and purpose-driven
                curriculums. From self-discovery to understanding the court
                process, each program is tailored to enhance your growth,
                resilience, and decision-making.
              </Typography>
            </Box>
            <Box className="healthy_bar">
              {/* Heading Text */}
              <Typography className="fw-600 mb-0 f-18 text-start w-100">
                Join our active healthy community
              </Typography>

              {/* Subheading Text */}
              <Typography className="mb-0 f-16 text-start w-100" pb={2}>
                As easy as a click away.
              </Typography>

              {/* Avatars */}
              <Box className="d-flex justify-content-between align-items-center w-100">
                <ImagesDetail userImages={imagesData} />

                <Box className="rounded-btns all_center cursor-pointer join_btn">
                  +{" "}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Cources;

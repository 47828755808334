import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { Box, Button, Typography } from "@mui/material";
import images from "../../assets/images";

const NotFound = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: {
          xs: "60vh", 
          sm: "85vh", 
          md: "90vh", 
          lg: "100vh", 
        },
        textAlign: "center",
        p: 2,
        position: "relative",
        backgroundImage: `url(${images.errorBanner})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Typography className="error_header fw-600">404 ERROR</Typography>
      <Typography className="fw-500 sub_header text-black font-poppins">
        Looks like you’ve drifted off course. Let’s get you back to shore.
      </Typography>

      <Button
        variant="contained"
        className="contact_btn f-16"
        endIcon={<ArrowOutwardIcon />}
        onClick={() => (window.location.href = "/")}
      >
        Navigate Home
      </Button>


      
    </Box>
  );
};

export default NotFound;

import { Email, Lock } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import images from "../../assets/images";
import { useLoginUserMutation } from "../../redux/api/apiSlice";

const LoginAccount = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [loginUser, { isLoading, error }] = useLoginUserMutation();

  const onSubmit = async (data) => {
    const { email, password } = data;
    console.log(data);

    const loginPayload = {
      email,
      password: password,
      role_id: null,
    };
    try {
      const response = await loginUser(loginPayload).unwrap();
      console.log("Login successful:", response.result.data.token);
      // const savedToken = response.result.data.token;

      if (response.result.data.token) {
        localStorage.setItem("authToken", response.result.data.token);
      }

      navigate("/dashboard/home");
    } catch (err) {
      console.error("Login failed:", err);
      alert(err?.data?.message || "Invalid email or password!");
    }
  };

  return (
    <Box py={5} sx={{ backgroundColor: "var(--color-lightSky)" }}>
      <Container
        sx={{
          py: 5,
        }}
      >
        <Box className="all_center">
          <Grid
            container
            spacing={2}
            sx={{ maxWidth: "1200px", width: "100%" }}
          >
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  borderRadius: "10px",
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={images.wellcome}
                  alt="welcome"
                  className="object-fit-cover"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    display: "block",
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box p={3}>
                <Box
                  className="d-flex align-items-center justify-content-between"
                  mb={1}
                >
                  <Typography variant="h4" className="fw-600 text-black">
                    Welcome Back, Captain!{" "}
                  </Typography>
                  {/* <img
                    src={images.wheel}
                    alt="wheel"
                    className="object-fit-contain"
                  /> */}
                </Box>
                <Typography variant="body1" sx={{ color: "#666", mb: 4 }}>
                  Continue your wellness journey with Centerboard. Log in to
                  access your personalized dashboard, track your progress, and
                  stay on course towards your goals.
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container spacing={2}>
                    {/* Email */}
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Email"
                        variant="outlined"
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value:
                              /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                            message: "Invalid email address",
                          },
                        })}
                        error={!!errors.email}
                        helperText={errors.email?.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Email />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>

                    {/* Password */}
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Password"
                        variant="outlined"
                        type="password"
                        {...register("password", {
                          required: "Password is required",
                        })}
                        error={!!errors.password}
                        helperText={errors.password?.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Lock />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>

                    {/* "Keep me logged in" Checkbox */}
                    <Box
                      className="d-flex align-items-center justify-content-between w-100"
                      mt={1}
                    >
                      <FormControlLabel
                        sx={{ ml: 1 }}
                        className="fw-500 f-14 font-poppins color-para"
                        control={
                          <Checkbox
                            {...register("keepLoggedIn")}
                            color="var(--color-darksky)"
                          />
                        }
                        label="Keep me logged in"
                      />
                      <Typography>
                        <a
                          className="fw-500 f-14 font-poppins color-para"
                          href="/forgot-password"
                          style={{ textDecoration: "none" }}
                        >
                          Forgot Password?
                        </a>
                      </Typography>
                    </Box>

                    {/* Login Button */}
                    <Box className="text-center w-100">
                      <Button
                        variant="contained"
                        className="contact_btn f-18 w-100  fw-600 font-poppins text-light"
                        type="submit"
                      >
                        Set Sail Again
                      </Button>
                    </Box>
                  </Grid>
                </form>

                <Divider sx={{ mb: 2 }}>Or</Divider>
                <Button
                  fullWidth
                  sx={{ mb: 2 }}
                  className="border-1 text-black font-poppins gap-1"
                  p={1}
                >
                  Continue with Google
                  <img
                    src={images.google}
                    alt="google"
                    className="object-fit-contain"
                  />
                </Button>

                {/* Link to login */}
                <Typography
                  align="center"
                  mt={1}
                  className="color-para font-lora gap-1 f-16"
                >
                  Don’t have an account?{" "}
                  <a
                    href="/create-account"
                    className="text-darksky font-poppins"
                  >
                    Create One
                  </a>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default LoginAccount;

import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import RocketIcon from "@mui/icons-material/Rocket";
import { Box, Grid } from "@mui/material";
import images from "../../../../assets/images";
import CustomAppBar from "../../../components/CustomAppBar";
import CourseCard from "./components/CourseCard ";
import CourseProgressCard from "./components/CourseProgressCard";

const coursesData = [
  {
    title: "Navigating the Seas of Self-Discovery",
    description: "Introduction to personal growth and self-awareness.",
    instructor: "Kim Sutherland",
    duration: "6 Weeks",
    level: "All Levels",
    modules: 5,
    students: 10,
    rating: 4.9,
    xp: 3300,
    icon: (
      <RocketIcon fontSize="large" sx={{ color: "var(--color-darkSky)" }} />
    ),
    avatar: images.kimAvatar,
    btn: "Explore the Episodes",
  },
  {
    title: "Legal Knowledge Handbook",
    description: "Find guiding lights to navigate life’s storms.",
    instructor: "Samantha Lee",
    duration: "3 Weeks",
    level: "All Levels",
    modules: 11,
    students: 22,
    rating: 4.9,
    xp: 1100,
    icon: (
      <RocketIcon fontSize="large" sx={{ color: "var(--color-darkSky)" }} />
    ),
    avatar: images.samanthaAvatar,
    btn: "Explore the Episodes",
  },
  {
    title: "Navigating DUI Prevention: A Podcast Journey",
    description: "Fear, Attitude & Incentives: The Road to Safer Decisions",
    instructor: "Michael Jenkins",
    duration: "2 Weeks",
    level: "All Levels",
    modules: 6,
    students: 5,
    rating: 5.0,
    xp: 600,
    icon: (
      <RocketIcon fontSize="large" sx={{ color: "var(--color-darkSky)" }} />
    ),
    avatar: images.michaelAvatar,
    btn: "Go to podcasts",
  },
];

const appBarData = [
  {
    title: "Enrolled Courses",
    icon: <PlayCircleOutlineIcon />,
    xp: "10 XP",
    notifications: 2,
    profileImage: images.kimAvatar,
  },
];

const EnrolledCourses = () => {
  return (
    <Box sx={{ py: 3 }}>
      <CustomAppBar {...appBarData[0]} />
      <Box>
        <CourseProgressCard
          title="DUI A Holistic Journey"
          description="Join us on this transformative journey toward safer roads and responsible driving. Together, we can build a safer community and a brighter future."
          instructor="Michael Jenkins"
          duration="2 Weeks"
          level="Intermediate"
          modules={3}
          students={22}
          rating={4.9}
          progress={2}
          totalLessons={24}
          xp={3300}
        />
      </Box>
      <Grid container spacing={3}>
        {coursesData.map((course, index) => (
          <Grid item xs={12} md={6} lg={4} key={index} display="flex">
            <CourseCard course={course} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default EnrolledCourses;

// AlertComponent.js

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React from "react";

const AlertComponent = ({ correctAnswer, image, isCorrect }) => {
  return (
    <Box width="100%" className="robotic_alert">
      <Box
        justifyContent="flex-end"
        alignItems="flex-start"
        gap={3}
        mt={5}
        className="d-flex position-relative"
      >
        <Box
          sx={{
            border: `2px solid ${isCorrect ? "#A5D6A7" : "#D3E3FF"}`,
            borderRadius: 2,
            p: 2,
            maxWidth: 400,
            textAlign: "left",
          }}
          className={isCorrect ? "bg-lightGreen" : "bg-lightsky"}
        >
          <Stack
            direction="row"
            spacing={1}
            alignItems="flex-start"
            className=""
          >
            {isCorrect ? (
              <CheckCircleOutlineIcon
                sx={{ color: "var(--color-lightGreen)" }}
              />
            ) : (
              <InfoOutlinedIcon sx={{ color: "var(--color-darkSky)" }} />
            )}
            <Typography variant="body2" className="font-poppins text-black">
              {isCorrect ? (
                <Typography component="span" fontWeight={600} fontSize={16}>
                  Kai's impressed! You're on fire
                </Typography>
              ) : (
                <>
                  Oof, close call! It’s actually{" "}
                  <Typography component="span" fontWeight={600} fontSize={16}>
                    {correctAnswer}
                  </Typography>
                  . Correct answer is this.
                </>
              )}
            </Typography>
          </Stack>
        </Box>
        <Box sx={{ mt: { lg: "90px", md: "40px" } }}>
          <img
            src={image}
            alt="Quiz Robot"
            width={250}
            height={250}
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AlertComponent;

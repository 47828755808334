import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SchoolIcon from "@mui/icons-material/School";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useState } from "react";

const NavigatorAccordion = ({ data, checked }) => {
  const [expanded, setExpanded] = useState(null);

  const handleExpand = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <Box className="navigator_accordian">
      {data.map((module, moduleIndex) => (
        <Accordion
          key={moduleIndex}
          expanded={expanded === moduleIndex}
          onChange={() => handleExpand(moduleIndex)}
          sx={{
            boxShadow: "none",
            mb: "10px",
            background: "transparent",
          }}
        >
          <AccordionSummary
            disabled={module?.sections?.length === 0}
            hid
            expandIcon={
              module?.sections?.length === 0 || expanded !== moduleIndex ? (
                <Box className="d-flex align-items-center">
                  <Typography
                    variant="body2"
                    className="f-16 text-black font-poppins"
                    sx={{ marginRight: "8px" }}
                  >
                    {checked}
                  </Typography>

                  <ExpandMoreIcon />
                </Box>
              ) : null
            }
            sx={{
              backgroundColor: "transparent",
              padding: { lg: "0px 20px", sm: "0px 10px" },
              fontWeight: "bold",
            }}
            className="card_summery"
          >
            <Box className="d-flex flex-column gap-1">
              <Typography
                variant="h6"
                className="f-20 text-black fw-500 module_title"
              >
                {module.title}
              </Typography>
              <Typography
                variant="body2"
                sx={{ marginLeft: "10px", color: "#666" }}
                className="f-18 text-midlight"
              >
                {module.moduleInfo}
              </Typography>
            </Box>
          </AccordionSummary>

          {module?.sections?.length > 0 ? (
            <AccordionDetails
              sx={{ backgroundColor: "transparent", padding: "20px" }}
            >
              {module.sections?.map((section, sectionIndex) => (
                <div key={sectionIndex}>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <SchoolIcon
                      sx={{ color: "var(--color-darkBlue)", mr: 1 }}
                    />
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      className="text-blue"
                    >
                      {section.title}
                    </Typography>
                  </Box>
                  <List>
                    {section.subHeading && (
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        sx={{ mt: 2 }}
                      >
                        {section.subHeading}
                      </Typography>
                    )}
                    {section.headingDetail && (
                      <List sx={{ pl: 5 }}>
                        {section.headingDetail.map((detail, index) => (
                          <ListItem
                            key={index}
                            sx={{ display: "list-item", listStyleType: "disc" }}
                          >
                            <ListItemText primary={detail} />
                          </ListItem>
                        ))}
                      </List>
                    )}

                    {section.subHeadingsTwo && (
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        sx={{ mt: 2 }}
                      >
                        {section.subHeadingsTwo}
                      </Typography>
                    )}
                    {section.headingsTwo && (
                      <List sx={{ pl: 5 }}>
                        {section.headingsTwo.map((heading, index) => (
                          <ListItem
                            key={index}
                            sx={{ display: "list-item", listStyleType: "disc" }}
                          >
                            <ListItemText primary={heading} />
                          </ListItem>
                        ))}
                      </List>
                    )}

                    {section.financialPlanning && (
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        sx={{ mt: 2 }}
                      >
                        {section.financialPlanning}
                      </Typography>
                    )}
                    {section.financialPlanningHeader && (
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        sx={{ mt: 2 }}
                      >
                        {section.financialPlanningHeader}
                      </Typography>
                    )}
                    {section.financialPlanningDetail && (
                      <List sx={{ pl: 5 }}>
                        {section.financialPlanningDetail.map(
                          (detail, index) => (
                            <ListItem
                              key={index}
                              sx={{
                                display: "list-item",
                                listStyleType: "disc",
                              }}
                            >
                              <ListItemText primary={detail} />
                            </ListItem>
                          )
                        )}
                      </List>
                    )}

                    {section.cognitiveHeader && (
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        sx={{ mt: 2 }}
                      >
                        {section.cognitiveHeader}
                      </Typography>
                    )}
                    {section.cognitiveDetail && (
                      <List sx={{ pl: 5 }}>
                        {section.cognitiveDetail.map((detail, index) => (
                          <ListItem
                            key={index}
                            sx={{ display: "list-item", listStyleType: "disc" }}
                          >
                            <ListItemText primary={detail} />
                          </ListItem>
                        ))}
                      </List>
                    )}

                    {section.reliefHeader && (
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        sx={{ mt: 2 }}
                      >
                        {section.reliefHeader}
                      </Typography>
                    )}
                    {section.reliefDetail && (
                      <List sx={{ pl: 5 }}>
                        {section.reliefDetail.map((detail, index) => (
                          <ListItem
                            key={index}
                            sx={{ display: "list-item", listStyleType: "disc" }}
                          >
                            <ListItemText primary={detail} />
                          </ListItem>
                        ))}
                      </List>
                    )}

                    {/* Original items mapping */}
                    {section.items?.map((text, index) => {
                      const [boldText, regularText] = text
                        .split("–")
                        .map((part) => part.trim());
                      return (
                        <ListItem
                          key={index}
                          className="d-flex align-items-center"
                        >
                          {section.icon ? (
                            <ListItemIcon className="text-midlight">
                              {section.icon}
                            </ListItemIcon>
                          ) : null}
                          <ListItemText
                            className="text-midlight"
                            primary={
                              <span className="f-14">
                                <span>{boldText}</span>
                                {regularText && ` – ${regularText}`}
                              </span>
                            }
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                </div>
              ))}
            </AccordionDetails>
          ) : null}
        </Accordion>
      ))}
    </Box>
  );
};

export default NavigatorAccordion;

import BoltIcon from "@mui/icons-material/Bolt";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import HomeIcon from "@mui/icons-material/Home";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SchoolIcon from "@mui/icons-material/School";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  LinearProgress,
  Toolbar,
  Typography,
} from "@mui/material";
import { motion } from "framer-motion";
import { useState } from "react";
import images from "../../../assets/images";
import "../dashboard.css";
import PricingPlans from "./components/PricingPlans";
import ProfileCard from "./components/ProfileCard";

export default function DashboardHome() {
  const emojis = ["😀", "😊", "🤩", "😟", "😞", "😡"];
  const [flyingEmojis, setFlyingEmojis] = useState([]);

  // Handle emoji click
  const handleEmojiClick = (emoji, event) => {
    const { left, top, width, height } = event.target.getBoundingClientRect();

    // Position of the emoji click
    const newEmoji = {
      id: Date.now(),
      emoji,
      x: left + width / 2, // Center position of the clicked emoji
      y: top + height / 2, // Center position of the clicked emoji
    };

    setFlyingEmojis((prev) => [...prev, newEmoji]);

    // Remove emoji after animation (2 seconds)
    setTimeout(() => {
      setFlyingEmojis((prev) => prev.filter((e) => e.id !== newEmoji.id));
    }, 2000);
  };
  console.log(flyingEmojis, "flyingEmojis");

  const goals = [
    {
      text: "Journal your thoughts",
      xp: 100,
      value: 20,
      icon: <MenuBookIcon />,
    },
    {
      text: "Daily Treasure Hunt",
      xp: 250,
      value: 30,
      icon: <CardGiftcardIcon />,
    },
    {
      text: "Complete Lesson Module",
      xp: 250,
      value: 40,
      icon: <SchoolIcon />,
    },
    {
      text: "Redeem Points in the Reward Store",
      xp: 150,
      value: 50,
      icon: <MonetizationOnIcon />,
    },
  ];

  return (
    <Box position="relative">
      <AppBar
        position="static"
        sx={{ background: "transparent", boxShadow: "none" }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: "20px",
          }}
          className="px-0"
        >
          <Box
            className="d-flex align-items-center justify-content-between"
            gap={1}
          >
            <IconButton
              className="icons_border text-darkblue"
              sx={{ p: "3px", borderRadius: "5px" }}
            >
              <HomeIcon />
            </IconButton>
            <Typography variant="h6" className="text-darkblue fw-500">
              Home
            </Typography>
          </Box>

          {/* Right Section - Icons and Profile */}
          <Box display="flex" alignItems="center" gap={2}>
            <Box display="flex" alignItems="center" gap={1}>
              <BoltIcon className="text-darksky" />
              <Typography className="f-14 color-para">1</Typography>
            </Box>

            <Box display="flex" alignItems="center" gap={1}>
              <MonetizationOnIcon sx={{ color: "#FFD700" }} />
              <Typography sx={{ fontSize: 14, color: "#333" }}>
                10 XP
              </Typography>
            </Box>

            <IconButton>
              <Badge
                color="error"
                variant="dot"
                className="icons_border"
                sx={{ p: "3px", borderRadius: "5px" }}
              >
                <NotificationsNoneIcon className="color-para" />
              </Badge>
            </IconButton>

            <Avatar src={images.kimAvatar} alt="Profile" />
          </Box>
        </Toolbar>
      </AppBar>
      <Grid container spacing={3}>
        {/* Left Main Section */}
        <Grid item xs={12} md={8}>
          {/* Wellness Journey Section */}
          <Card
            sx={{ p: 3, borderRadius: 3, boxShadow: 3 }}
            className="sail_wellness radius-16 bg-lightsky d-flex align-items-center position-relative gap-1"
          >
            <Grid container spacing={2}>
              <Grid item md={8} sm={12}>
                <Typography className="sub_header fw-500 font-poppins text-darkblue">
                  Set Sail on Your Wellness Journey
                </Typography>
                <Typography
                  className="f-16 fw-500 font-lora color-para"
                  sx={{ mt: 1 }}
                >
                  Your journey toward self-awareness and growth starts here. Set
                  your course, explore your focus elements, and navigate toward
                  a balanced life.
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={20}
                  sx={{
                    my: 2,
                    p: "6px",
                    borderRadius: "8px",
                    backgroundColor: "lightgray",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "#00ACC1",
                    },
                  }}
                />
                <Typography variant="caption" color="text.secondary">
                  Earn 250 XP after completing this lesson!
                </Typography>
                <br />

                <Button
                  variant="contained"
                  className="contact_btn f-16 text-light font-poppins"
                >
                  Start Your First Lesson
                </Button>
              </Grid>
              <Grid md={4} sm={12} position="relative" className="text-center">
                <img
                  className="object-fit-contain robot_img"
                  src={images.workRobot}
                  alt="robot_img"
                />{" "}
              </Grid>
            </Grid>
          </Card>

          {/* Mood Tracker */}
          <Box position="relative">
            <Card
              sx={{ p: 3, mt: 3, borderRadius: 3 }}
              className="d-flex align-items-center justify-content-between"
            >
              <Typography variant="h6">How are you feeling today?</Typography>
              <Box display="flex" justifyContent="center" gap={2} mt={2}>
                <div className="emoji-picker">
                  {emojis.map((emoji, index) => (
                    <span
                      key={index}
                      className="emoji"
                      onClick={(e) => handleEmojiClick(emoji, e)}
                      style={{ fontSize: "30px", cursor: "pointer" }}
                    >
                      {emoji}
                    </span>
                  ))}
                </div>
              </Box>

              {/* Floating Emojis Section */}
            </Card>
            <Box position="relative" sx={{ left: "80%" }}>
              {flyingEmojis.map((item) => (
                <motion.span
                  key={item.id}
                  initial={{ opacity: 1, x: item.x, y: item.y }}
                  animate={{ opacity: 0, y: item.y - 150 }}
                  transition={{ duration: 2 }}
                  className="floating-emoji "
                  style={{
                    // position: "absolute",
                    left: `${item.x}px`,
                    top: `${item.y}px`,
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  {item.emoji}
                </motion.span>
              ))}
            </Box>
          </Box>
          {/* Goals Section */}
          <Card
            sx={{
              p: 3,
              mt: 3,
              borderRadius: 3,
              boxShadow: 3,
              position: "relative",
              overflow: "hidden",
            }}
          >
            <Typography className="sub_header fw-500 font-poppins text-darkblue">
              Today's Goals
            </Typography>

            {goals.map((goal, index) => (
              <Box key={index} my={2} display="flex" alignItems="center">
                {/* MUI Icon for each goal */}
                <Box className="text-white bg-darksky goal_icons all_center">
                  {goal.icon}
                </Box>

                <Box className="w-100">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography className="f-14 fw-500 font-poppins color-para">
                      {goal.text}
                    </Typography>
                    <Typography
                      variant="body2"
                      className="xp-color f-14 f-poppins"
                    >
                      + {goal.xp} XP
                    </Typography>
                  </Box>

                  <LinearProgress
                    variant="determinate"
                    value={goal.value}
                    sx={{
                      height: 8,
                      my: 1,
                      borderRadius: "8px",
                      backgroundColor: "lightgray",
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: "#00ACC1",
                      },
                    }}
                  />
                </Box>
              </Box>
            ))}
          </Card>
        </Grid>

        {/* Right Side Section */}
        <Grid item xs={12} md={4}>
          <ProfileCard />
          <Box className="price_plans">
            <PricingPlans />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

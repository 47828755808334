import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";

const GrievanceModal = ({ open, handleClose }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (data) => {
    console.log("Form Data Submitted:", data);
    reset();
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="grievance-modal-title"
      aria-describedby="grievance-modal-description"
    >
      <Box
        className="all_center"
        sx={{
          height: "100vh",
        }}
        pb={2}
      >
        <Box
          sx={{
            width: 400,
            padding: 2,
            backgroundColor: "white",
            borderRadius: 1,
            boxShadow: 3,
            position: "relative",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            variant="h6"
            id="grievance-modal-title"
            gutterBottom
            className="pb-2 fw-bold"
          >
            File a Grievance
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              label="Grievance Coordinator"
              name="grievanceCoordinator"
              fullWidth
              {...register("grievanceCoordinator", {
                required: "Grievance Coordinator is required",
              })}
              error={!!errors.grievanceCoordinator}
              helperText={errors.grievanceCoordinator?.message}
              sx={{ marginBottom: 2 }}
            />
            <TextField
              label="Phone"
              name="phone"
              fullWidth
              {...register("phone", { required: "Phone is required" })}
              error={!!errors.phone}
              helperText={errors.phone?.message}
              sx={{ marginBottom: 2 }}
            />
            <TextField
              label="Email"
              name="email"
              fullWidth
              {...register("email", { required: "Email is required" })}
              error={!!errors.email}
              helperText={errors.email?.message}
              sx={{ marginBottom: 2 }}
            />
            <TextField
              label="Mailing Address"
              name="address"
              fullWidth
              {...register("address", {
                required: "Mailing Address is required",
              })}
              error={!!errors.address}
              helperText={errors.address?.message}
              sx={{ marginBottom: 2 }}
            />

            <Button
              variant="contained"
              type="submit"
              className="contact_btn f-16 w-100 m-0 mb-1"
            >
              Submit Grievance
            </Button>
          </form>
        </Box>
      </Box>
    </Modal>
  );
};

export default GrievanceModal;

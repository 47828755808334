import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import WindowIcon from "@mui/icons-material/Window";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import { Avatar, Box, Card, Grid, IconButton, Typography } from "@mui/material";
import { useRef, useState } from "react";
import video from "../../../assets/aboutus/video.mp4";
import images from "../../../assets/images";

const Inspiration = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);

  // Toggle Play/Pause
  const handleVideoToggle = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <Box>
      <Box className="all_center gap-1" mb={5}>
        <Box className="rounded-btns all_center cursor-pointer" p={2}>
          <WindowIcon fontSize="20px" className="window_icon" />
        </Box>
        <Typography
          variant="button"
          color="primary"
          className="rounded-btns cursor-pointer text-darksky"
          py={1}
          px={2}
        >
          OUR TEAM
        </Typography>
      </Box>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          margin: "auto",
          borderRadius: 4,
          overflow: "hidden",
          mb: 3,
        }}
      >
        <video
          ref={videoRef}
          src={video}
          autoPlay
          muted
          loop
          className="w-100 video_size"
          style={{ display: "block", borderRadius: 3 }}
        />
        <Box
          sx={{
            position: "absolute",
            bottom: 20,
            left: 20,
            right: 20,
            color: "white",
            textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
          }}
        >
          <Typography className="fw-500 font-poppins text-light sub_header">
            Every Journey Needs a Strong Crew
          </Typography>
          <Typography className="fw-500 font-lora text-light f-18" my={1}>
            Our team is here to support and guide you on your path to growth and
            well-being.
          </Typography>
        </Box>
        {/* Play/Pause Button */}
        <IconButton
          onClick={handleVideoToggle}
          sx={{
            position: "absolute",
            bottom: 20,
            right: 20,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            "&:hover": { backgroundColor: "white" },
          }}
        >
          {isPlaying ? (
            <PauseIcon sx={{ fontSize: 30, color: "#0097A7" }} />
          ) : (
            <PlayArrowIcon sx={{ fontSize: 30, color: "#0097A7" }} />
          )}
        </IconButton>
      </Box>

      {/* Team Roles Section */}
      <Grid container spacing={4}>
        {/* Counselors and Therapists Card */}
        <Grid item xs={12} sm={6} md={4}>
          <Card
            className="card_border d-flex flex-column h-100"
            sx={{
              p: 3,
              alignItems: "flex-start",
              borderRadius: 3,
            }}
          >
            <WorkOutlineIcon sx={{ fontSize: 30, color: "#0097A7" }} />
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ mt: 2 }}
              className="styled_subheder_header text-black font-poppins fw-500"
            >
              Counselors and Therapists
            </Typography>
            <Typography className="fw-500 font-lora color-para f-18" mt={1}>
              Providing guidance, support, and tools to help you navigate life's
              challenges with confidence.
            </Typography>
          </Card>
        </Grid>

        {/* Support Staff Card */}
        <Grid item xs={12} sm={6} md={4}>
          <Card
            className="card_border d-flex flex-column h-100"
            sx={{
              p: 3,
              alignItems: "flex-start",
              borderRadius: 3,
            }}
          >
            <WorkOutlineIcon sx={{ fontSize: 30, color: "#0097A7" }} />
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ mt: 2 }}
              className="styled_subheder_header text-black font-poppins fw-500"
            >
              Support Staff
            </Typography>
            <Typography className="fw-500 font-lora color-para f-18" mt={1}>
              Ensuring a smooth and supportive experience while you chart your
              own course.
            </Typography>
          </Card>
        </Grid>

        {/* Educators Card */}
        <Grid item xs={12} sm={6} md={4}>
          <Card
            className="card_border d-flex flex-column bg-lightsky"
            sx={{
              p: 3,
              borderRadius: 3,
              justifyContent: "space-between",
            }}
          >
            <Typography className="styled_subheder_header text-black font-poppins fw-500">
              Educators
            </Typography>
            <Typography className="fw-500 font-lora color-para f-18" mt={1}>
              Equipping you with knowledge and skills to build resilience and
              take charge of your journey.
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", mt: 3 }}>
              <Avatar
                src={images.kimAvatar}
                alt="Kim Sutherland"
                sx={{ width: 50, height: 50, mr: 2 }}
              />
              <Box>
                <Typography className="fw-500 font-poppins text-black sub_header">
                  Kim Sutherland
                </Typography>
                <Typography className="fw-500 font-lora color-para f-18" mt={1}>
                  Founder, Centerboard Wellness Center
                </Typography>
              </Box>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Inspiration;

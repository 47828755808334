import AccessTimeIcon from "@mui/icons-material/AccessTime";
import BarChartIcon from "@mui/icons-material/BarChart";
import CheckIcon from "@mui/icons-material/Check";
import PersonIcon from "@mui/icons-material/Person";
import QuizIcon from "@mui/icons-material/Quiz";
import SchoolIcon from "@mui/icons-material/School";
import StarIcon from "@mui/icons-material/Star";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import WindowIcon from "@mui/icons-material/Window";
import images from "../images";

export const moduleData = [
  {
    title: "Charting Your Course – Building Self-Awareness and Resilience",
    moduleInfo: "Module 1   • 10 Compass Credits",
    description:
      "In this module, you'll develop a strong foundation of self-awareness and resilience, the core skills needed for personal growth.",
    sections: [
      {
        title: (
          <div className="d-flex align-items-center text-blue">
            <img
              src={images.degree}
              className="accord_icon"
              alt="Degree Icon"
              style={{ width: "24px", height: "24px", marginRight: "8px" }}
            />
            What You’ll Learn:
          </div>
        ),
        icon: <CheckIcon className="text-midlight" />,
        items: [
          "How your thoughts and emotions influence your decisions and actions.",
          "How to identify patterns that may be holding you back.",
          "How to embrace resilience and learn from challenges.",
        ],
      },
      {
        title: (
          <div className="d-flex align-items-center text-blue">
            <img
              src={images.file}
              className="accord_icon"
              alt="File Icon"
              style={{ width: "24px", height: "24px", marginRight: "8px" }}
            />
            Activities:
          </div>
        ),
        icon: (
          <img
            src={images.thumb}
            alt="File Icon"
            style={{ width: "20px", height: "20px", marginRight: "8px" }}
          />
        ),
        items: [
          "Reflective journaling on personal strengths and areas for growth.",
          "Explore guiding lights like courage, strength, and surrender as tools for navigating life's storms.",
          "Explore guiding lights like courage, strength, and surrender as tools for navigating life’s storms.",
        ],
      },
      {
        title: (
          <div className="d-flex align-items-center text-blue">
            <img
              src={images.star}
              className="accord_icon"
              alt="Degree Icon"
              style={{ width: "24px", height: "24px", marginRight: "8px" }}
            />
            Outcome:
          </div>
        ),
        icon: <StarIcon className="text-midlight" />,
        items: [
          "You'll feel more confident in understanding yourself and ready to apply new tools to create positive changes in your life.",
        ],
      },
    ],
  },
  {
    title: "Navigating Thinking Patterns – Shaping Your Mindset",
    moduleInfo: "Module 2 • 20 Compass Credits",
    description:
      "In this module, you'll develop a strong foundation of self-awareness and resilience, the core skills needed for personal growth.",
    sections: [],
  },
  {
    title: "Exploring Emotional Wisdom and Cosmic Connection",
    moduleInfo: "Module 3 • 30 Compass Credits",
    description:
      "In this module, you'll develop a strong foundation of self-awareness and resilience, the core skills needed for personal growth.",
    sections: [],
  },
  {
    title: "Setting Your Compass: SMART Goals and Taking Action",
    moduleInfo: "Module 4 • 40 Compass Credits",
    description:
      "In this module, you'll develop a strong foundation of self-awareness and resilience, the core skills needed for personal growth.",
    sections: [],
  },
  {
    title: "Final Expedition: The Journey Continues",
    moduleInfo: "Module 5 • 50 Compass Credits",
    description:
      "In this module, you'll develop a strong foundation of self-awareness and resilience, the core skills needed for personal growth.",
    sections: [],
  },
];

export const pageData = {
  title: "Navigating the Seas of Self-Discovery",
  description:
    "Welcome to Vision Quest, where you’ll embark on a transformative journey of self-discovery and personal growth. Below is the structure of your curriculum, starting with the Introduction and progressing through four modules. Each module builds on the last, guiding you to develop key skills and insights to chart your course to success.",
  icon: <WindowIcon fontSize="20px" className="window_icon" />,
  buttonText: "Navigation Charts",
};

export const weekModule = {
  title: "Hoist your sails and embark on a journey of self-discovery",
  description:
    "Each week is focused on developing courage and confidence, essential for steering your course. Through reflection, activities, and guided learning, you will deepen your understanding and strengthen your ability to navigate life's challenges, guided by the stars of wisdom.",
  icon: <WindowIcon fontSize="20px" className="window_icon" />,
  buttonText: "The Captain’s Voyage: A 12-Week Expedition",
};

export const weekAccordianData = [
  {
    title: "Bravery & Courage - Setting Sail: Defining Your Vision & Purpose",
    moduleInfo: "Leg 1 of the Journey    • 100 XP",
    description:
      "Face challenges with boldness and strength. Reflect on past moments of courage and identify opportunities for brave action.",
    sections: [
      {
        title: (
          <div className="d-flex align-items-center text-blue">
            <img
              src={images.degree}
              className="accord_icon"
              alt="Degree Icon"
              style={{ width: "24px", height: "24px", marginRight: "8px" }}
            />
            What You’ll Learn:
          </div>
        ),
        icon: <CheckIcon className="text-midlight" />,
        items: [
          "Face challenges with boldness and strength.",
          "Reflect on past moments of courage and identify opportunities for brave action.",
        ],
      },
    ],
  },
  {
    title: "Leadership - Reading the Stars: Strength-Based Leadership",
    moduleInfo: "Leg 2 of the Journey • 100 XP",
    description:
      "Explore what leadership means in your life and how to lead by example.",
    sections: [
      {
        title: (
          <div className="d-flex align-items-center text-blue">
            <img
              src={images.degree}
              className="accord_icon"
              alt="Degree Icon"
              style={{ width: "24px", height: "24px", marginRight: "8px" }}
            />
            What You’ll Learn:
          </div>
        ),
        icon: <CheckIcon className="text-midlight" />,
        items: [
          "Face challenges with boldness and strength.",
          "Explore what leadership means in your life and how to lead by example.",
        ],
      },
    ],
  },
  {
    title: "Resilience - Navigating Rough Waters: Building Strength",
    moduleInfo: "Leg 3 of the Journey  • 100 XP",
    description:
      "Develop the ability to recover and adapt in the face of adversity. Learn strategies for bouncing back from setbacks.",
    sections: [
      {
        title: (
          <div className="d-flex align-items-center text-blue">
            <img
              src={images.degree}
              className="accord_icon"
              alt="Degree Icon"
              style={{ width: "24px", height: "24px", marginRight: "8px" }}
            />
            What You’ll Learn:
          </div>
        ),
        icon: <CheckIcon className="text-midlight" />,
        items: [
          "Develop the ability to recover and adapt in the face of adversity.",
          "Learn strategies for bouncing back from setbacks.",
        ],
      },
    ],
  },
  {
    title: "Integrity - Navigating Rough Waters: Building Strength",
    moduleInfo: "Leg 4 of the Journey  • 100 XP",
    description:
      "Embrace honesty and strong moral principles. Reflect on the value of staying true to your word and beliefs.",
    sections: [
      {
        title: (
          <div className="d-flex align-items-center text-blue">
            <img
              src={images.degree}
              className="accord_icon"
              alt="Degree Icon"
              style={{ width: "24px", height: "24px", marginRight: "8px" }}
            />
            What You’ll Learn:
          </div>
        ),
        icon: <CheckIcon className="text-midlight" />,
        items: [
          "Embrace honesty and strong moral principles.",
          "Reflect on the value of staying true to your word and beliefs.",
        ],
      },
    ],
  },
  {
    title: "Change and Adaptability - Tides of Change: Emotional Wisdom & Growth",
    moduleInfo: "Leg 5 of the Journey  • 100 XP",
    description:
      "Navigate life's transitions with grace and flexibility. Understand the importance of being open to growth and evolution.",
    sections: [
      {
        title: (
          <div className="d-flex align-items-center text-blue">
            <img
              src={images.degree}
              className="accord_icon"
              alt="Degree Icon"
              style={{ width: "24px", height: "24px", marginRight: "8px" }}
            />
            What You’ll Learn:
          </div>
        ),
        icon: <CheckIcon className="text-midlight" />,
        items: [
          "Navigate life's transitions with grace and flexibility.enges with boldness and strength.",
          "Understand the importance of being open to growth and evolution.",
        ],
      },
    ],
  },
  {
    title: "Empathy and Connection - Mapping Your Journey: Overcoming Barriers",
    moduleInfo: "Leg 6 of the Journey  • 100 XP",
    description:
      "Strengthen your ability to understand and connect with others. Practice active listening and compassion in relationships.",
    sections: [
      {
        title: (
          <div className="d-flex align-items-center text-blue">
            <img
              src={images.degree}
              className="accord_icon"
              alt="Degree Icon"
              style={{ width: "24px", height: "24px", marginRight: "8px" }}
            />
            What You’ll Learn:
          </div>
        ),
        icon: <CheckIcon className="text-midlight" />,
        items: [
          "Strengthen your ability to understand and connect with others.",
          "Practice active listening and compassion in relationships.",
        ],
      },
    ],
  },
  {
    title: "Decision-Making - Steering Through Storms: Handling Setbacks",
    moduleInfo: "Leg 7 of the Journey  • 100 XP",
    description:
      "Build confidence in making thoughtful and effective choices. Learn techniques for evaluating options and considering consequences.",
    sections: [
      {
        title: (
          <div className="d-flex align-items-center text-blue">
            <img
              src={images.degree}
              className="accord_icon"
              alt="Degree Icon"
              style={{ width: "24px", height: "24px", marginRight: "8px" }}
            />
            What You’ll Learn:
          </div>
        ),
        icon: <CheckIcon className="text-midlight" />,
        items: [
          "Build confidence in making thoughtful and effective choices.",
          "Learn techniques for evaluating options and considering consequences.",
        ],
      },
    ],
  },
  {
    title: "Vision - Finding North: Cultivating Strength & Courage",
    moduleInfo: "Leg 8 of the Journey  • 100 XP",
    description:
      "Define and focus on long-term goals and aspirations. Envision the future you want to create for yourself.",
    sections: [
      {
        title: (
          <div className="d-flex align-items-center text-blue">
            <img
              src={images.degree}
              className="accord_icon"
              alt="Degree Icon"
              style={{ width: "24px", height: "24px", marginRight: "8px" }}
            />
            What You’ll Learn:
          </div>
        ),
        icon: <CheckIcon className="text-midlight" />,
        items: [
          "Define and focus on long-term goals and aspirations.",
          "Envision the future you want to create for yourself.",
        ],
      },
    ],
  },
  {
    title: "Humility - Anchoring Values: The Power of Humility",
    moduleInfo: "Leg 9 of the Journey  • 100 XP",
    description:
      "Reflect on the importance of humility in personal and professional growth. Practice balancing confidence with self-awareness.",
    sections: [
      {
        title: (
          <div className="d-flex align-items-center text-blue">
            <img
              src={images.degree}
              className="accord_icon"
              alt="Degree Icon"
              style={{ width: "24px", height: "24px", marginRight: "8px" }}
            />
            What You’ll Learn:
          </div>
        ),
        icon: <CheckIcon className="text-midlight" />,
        items: [
          "Reflect on the importance of humility in personal and professional growth.",
          "Practice balancing confidence with self-awareness.",
        ],
      },
    ],
  },
  {
    title: "Initiative - Navigating the Open Waters: Staying on Course",
    moduleInfo: "Leg 10 of the Journey  • 100 XP",
    description:
      "Take proactive steps toward achieving your goals. Understand the value of self-motivation and responsibility.",
    sections: [
      {
        title: (
          <div className="d-flex align-items-center text-blue">
            <img
              src={images.degree}
              className="accord_icon"
              alt="Degree Icon"
              style={{ width: "24px", height: "24px", marginRight: "8px" }}
            />
            What You’ll Learn:
          </div>
        ),
        icon: <CheckIcon className="text-midlight" />,
        items: [
          "Take proactive steps toward achieving your goals.",
          "Understand the value of self-motivation and responsibility.",
        ],
      },
    ],
  },
  {
    title: "Self-Reflection - Sailing Toward Success: Taking Action",
    moduleInfo: "Leg 11 of the Journey • 100 XP",
    description: "Final Reflection",
    sections: [
      {
        title: (
          <div className="d-flex align-items-center text-blue">
            <img
              src={images.degree}
              className="accord_icon"
              alt="Degree Icon"
              style={{ width: "24px", height: "24px", marginRight: "8px" }}
            />
            What You’ll Learn:
          </div>
        ),
        icon: <CheckIcon className="text-midlight" />,
        items: [
          "Face challenges with boldness and strength.",
          "Reflect on past moments of courage and identify opportunities for brave action.",
        ],
      }
    ],
  },
  {
    title: "Final Refection - The Captain’s Log: A Reflection on the Voyage",
    moduleInfo: "Leg 12 of the Journey  • 100 XP",
    description:
      "Review the entire journey and synthesize what you've learned. Develop a personal growth map for the future and set intentions for continued success.",
    sections: [
      {
        title: (
          <div className="d-flex align-items-center text-blue">
            <img
              src={images.degree}
              className="accord_icon"
              alt="Degree Icon"
              style={{ width: "24px", height: "24px", marginRight: "8px" }}
            />
            What You’ll Learn:
          </div>
        ),
        icon: <CheckIcon className="text-midlight" />,
        items: [
          "Review the entire journey and synthesize what you've learned.",
          "Develop a personal growth map for the future and set intentions for continued success.",
        ],
      },
    ],
  },
  
];

export const reviewsData = [
  {
    name: "John Smith",
    avatar: images.reviewTwo,
    rating: 4.9,
    review:
      "The Life Focus Elements gave me a whole new perspective on balance and personal growth. I feel more aligned and in control than ever before. Like the course a lot. The progress tracking keeps me motivated every day!",
    date: "Monday, 12/13/2024 2:00 PM",
  },
  {
    name: "Emma Johnson",
    avatar: images.reviewOne,
    rating: 4.7,
    review:
      "This course has transformed the way I see my goals. The lessons are structured well, and I love the interactive elements.",
    date: "Tuesday, 12/14/2024 10:30 AM",
  },
  {
    name: "Michael Brown",
    avatar: images.reviewThree,
    rating: 5.0,
    review:
      "Absolutely fantastic! The best self-improvement program I've taken. Highly recommended!",
    date: "Wednesday, 12/15/2024 5:45 PM",
  },
];

export const accordionData = [
  {
    title: "How do the modules work?",
    content:
      "Our modules focus on key aspects of well-being, such as self-awareness, goal setting, progress tracking, and life balance. Each module includes guided exercises, reflection prompts, and actionable steps to help you grow.",
  },
  {
    title: "Can I track my progress?",
    content:
      "The Life Focus Elements—Earth, Water, Air, Fire, and Space—represent different aspects of personal growth. They help you understand and align your emotions, thoughts, and actions with your overall well-being.",
  },
  {
    title: "Is this suitable for beginners?",
    content:
      "Absolutely! Our modules are designed for people of all levels, including beginners who want to improve their self-awareness and goal-setting skills.",
  },
  {
    title: "What are the Life Focus Elements?",
    content:
      "Absolutely! Our modules are designed for people of all levels, including beginners who want to improve their self-awareness and goal-setting skills.",
  },
  {
    title: "Can I track my progress?",
    content:
      "Absolutely! Our modules are designed for people of all levels, including beginners who want to improve their self-awareness and goal-setting skills.",
  },
  {
    title: "Who can benefit from Centerboard Wellness?",
    content:
      "Absolutely! Our modules are designed for people of all levels, including beginners who want to improve their self-awareness and goal-setting skills.",
  },
  {
    title: "What is Centerboard Wellness?",
    content:
      "Absolutely! Our modules are designed for people of all levels, including beginners who want to improve their self-awareness and goal-setting skills.",
  },
];

export const holisticCardsData = [
  {
    id: 1,
    image: images.crOne,
    title: "Navigating the Seas of Self-Discovery",
    buttonLabel: "Learn More",
    link: "sea-discovery",
  },
  {
    id: 2,
    image: images.crTwo,
    title: "The Captain’s Voyage: A 12-Week Expedition",
    buttonLabel: "Learn More",
    link: "twelve-weeks-plan",
  },
  {
    id: 3,
    image: images.crThree,
    title: "The Captain’s Treasure Hunt",
    buttonLabel: "Learn More",
    link: "daily-treasure-hunting",
  },
  {
    id: 4,
    image: images.crFour,
    title: "Welcome to the Legal Knowledge Handbook",
    buttonLabel: "Learn More",
    link: "legal-knowledge",
  },
  {
    id: 5,
    image: images.crFive,
    title:
      "Navigating Out of the DUI Port: A Journey to Self-Awareness and Change",
    buttonLabel: "Learn More",
    link: "holistic-journey",
  },
  {
    id: 6,
    image: images.crSix,
    title: "Navigating Choices: The DUI Prevention Podcast",
    buttonLabel: "Learn More",
    link: "educational-podcast",
  },
];

export const growthData = [
  {
    icon: images.cdOne,
    title: "Assess Where You Are Now",
    description:
      "Complete a self-awareness assessment to evaluate your strengths, challenges, and areas for growth.",
  },
  {
    icon: images.cdTwo,
    title: "Set Your Vision",
    description:
      "Imagine the life you want to create and define what success looks like for you.",
  },
  {
    icon: images.cdthree,
    title: "Create Your Mission Statement",
    description:
      "Write a short statement about what drives you and your purpose for embarking on this journey.",
  },
  {
    icon: images.cdFour,
    title: "Gather Your Tools",
    description: "A journal, a list of goals, and a commitment to growth.",
  },
  {
    icon: images.cdFive,
    title: "Activity: Chart Your Starting Point",
    description:
      "Complete your self-awareness assessment. Write your vision and mission statements. Reflect on what you want to achieve by the end of this program.",
  },
];

export const trasuresData = [
  { icon: <AccessTimeIcon />, label: "12 Weeks" },
  { icon: <BarChartIcon />, label: "Beginner" },
  { icon: <ViewModuleIcon />, label: "4 Lesson" },
  { icon: <QuizIcon />, label: "1 Quizzes" },
  { icon: <SchoolIcon />, label: "20 Students" },
  { icon: <PersonIcon />, label: "Dr. Lisa Monroe" },
  { icon: true, lable: "Enroll Now" },
];

export const gemsModule = {
  title: "Discover the Hidden Gems Within Yourself",
  description:
    "Embark on a transformative journey of self-discovery with the Daily Treasure Hunt to Self-Awareness Game. This interactive and engaging game is designed to help you uncover your strengths, reflect on your actions, and cultivate mindfulness in your daily life. Through a series of fun activities and challenges, this game encourages you to:",
  icon: <WindowIcon fontSize="20px" className="window_icon" />,
  buttonText: "Features",
};

export const infoCardData = [
  {
    title: "Reflection",
    description: "Reflect on your daily actions and decisions.",
    icon: images.reflection,
  },
  {
    title: "Discovery",
    description: "Discover what makes you unique and special.",
    icon: images.discovery,
  },
  {
    title: "Empowerment",
    description: "Empower yourself to unlock your true potential.",
    icon: images.empowerment,
  },
];

export const leadershipModule = {
  title: "Leadership Global Communication (LGC) Log",
  description:
    "The LGC Log is your daily tracker for focus, reflection, and growth. By aligning your actions with meaningful themes, this tool guides you through mindful decision-making and helps you achieve your personal goals.",
  icon: <WindowIcon fontSize="20px" className="window_icon" />,
  buttonText: "How It Works",
  linkButton: "Enroll Now",
};

export const leadershipData = [
  {
    icon: images.leadOne,
    title: "Start Your Day:",
    description:
      "Choose a Daily Theme from the provided list that resonates with your weekly goals.",
  },
  {
    icon: images.leadTwo,
    title: "Throughout the Day:",
    description:
      "Treat your day like a treasure hunt, looking for signs in nature and your surroundings that align with the Life Focus Elements: earth, fire, air, wind, and space. Keep your chosen theme in mind as you make decisions and interact with the world.",
  },
  {
    icon: images.leadThree,
    title: "End Your Day:",
    description:
      "Reflect on how your focus shaped your thoughts and actions. Note how it contributed to your weekly goal and overall self-awareness.",
  },
];

export const lifeData = [
  {
    title: "Earth",
    description: "Ground yourself in stability and strength.",
    icon: images.lifeOne,
  },
  {
    title: "Fire",
    description: "Ignite passion and energy in your actions.",
    icon: images.lifeTwo,
  },
  {
    title: "Air",
    description: "Embrace clarity and flexibility in your thoughts.",
    icon: images.lifeThree,
  },
  {
    title: "Wind",
    description: "Navigate change and adaptability with grace.",
    icon: images.lifeFour,
  },
  {
    title: "Space",
    description: "Find balance and perspective in life’s vastness.",
    icon: images.lifeFive,
  },
];

export const whyItWorks = {
  title: "Why It Works",
  description: "The Daily Treasure Hunt to Self-Awareness Game is designed to:",
  benefits: [
    {
      id: "01. Build Awareness",
      text: "Encourage mindfulness and recognizing signs in daily life",
    },
    {
      id: "02. Strengthen Resilience",
      text: "Develop a deeper understanding of emotions and thoughts.",
    },
    {
      id: "03. Gain Insight",
      text: "Foster self-discovery and decision-making skills",
    },
  ],
  conclusion:
    "Start your adventure today and discover the treasures within you. The journey begins now—where will it take you?",
};

export const personalData = [
  {
    title: "Discover Your Strengths",
    description: "Each day, identify and apply your personal strengths.",
  },
  {
    title: "Build Confidence",
    description:
      "Reflect on how your strengths contribute to your achievements.",
  },
  {
    title: "Enhance Self-Awareness",
    description: "Recognize patterns and opportunities for growth.",
  },
];

export const strengthData = [
  "Focus on what you do well and celebrate your unique abilities.",
  "Let your strengths guide your decisions and interactions.",
  "Reinforce positive habits that boost confidence and self-awareness.",
];

export const legalData = [
  { icon: <AccessTimeIcon />, label: "3 Weeks" },
  { icon: <BarChartIcon />, label: "All Levels" },
  { icon: <ViewModuleIcon />, label: "5 Modules" },
  { icon: <QuizIcon />, label: "0 Quizzes" },
  { icon: <SchoolIcon />, label: "30 Students" },
  { icon: <PersonIcon />, label: "Samantha Lee" },
];

import { Box } from "@mui/material";
import journey from "../../../assets/home/journey.png";
import StartingComponent from "../../components/StartingComponent";

const SailComponent = () => {
  return (
    <Box>
      <StartingComponent
        backgroundImage={journey}
        headerText="Your journey starts now—let’s navigate the seas of life together."
        buttonText="Start Your Journey"
        onButtonClick={() => console.log("Button clicked!")}
      />
    </Box>
  );
};

export default SailComponent;

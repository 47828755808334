import WindowIcon from "@mui/icons-material/Window";
import { Box, Container, Typography } from "@mui/material";
import images from "../../../../assets/images";
import "../../../../assets/style.css";
import ConfidenceContainer from "../../../components/ConfidenceContainer";
import InfoCardGrid from "../../../components/InfoCard";
import LegalHandbook from "../../../components/LegalHandbook";
import NavigatorAccordion from "../../../components/NavigatorAccordion";
import PageHeader from "../../../components/PageHeader ";
import WelcomeHandbook from "../LegalKnowledge/components/WelcomeHandbook";
import DisabilityStatement from "./components/DisabilityStatement";
import HolisticJourneyReviews from "./components/HolisticJourneyReviews";
import WellnessBoard from "./components/WellnessBoard";

export default function HolisticJourney({
  holisticBannerData,
  duiJourneyData,
  programsData,
  programsAccordianData,
  drivesData,
  classDetails,
  drivesChangeData,
  reviewhandlebookDAta,
  accordionData,
}) {
  return (
    <Box mb={2}>
      <WelcomeHandbook
        title="Navigating Out of the DUI Port: A Journey to Self-Awareness and Change"
        backgroundImage={images?.holistic}
        details={holisticBannerData}
        btnText="Enroll Now"
      />
      <LegalHandbook data={duiJourneyData} />

      <Container maxWidth="lg" sx={{ py: 5 }} className="main_container">
        <PageHeader {...programsData} />
        <NavigatorAccordion data={programsAccordianData} checked="" />
      </Container>

      <Container maxWidth="lg" sx={{ py: 5 }} className="main_container">
        <PageHeader {...drivesChangeData} />
        <InfoCardGrid data={drivesData} />{" "}
      </Container>
      <Container
        maxWidth="lg"
        sx={{
          py: 5,
          my: 5,
          backgroundImage: `url(${images.wheel})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="main_container radius-16"
      >
        <WellnessBoard />
      </Container>

      <Container maxWidth="lg" sx={{ py: 5, my: 5 }} className="main_container">
        <Box className="all_center gap-1" mb={4}>
          <Box className="rounded-btns all_center cursor-pointer" p={2}>
            <WindowIcon fontSize="20px" className="window_icon" />
          </Box>
          <Typography
            variant="button"
            color="primary"
            className="rounded-btns cursor-pointer text-darksky capitalize_text"
            py={1}
            px={2}
          >
            Setting Sail: What You Need to Know{" "}
          </Typography>
        </Box>
        <ConfidenceContainer
          imageUrl={images.information}
          images={images}
          contentData={classDetails}
        />
      </Container>
      <Container
        maxWidth="lg"
        className="radius-16 all_center bg-cover"
        sx={{
          backgroundImage: `url(${images.disability})`,
          backgroundPosition: "center",
          p: 5,
          my: 5,
        }}
      >
        <DisabilityStatement />
      </Container>

      <HolisticJourneyReviews
        accordionData={accordionData}
        reviewhandlebookDAta={reviewhandlebookDAta}
      />
    </Box>
  );
}

import {
  AccessTime,
  Layers,
  LayersOutlined,
  People,
  SignalCellularAlt,
  Star,
} from "@mui/icons-material";
import DescriptionIcon from "@mui/icons-material/Description";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ReactPlayer from "react-player";
import images from "../../../../assets/images";
import QuizHeader from "../components/QuizHeader";
import RequirementCard from "../components/RequirementCard";
import ModuleTab from "./components/ModuleTab";
import OverviewTab from "./components/OverviewTab";
import QuizTab from "./components/QuizTab";
import ResourceTab from "./components/ResourceTab";
import UserProfile from "./components/UserProfile";
import VideoCard from "./components/VideoCard";

const courseDetails = [
  { icon: <AccessTime fontSize="small" />, text: "2 Weeks" },
  { icon: <SignalCellularAlt fontSize="small" />, text: "Intermediate" },
  { icon: <Layers fontSize="small" />, text: `3 modules` },
  { icon: <People fontSize="small" />, text: `22 students` },
  { icon: <Star fontSize="small" />, text: "4.9" },
];

const courseData = [
  {
    module: "Level I Education",
    lessons: [
      {
        title: "Introduction to Impaired Driving",
        duration: "12 min",
        url: "https://videos.pexels.com/video-files/3195444/3195444-uhd_2560_1440_25fps.mp4",
      },
      {
        title: "Alcohol & Drug Impairment",
        duration: "120 min",
        url: "https://videos.pexels.com/video-files/4058068/4058068-uhd_2732_1440_25fps.mp4",
      },
      {
        title: "Risk & Decision-Making",
        duration: "120 min",
        url: "https://videos.pexels.com/video-files/4884242/4884242-uhd_2560_1440_30fps.mp4",
      },
      {
        title: "Legal & Financial Impact",
        duration: "120 min",
        url: "https://videos.pexels.com/video-files/4064865/4064865-uhd_2732_1440_25fps.mp4",
      },
      {
        title: "Safe Driving Habits",
        duration: "120 min",
        url: "https://videos.pexels.com/video-files/4065218/4065218-uhd_2732_1440_25fps.mp4",
      },
      {
        title: "Reflection & Application",
        duration: "120 min",
        url: "https://videos.pexels.com/video-files/5752729/5752729-uhd_2560_1440_30fps.mp4",
      },
    ],
  },
];

const VideoPlayer = ({ video }) => {
  return (
    <>
      <ReactPlayer url={video.url} controls width="100%" />
      <Typography variant="h6" mt={2}>
        {video.title}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        Duration: {video.duration}
      </Typography>
    </>
  );
};
const overviewData = {
  courseDescription: {
    icon: <LayersOutlined sx={{ color: "var(--color-darkSky)" }} />,
    intro:
      "At Centerboard Wellness Center, we believe in addressing both the external challenges of the legal system and the internal journey of personal growth. As you embark on this dual path, rest assured that we’re here to guide you every step of the way. Let us help you navigate this complex terrain with knowledge, confidence, and resilience.",
    subtitle:
      "Embracing Safe Roads: Understanding Colorado's Journey to Responsible Driving",
    details:
      "Welcome aboard! At Centerboard Wellness Center, we are dedicated to helping you navigate the road to responsible driving and personal growth. Our program is designed to provide you with the tools, knowledge, and support necessary to make informed and positive decisions. Driving under the influence is a critical issue that affects not only individuals but also families and communities. Through education, self-awareness, and accountability, our program aims to empower participants to take charge of their actions, understand the consequences, and build a safer future.",
  },
  learningPoints: [
    "Focused on foundational knowledge about the impact of impaired driving and building responsible habits.",
    "Explore advanced decision-making techniques and accountability strategies to prevent repeat offenses.",
    "Learn about emotional regulation, impulse control, and high-risk situation management.",
    "Develop skills in cognitive-behavioral strategies to reshape thought patterns and behaviors.",
    "Understand the social and cultural influences on substance use and risky decision-making.",
  ],
};

const modulesData = [
  {
    level: "Level I Education",
    duration: "12 hours",
    lessons: "6 Lessons",
    xp: "1000 XP",
    details:
      "Focused on foundational knowledge about the impact of impaired driving and building responsible habits.",
  },
  {
    level: "Level II Education",
    duration: "24 hours",
    lessons: "12 Lessons",
    xp: "1000 XP",
    details:
      "Explores deeper concepts, including decision-making strategies and the role of accountability in reducing recidivism.",
  },
  {
    level: "Level II Therapy",
    duration: "",
    lessons: "12 Lessons",
    xp: "2000 XP",
    details: `Designed to address individual needs through therapeutic interventions for long-term behavior change.
      \n**Tracks:**
      \n- Track A: 21 sessions
      \n- Track B: 26 sessions
      \n- Track C: 34 sessions
      \n- Track D: 43 sessions
      \nEach track incorporates therapeutic strategies to promote emotional resilience, personal accountability, and safer decision-making practices.`,
  },
];

export default function DashboardCourses() {
  const [selectedVideo, setSelectedVideo] = useState(courseData[0].lessons[0]);
  const [tabIndex, setTabIndex] = useState(0);
  const [isQuizUnlocked, setIsQuizUnlocked] = useState(false);

  return (
    <div>
      <QuizHeader
        title="DUI A Holistic Journey"
        user={{ name: "Michael Jenkins", avatar: images.kimAvatar }}
        details={courseDetails}
      />
      <Container-fluid maxWidth="lg" sx={{ mt: 4 }}>
        <Grid container spacing={2}>
          {/* Left Sidebar - Video Player and Details */}
          <Grid item xs={12} md={8}>
            <VideoCard
              title="Module 1: Level I Education"
              hours="4"
              lessons="33"
              xp="330"
            />
            <VideoPlayer video={selectedVideo} />
            <UserProfile
              name="Michael Jenkins"
              role="Professional DUI Therapist"
              avatarSrc={images.kimAvatar}
            />
            <Box className="tabs_one_hero">
              <Tabs
                value={tabIndex}
                onChange={(e, newValue) => setTabIndex(newValue)}
                sx={{
                  mt: 2,
                  "& .MuiTabs-indicator": {
                    backgroundColor: "var(--color-darkSky)",
                  },
                  "& .MuiTab-root": {
                    color: "var(--color-dark)",
                  },
                  "& .Mui-selected": {
                    color: "#00acc1",
                    fontWeight: "bold",
                  },
                }}
              >
                <Tab label="Overview" />
                <Tab label="Navigation Charts" />
                <Tab label="Resources" />
                <Tab
                  label={
                    <Stack direction="row" spacing={1} alignItems="center">
                      <span>Quiz</span>
                      {!isQuizUnlocked && (
                        <LockOutlinedIcon fontSize="small" />
                      )}{" "}
                    </Stack>
                  }
                />
              </Tabs>
            </Box>
            {tabIndex === 0 && (
              <Box mt={2}>
                <OverviewTab
                  courseDescription={overviewData.courseDescription}
                  learningPoints={overviewData.learningPoints}
                />{" "}
              </Box>
            )}
            {tabIndex === 1 && (
              <Box variant="body1" mt={2}>
                <ModuleTab modulesData={modulesData} />
              </Box>
            )}
            {tabIndex === 2 && (
              <Box variant="body1" mt={2}>
                <ResourceTab />
              </Box>
            )}
            {tabIndex === 3 && (
              <Box variant="body1" mt={2}>
                <QuizTab
                  isQuizUnlocked={isQuizUnlocked}
                  setIsQuizUnlocked={setIsQuizUnlocked}
                />
              </Box>
            )}
          </Grid>

          {/* Right Sidebar - Course Content */}
          <Grid item xs={12} md={4}>
            <Typography className="text-black font-poppins f-20 fw-500" mb={1}>
              Course Content
            </Typography>
            {courseData.map((module, index) => (
              <Accordion key={index} defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6">{module.module}</Typography>
                </AccordionSummary>
                <AccordionDetails className="p-0">
                  <List>
                    {module.lessons.map((lesson, idx) => (
                      <ListItem
                        button
                        key={idx}
                        onClick={() => setSelectedVideo(lesson)}
                        className={
                          selectedVideo.title === lesson.title
                            ? "selected_bg"
                            : ""
                        }
                      >
                        <ListItemText
                          primary={lesson.title}
                          secondary={lesson.duration}
                        />
                        <PlayCircleOutlineIcon />
                      </ListItem>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            ))}

            <RequirementCard
              badgeIcon={
                <DescriptionIcon sx={{ color: "var(--color-darkSky)" }} />
              }
              requirement="Participants must complete Level II Education before advancing to Level I Therapy."
            />
          </Grid>
        </Grid>
      </Container-fluid>
    </div>
  );
}

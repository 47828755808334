import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import DescriptionIcon from "@mui/icons-material/Description";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";

const iconsMap = {
  study: <DescriptionIcon sx={{ color: "var(--color-darkSky)" }} />,
  time: <AccessTimeIcon sx={{ color: "var(--color-darkSky)" }} />,
  back: <ShortcutIcon sx={{ color: "var(--color-darkSky)" }} />,
  score: <CreditScoreIcon sx={{ color: "var(--color-darkSky)" }} />,
};

const ReadyCard = ({ title, subtitle, description, iconType }) => {
  return (
    <Card
      sx={{
        flex: { xs: "1 1 100%", md: "1 1 calc(50% - 16px)" },
        maxWidth: { xs: "100%", md: "calc(50% - 16px)" },
        minWidth: 280,
        m: 1,
        p: 2,
        borderRadius: 2,
      }}
      className="light-border radius-16"
    >
      <CardContent>
        <Box display="flex" alignItems="center" gap={1} mb={1}>
          <Box className="quiz_icon all_center">
            {iconsMap[iconType] || null}
          </Box>
          <Typography className="text-black f-18 font-poppins fw-500">
            {title}
          </Typography>
        </Box>
        <Typography mt={1} className="color-para f-18 font-lora fw-500">
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ReadyCard;

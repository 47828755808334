import BuildIcon from "@mui/icons-material/Build";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import WindowIcon from "@mui/icons-material/Window";
import images from "../images";

export const skilledData = {
  title:
    "Every Journey is Guided by a Crew of Skilled and Dedicated Individuals.",
  description:
    " At Centerboard Wellness Center, our team includes professionals from diverse backgrounds, united by a shared commitment to your well-being.",
  icon: <WindowIcon fontSize="20px" className="window_icon" />,
  buttonText: "Program Objectives",
};

export const skilledDetailData = [
  {
    title: "Counselors and Therapists:",
    description:
      "Providing guidance, support, and tools to help you navigate life’s challenges with confidence.",
    icon: images.reflection,
  },
  {
    title: "Educators:",
    description:
      "Equipping you with knowledge and skills to build resilience and take charge of your journey.",
    icon: images.discovery,
  },
  {
    title: "Support Staff:",
    description:
      "Ensuring a smooth and supportive experience while you chart your own course.",
    icon: images.empowerment,
  },
];

export const features = [
  {
    icon: <SupportAgentIcon sx={{ fontSize: 50, color: "#0097A7" }} />,
    title: "Personalized Support",
    description:
      "We tailor our programs and resources to meet your unique needs.",
  },
  {
    icon: <BuildIcon sx={{ fontSize: 50, color: "#0097A7" }} />,
    title: "Innovative Tools",
    description:
      "From interactive curriculums to gamified learning, we provide engaging and effective solutions.",
  },
  {
    icon: <SelfImprovementIcon sx={{ fontSize: 50, color: "#0097A7" }} />,
    title: "Holistic Approach",
    description:
      "We focus on every aspect of your well-being—mind, body, and spirit.",
  },
];

import DownloadIcon from "@mui/icons-material/Download";
import LockIcon from "@mui/icons-material/Lock";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import React from "react";

const resources = [
  {
    title: "State-approved curriculum",
    downloadable: true,
  },
  {
    title:
      "Embracing Safe Roads: Understanding Colorado's Journey to Responsible Driving\" by Centerboard Wellness Center",
    downloadable: false,
  },
];

const ResourceItem = ({ resource }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#f8fbff",
        p: 2,
        borderRadius: 2,
        border: "1px solid #E0E3E7",
        mb: 1,
      }}
    >
      {/* Left Section - PDF Icon & Title */}
      <Stack direction="row" alignItems="center" spacing={2}>
        <PictureAsPdfIcon sx={{ color: "red", fontSize: 30 }} />
        <Typography className="text-black font-poppins f-16 fw-500">
          {resource.title}
        </Typography>
      </Stack>

      {/* Right Section - Download or Lock Icon */}
      {resource.downloadable ? (
        <Button
          variant="outlined"
          startIcon={<DownloadIcon />}
          sx={{
            borderColor: "#002366",
            color: "#002366",
            borderRadius: "50px",
            "&:hover": {
              backgroundColor: "#002366",
              color: "#fff",
            },
          }}
        >
          Download
        </Button>
      ) : (
        <IconButton sx={{ color: "#B0B3B8" }}>
          <LockIcon />
        </IconButton>
      )}
    </Box>
  );
};

const ResourceTab = () => {
  return (
    <Box sx={{ maxWidth: 700, mx: "auto", mt: 3 }}>
      <Typography
        className="text-darkblue font-poppins sub_header fw-500 gap-1 d-flex align-items-center"
        my={2}
      >
        <PostAddIcon sx={{ color: "var(--color-darkSky)" }} />
        Modules
      </Typography>
      {resources.map((resource, index) => (
        <ResourceItem key={index} resource={resource} />
      ))}
    </Box>
  );
};

export default ResourceTab;

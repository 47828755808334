import { Box, CardMedia, Grid, Paper, Typography } from "@mui/material";

const StatementContainer = ({ image, data, subHeader }) => {
  return (
    <Grid container spacing={4} alignItems="center" mt={1}>
      {/* Left Image Section */}
      <Grid item xs={12} md={6}>
        <CardMedia
          component="img"
          height="350"
          image={image}
          alt="Journey Image"
          className="fix_img"
        />
      </Grid>
      {/* Right Text Section */}
      <Grid item xs={12} md={6} position="relative">
        <Box className="programmers">
          {subHeader && (
            <Typography pb={3} className="font-poppins fw-600 leader_subheader">
              {subHeader}
            </Typography>
          )}
          {data.map((program, index) => (
            <Paper
              className="main_programms mb-1"
              key={index}
              sx={{
                padding: (theme) => theme.spacing(2),
                textAlign: "left",
                color: (theme) => theme.palette.text.primary,
                display: "flex",
                alignItems: "flex-start",
                gap: (theme) => theme.spacing(2),
                height: "100%",
                backgroundColor: "transparent",
                boxShadow: "none",
                flexBasis: { xs: "100%", sm: "50%", md: "33.33%", lg: "25%" },
              }}
            >
              <img
                src={program.icon}
                alt={`curriculum detail icon ${index + 1}`}
              />

              <Box sx={{ flex: 1 }}>
                <Typography
                  variant="h6"
                  className="chart_header"
                  sx={{
                    fontWeight: 600,
                    marginBottom: (theme) => theme.spacing(0.5),
                  }}
                >
                  {program.title}
                </Typography>
                <Typography
                  className="f-18 font-lora"
                  sx={{
                    color: (theme) => theme.palette.text.secondary,
                  }}
                >
                  {program.description}
                </Typography>
              </Box>
            </Paper>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};

export default StatementContainer;

import React from "react";
import { Divider } from "@mui/material";

function ScreenDivider(props) {
  return (
    <Divider
      sx={{
        my: 2,
        backgroundColor: "var(--color-darkSky)",
        maxWidth: "1200px",
        alignSelf: "center",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    />
  );
}

export default ScreenDivider;

// WeekPlanCard.js
import {
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import React from "react";

const WeekPlanCard = ({
  title,
  progress,
  journalEntries,
  pointsEarned,
  upcomingAppointments,
  weekNumber,
}) => {
  return (
    <Card className="border-card-1 border-r-8 bg-lightsky">
      <CardContent sx={{ height: "280px" }}>
        <Typography
          className="font-poppins fw-500 f-18 text-black"
          mb={2}
          component="div"
        >
          {title}
        </Typography>
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{
            my: 2,
            p: "6px",
            borderRadius: "8px",
            backgroundColor: "var(--color-light-d)",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "var(--color-darkBlue)",
            },
          }}
        />
        <Typography
          variant="body2"
          pt={1}
          className="font-14 color-para font-lora fw-400"
        >
          Week {weekNumber} of 12
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography className="font-14 color-para font-lora fw-400">
              Journal Entries
            </Typography>
            <Typography
              variant="body1"
              className="sub_header text-darksky font-poppins fw-500"
            >
              {journalEntries}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className="font-14 color-para font-lora fw-400">
              Points Earned
            </Typography>
            <Typography className="sub_header text-darksky font-poppins fw-500">
              {pointsEarned} XP
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              className="font-14 color-para font-lora fw-400"
            >
              Upcoming Appointments
            </Typography>
            <Typography className="sub_header text-darksky font-poppins fw-500">
              {upcomingAppointments}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default WeekPlanCard;
